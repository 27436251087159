import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import {
  NEXT_GEOLOCATION_PARAMS,
  requestNextApiEndpoint,
} from '~/lib/handle-api'

interface useGeolocationOptions {
  throwError?: boolean
  keys?: any[]
}

const defaultUseGeolocationOption = {
  throwError: false,
  keys: [],
}

export default function useGeolocationMutation(
  options?: UseQueryOptions<any, any, any>,
  useGeolocationOptions?: useGeolocationOptions,
) {
  const useGeolocationOptionsWithDefault = {
    ...defaultUseGeolocationOption,
    ...useGeolocationOptions,
  }
  return useQuery(
    [
      'getGeolocation',
      ...(useGeolocationOptionsWithDefault?.keys
        ? useGeolocationOptionsWithDefault.keys
        : []),
    ],
    async () => {
      try {
        const geolocation = await requestNextApiEndpoint(
          NEXT_GEOLOCATION_PARAMS,
        )

        return {
          ...(geolocation?.data ?? {}),
        }
      } catch (error) {
        if (useGeolocationOptionsWithDefault?.throwError) {
          throw new Error(error)
        }
      }
    },
    options,
  )
}
