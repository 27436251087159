import classnames from 'classnames/bind'
import Head from 'next/head'
import { useMemo, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { GlobalThemeColors } from '~/@types/colors'

import { NavigationData } from '@unlikelystudio/react-abstract-components'
import { useMeasureObserver } from '@unlikelystudio/react-hooks'

import NavigationDesktopClick from '~/components/Navigation/Navigation/NavigationDesktopClick'
import NavigationMobile from '~/components/Navigation/Navigation/NavigationMobile'
import type { NavigationProps } from '~/components/Navigation/Navigation/types'
import Banner from '~/components/UI/Banner'
import BannerMarketManager from '~/components/UI/BannerMarket/BannerManager'

import { useBanner } from '~/providers/BannerProvider'

import { serializeJSONLD } from '~/data/json-ld/navigation/serialize-json-ld'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function Navigation({
  className,
  banner,
  isDisabled,
  ...rest
}: NavigationProps) {
  const [inViewRef, inView] = useInView({ initialInView: true })

  const bannerRef = useRef(null)
  const { height: bannerHeight } = useMeasureObserver(
    bannerRef,
    'getBoundingClientRect',
  )
  const { isVisible: isBannerVisible } = useBanner()

  const cssVariables = useMemo(
    () =>
      `body {
        --banner-height: ${bannerHeight}px;
        --banner-displayed: ${
          !isBannerVisible || !banner?.texts?.length ? 0 : 1
        };
      }`,
    [bannerHeight, isBannerVisible, banner],
  )

  return (
    <>
      <div ref={inViewRef} className={css.NavigationScrollRef} />
      <Head>{cssVariables && <style>{cssVariables}</style>}</Head>

      {rest?.allLinks && <NavigationData {...serializeJSONLD(rest.allLinks)} />}

      {!isDisabled && (
        <header
          className={cx(css.Navigation, className, {
            hasScrolled: !inView,
          })}>
          {banner?.displayed &&
            banner?.texts?.length > 0 &&
            isBannerVisible && <Banner ref={bannerRef} {...banner} />}

          <NavigationDesktopClick
            className={cx(css.hideOnSmallScreen, css.desktopNavigation)}
            hasScrolled={!inView}
            {...rest}
          />
          <NavigationMobile
            className={cx(css.hideOnLargeScreen, css.mobileNavigation)}
            hasScrolled={!inView}
            {...rest}
          />
        </header>
      )}
    </>
  )
}

Navigation.defaultProps = {
  theme: GlobalThemeColors.Black,
}

export default Navigation
