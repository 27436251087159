import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { ATTRIBUTES_FOR_FACETING, SIZE_ORDERING } from '~/lib/algolia/constants'
import type { FacetsResult } from '~/lib/algolia/fetch-facets'

import { Facets } from '@unlikelystudio/horizon-algolia'

import useFetchAlgoliaFacets from '~/hooks/useFetchAlgoliaFacets'

export const FilterContext = createContext<FilterType>({})

export function useFilter() {
  return useContext(FilterContext)
}

interface ProviderFilterProps {
  defaultFilters?: Facets
  children: JSX.Element | JSX.Element[]
}

export enum FilterPanelTypes {
  SORT_BY = 'sort_by',
  FILTER_BY = 'filter_by',
}

export enum SortByValues {
  PRICE_ASC = 'price_asc',
  PRICE_DESC = 'price_desc',
  DEFAULT = 'default',
}

export type FilterResultType = Record<string, string | string[] | false>

// Need to be updated with the same keys as OrderResultType
const ALLOWED_SORT_BY = ['order_by']

export type OrderResultType = {
  order_by: SortByValues
}

export type FilterFacet = {
  [key: string]: string[]
}
export interface FilterType {
  panel?: FilterPanelTypes | null
  setPanel?(visible: FilterPanelTypes | null): void
  sortBy?: OrderResultType
  setSortBy?: React.Dispatch<React.SetStateAction<FilterResultType>>
  filters?: FilterResultType
  setFilters?: React.Dispatch<React.SetStateAction<FilterResultType>>
  hasFilters?: boolean
  facets?: FacetsResult[]
}

export function hasFilterSelected(filter: FilterResultType[string]) {
  return (
    filter !== undefined &&
    filter !== false &&
    filter !== null &&
    filter.length > 0
  )
}

function hasFiltersSelected(filters: FilterResultType) {
  // Get all values from filters
  const filterCount = Object.values(filters)
    // Filter non string array values
    ?.filter((item) => hasFilterSelected(item))
    // Flatten the array of string
    ?.flat(3)?.length
  // If there is at least 1 string there is a filter enable
  return filterCount > 0
}

export function getFilteredAlgoliaParams(
  filters: FilterResultType,
): FilterResultType {
  if (!filters) return {}
  return (
    Object.keys(filters)
      // Test if the query filter is a valid key for algolia
      ?.filter(
        (filter) =>
          filter !== null &&
          (ATTRIBUTES_FOR_FACETING.includes(filter) ||
            ALLOWED_SORT_BY?.includes(filter)),
      )
      ?.reduce((cur, key) => {
        let processedValue
        const val = filters[key]
        if (typeof val === 'string')
          // Transform all + from nextJS query
          processedValue = val?.toString()?.replaceAll('+', ' ')
        else if (Array.isArray(val))
          // Transform all + from nextJS query
          processedValue = val.map((val) =>
            val?.toString()?.replaceAll('+', ' '),
          )
        return Object.assign(cur, { [key]: processedValue })
      }, {})
  )
}

export default function FilterProvider({
  defaultFilters,
  children,
}: ProviderFilterProps) {
  const [panel, setPanel] = useState(null)
  const [filters, setFilters] = useState<FilterResultType>({})
  const [sortBy, setSortBy] = useState<OrderResultType>({
    order_by: null,
  })

  const { data: facets, refetch } = useFetchAlgoliaFacets(defaultFilters ?? {})

  const processedFacets = facets?.map((facet) => {
    if (facet.type === 'size')
      return {
        ...facet,
        values: facet.values?.sort((a, b) => {
          return SIZE_ORDERING.indexOf(a.name) < SIZE_ORDERING.indexOf(b.name)
            ? -1
            : 1
        }),
      }
    else return facet
  })
  // Has filter enabled -> if filters were selected & panel was closed
  const hasFilters = hasFiltersSelected(filters) || hasFiltersSelected(sortBy)

  // On filter panel open, fetch facets if they weren't fetched previously
  useEffect(() => {
    if (panel === FilterPanelTypes.FILTER_BY && !processedFacets) {
      refetch()
    }
  }, [panel])

  useEffect(() => {
    return () => {
      setFilters({})
    }
  }, [])

  const value: FilterType = useMemo(
    () => ({
      panel,
      setPanel,
      sortBy,
      setSortBy,
      filters,
      setFilters,
      hasFilters,
      facets: processedFacets,
    }),
    [sortBy, panel, filters, hasFilters, processedFacets],
  )

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  )
}
