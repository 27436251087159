import { useMountedState } from 'react-use'

import { useGeolocation } from '~/providers/GeolocationProvider'

export default function useIsCountryFromIsoCode(isoCodes: string[]) {
  const isMounted = useMountedState()
  const { countryISO } = useGeolocation()

  if (!isMounted) return false
  if (!isoCodes || !Array.isArray(isoCodes)) return null

  return isoCodes
    ?.map((isoCode) => isoCode?.toLocaleLowerCase())
    ?.includes(countryISO?.toLocaleLowerCase())
}
