import classnames from 'classnames/bind'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { GlobalThemeColors } from '~/@types/colors'
import { FooterProps } from '~/@types/footer'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { FORBIDDEN_COUNTRIES_NEWSLETTER } from '~/lib/constants'

import { Accordion } from '@unlikelystudio/react-abstract-components'

import RichText from '~/components/Abstracts/RichText'
import Breadcrumb from '~/components/UI/Breadcrumb'
import SupFooter from '~/components/UI/Footer/SupFooter'
import LangSwitcher from '~/components/UI/LangSwitcher'

import { useNavigation } from '~/providers/NavigationProvider'
import { useStyle } from '~/providers/StyleProvider'

import useIsCountryFromIsoCode from '~/hooks/useCountryFromIsoCode'
import useShop from '~/hooks/useShop'

import FooterLinks from './FooterLinks'
import FooterNewsletter from './FooterNewsletter'
import css from './styles.module.scss'

const cx = classnames.bind(css)

function Footer({
  className,
  newsletter,
  supFooter,
  items,
  reassuranceText,
  pageType,
}: FooterProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const reassuranceStyle = useStyle({
    textPreset: GlobalTextPreset.Label10Haffer,
    color: GlobalThemeColors.DoveGray,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const isForbiddenCountry = useIsCountryFromIsoCode(
    FORBIDDEN_COUNTRIES_NEWSLETTER,
  )

  const { setFooterVisible } = useNavigation()

  const {
    ref: footerInViewRef,
    inView: inView,
    entry,
  } = useInView({
    threshold: 0.2,
  })

  const isFooterVisible =
    (inView && entry?.boundingClientRect?.top > 0) ?? false

  useEffect(() => {
    setFooterVisible(isFooterVisible)
  }, [isFooterVisible])

  const shop = useShop()

  return (
    <div className={cx(css.Footer, className, { isForbiddenCountry })}>
      <span className={css.marker} ref={footerInViewRef} />
      <div className={cx(css.topFooter, gridStyle)}>
        <div className={css.topContent}>
          <Breadcrumb className={css.breadcrumb} />

          <LangSwitcher className={css.langSwitcher} />
        </div>
      </div>

      {shop && supFooter?.items?.length > 0 && (
        <div className={cx(css.supFooter, gridStyle)}>
          <SupFooter
            className={cx(css.supFooterContent)}
            itemClassName={cx(css.supFooterItem)}
            {...supFooter}
          />
        </div>
      )}

      <div className={cx(css.mainFooter, gridStyle)}>
        {!isForbiddenCountry && (
          <FooterNewsletter className={cx(css.newsletter)} {...newsletter} />
        )}

        {items?.length > 0 && (
          <>
            <div className={cx(css.linksContainer, css.hideOnSmallScreen)}>
              {items?.map((links, index) => (
                <FooterLinks
                  key={index}
                  className={cx(css.links)}
                  {...links}
                  index={index}
                />
              ))}
            </div>

            <div className={cx(css.linksContainer, css.hideOnLargeScreen)}>
              <Accordion className={css.accordion}>
                {items?.map((links, index) => (
                  <FooterLinks
                    key={index}
                    className={cx(css.links)}
                    index={index}
                    isAccordion
                    {...links}
                  />
                ))}
              </Accordion>
            </div>
          </>
        )}

        {reassuranceText && (
          <RichText
            className={cx(css.reassurance, reassuranceStyle)}
            ctaPreset={GlobalTextPreset.Cta10_12Haffer}
            render={reassuranceText}
          />
        )}
      </div>
    </div>
  )
}

Footer.defaultProps = {}

export default Footer
