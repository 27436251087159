import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export enum SliderNavigationType {
  Number = 'number',
  Dot = 'dot',
  DotSmall = 'dotSmall',
}

interface SliderNavigationItemProps {
  className?: string
  index?: number
  active?: boolean
  onClick?(): void
}

export interface SliderNavigationProps {
  className?: string
  itemClassName?: string
  max?: number
  index?: number
  setIndex?(index: number): void
  type?: SliderNavigationType
}

const Dot = ({ className, onClick }: SliderNavigationItemProps) => {
  return <span className={cx(css.dot, className)} onClick={onClick} />
}

const DotSmall = ({ className, onClick }: SliderNavigationItemProps) => {
  return <span className={cx(css.dotSmall, className)} onClick={onClick} />
}

function Number({
  className,
  index,
  active,
  onClick,
}: SliderNavigationItemProps) {
  const itemStyle = useStyle({
    textPreset: GlobalTextPreset.Label12_14Haffer,
    color: GlobalThemeColors[active ? 'Black' : 'DoveGray'],
  })

  return (
    <span className={cx(css.number, className, itemStyle)} onClick={onClick}>
      {`0${index + 1}`.padEnd(2)}
    </span>
  )
}

const mapItemType: {
  [key in SliderNavigationType]: (
    args: SliderNavigationItemProps,
  ) => JSX.Element
} = {
  [SliderNavigationType.Number]: Number,
  [SliderNavigationType.Dot]: Dot,
  [SliderNavigationType.DotSmall]: DotSmall,
}

function SliderNavigation({
  className,
  itemClassName,
  max,
  index,
  setIndex,
  type,
}: SliderNavigationProps) {
  const onClick = (index: number) => {
    setIndex(index)
  }

  const Item = mapItemType[type]

  return max > 1 ? (
    <div className={cx(css.SliderNavigation, className)}>
      {Array.from(Array(max)).map((_, i) =>
        Item ? (
          <Item
            key={i}
            className={cx(css.item, itemClassName, { active: i === index })}
            active={i === index}
            index={i}
            onClick={() => onClick(i)}
          />
        ) : null,
      )}
    </div>
  ) : null
}

SliderNavigation.defaultProps = {
  type: SliderNavigationType.Number,
}

export default SliderNavigation
