import classnames from 'classnames/bind'
import { AnimatePresence, m, usePresence } from 'framer-motion'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import { useBodyScrollLock } from '@unlikelystudio/react-hooks'

import { CloseIcon } from '~/components/Abstracts/Icons'

import { usePanel } from '~/providers/PanelProvider'

import { PANEL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type ChidrenFunctionWithProps<
  TProps,
  TReturn = JSX.Element | JSX.Element[],
> = (props: TProps) => TReturn

export interface PanelWrapperProps {
  className?: string
  enterFromLeft?: boolean
  enterFromTop?: boolean
  withoutBorder?: boolean
  withoutCloseButton?: boolean
  onHide?: () => void
  children?:
    | JSX.Element
    | JSX.Element[]
    | ChidrenFunctionWithProps<{ hide?: boolean }>
}

function PanelWrapper({
  className,
  enterFromLeft,
  enterFromTop,
  withoutBorder,
  withoutCloseButton,
  children,
}: PanelWrapperProps) {
  const t = useTranslate()
  const { removeCurrent } = usePanel()
  const [isPresent] = usePresence()
  const componentRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const closeRef = useRef(null)
  const childrenContentRef = useRef<HTMLDivElement>(null)
  const [hide] = useState(false)

  useBodyScrollLock(isPresent)
  useEffect(() => {
    if (isPresent) {
      if (closeRef?.current && !withoutCloseButton) {
        closeRef.current.focus()
      } else if (withoutCloseButton && childrenContentRef?.current) {
        childrenContentRef.current.setAttribute('tabindex', '0')
        childrenContentRef.current.focus()
      }
    } else {
      if (closeRef?.current && !withoutCloseButton) {
        closeRef.current.blur()
      } else if (withoutCloseButton && childrenContentRef?.current) {
        childrenContentRef.current.removeAttribute('tabindex')
        childrenContentRef.current.blur()
      }
    }
  }, [isPresent, withoutCloseButton])

  return (
    <AnimatePresence>
      <m.div
        initial={{ opacity: 0 }}
        animate={{
          opacity: 1,
          transition: { duration: 0.15 },
        }}
        exit={{
          opacity: 0,
          transition: { duration: 0.15 },
        }}
        ref={componentRef}
        className={cx(className, css.PanelWrapper, {
          enterFromLeft,
          enterFromTop,
        })}
      >
        <div ref={contentRef} className={css.content}>
          {!withoutCloseButton && (
            <div className={css.closeIcon} onClick={removeCurrent}>
              <CloseIcon
                className={cx(css.close)}
                aria-label={t(PANEL.CLOSE)}
              />
            </div>
          )}
          <div className={cx(css.childrenWrapper, { withoutBorder })}>
            <div ref={childrenContentRef} className={css.childrenContent}>
              <div className={css.children}>
                {typeof children === 'function' ? children({ hide }) : children}
              </div>
            </div>
          </div>
        </div>
      </m.div>
    </AnimatePresence>
  )
}

PanelWrapper.defaultProps = {
  enterFromLeft: false,
  enterFromTop: false,
}

export default PanelWrapper
