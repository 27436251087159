import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { COOKIE_KEYS } from '~/lib/constants'

import { useGeolocation } from '~/providers/GeolocationProvider'

export default function useGeolocationCookie() {
  const GLOBAL_KEY = COOKIE_KEYS.globalGeolocationPopIn
  const CHECKOUT_KEY = COOKIE_KEYS.checkoutGeolocationPopIn
  const { setDisplayGlobalPopIn, setDisplayCheckoutPopIn } = useGeolocation()

  const acceptGlobalGeoLocation = useCallback(() => {
    Cookies.set(GLOBAL_KEY, '1', { expires: 365 })
    setDisplayGlobalPopIn(false)
  }, [])

  const getGlobalGeoLocation = useCallback(() => {
    return Cookies.get(GLOBAL_KEY) === '1'
  }, [])

  const acceptCheckoutGeoLocation = useCallback(() => {
    Cookies.set(CHECKOUT_KEY, '1', { expires: 365 })
    setDisplayCheckoutPopIn(false)
  }, [])

  const getCheckoutGeoLocation = useCallback(() => {
    return Cookies.get(CHECKOUT_KEY) === '1'
  }, [])

  return {
    acceptGlobalGeoLocation,
    getGlobalGeoLocation,
    acceptCheckoutGeoLocation,
    getCheckoutGeoLocation,
  }
}
