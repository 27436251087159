import classnames from 'classnames/bind'
import React, { useState } from 'react'

import Input, { InputProps } from '~/components/Abstracts/Form/Input'

import uniqueId from '~/utils/unique-id'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface CheckboxProps extends InputProps {
  className?: string
  inputClassName?: string
  contentClassName?: string
  labelClassName?: string
  checkmarkClassName?: string
  checkmarkActiveClassName?: string
  checkmarkActive?: JSX.Element | JSX.Element[] | string | null
  label?: JSX.Element | JSX.Element[] | string
  id?: string
}

function Checkbox({
  className,
  inputClassName,
  contentClassName,
  labelClassName,
  checkmarkClassName,
  checkmarkActiveClassName,
  checkmarkActive,
  errorClassname,
  label,
  disabled,
  id,
  ...inputProps
}: CheckboxProps) {
  const [error, setError] = useState(null)

  const processedId = `${id}_${uniqueId()}`
  const cssError =
    (inputProps.withError ||
      error?.type ||
      Object.keys(error?.types ?? {})?.length > 0) &&
    inputProps.withError
      ? errorClassname
      : null

  return (
    <label
      className={cx(css.Checkbox, className, cssError, { disabled })}
      htmlFor={processedId}
    >
      <Input
        type="checkbox"
        {...inputProps}
        id={processedId}
        className={cx(inputClassName, css.input)}
        onError={(err) => setError(err)}
      />
      <div className={cx(contentClassName, css.contentClassName)}>
        <span className={cx(css.checkmark, checkmarkClassName, cssError)}>
          <span
            className={cx(
              css.checkmarkActive,
              checkmarkActiveClassName,
              cssError,
            )}
          >
            {checkmarkActive ?? null}
          </span>
        </span>
        <span className={cx(css.label, cssError, labelClassName)}>{label}</span>
      </div>
    </label>
  )
}

Checkbox.defaultProps = {}

export default Checkbox
