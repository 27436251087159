import { getIntlLocale } from '~/utils/locales'

/**
 * It takes a date, a locale, and some options, and returns a formatted date string
 * @param {string | number | Date} date - The date to be formatted.
 * @param locale - The locale to use for formatting.
 * @param [options] - Intl.DateTimeFormatOptions
 * @returns A string with the date in the format of DD/MM/YY (default)
 */
export default function serializeDate(
  date: string | number | Date,
  locale,
  options?: Intl.DateTimeFormatOptions,
) {
  const intl = getIntlLocale(locale)
  return date
    ? new Intl.DateTimeFormat(intl, {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        ...options,
      }).format(new Date(date))
    : null
}
