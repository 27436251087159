export default function normalizeString(str: string) {
  if (!str || typeof str !== 'string') return null
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    ?.toLocaleLowerCase()
}

export function normalizeObj<T>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj).map(([_, v]) => [
      _,
      typeof v === 'string' ? normalizeString(v) : v,
    ]),
  ) as T
}
