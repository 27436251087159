import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query'
import { ErrorOption } from 'react-hook-form'
import { QUERY_KEYS } from '~/lib/constants'
import {
  requestNextApiEndpoint,
  SHOPIFY_METAFIELDS_SET_PARAMS,
} from '~/lib/handle-api'
import { MetafieldInput } from '~/lib/shopify/metafields'

import { useCustomerContext } from '@unlikelystudio/react-ecommerce-hooks'

import useLocale from '~/hooks/useLocale'
import useOnMutationError from '~/hooks/useOnMutationError'

interface UseSetMetafieldsMutationParams {
  metafields: Omit<MetafieldInput, 'ownerId'>[]
}

export default function useSetMetafields(
  mutationOptions?: UseMutationOptions<
    any,
    any,
    UseSetMetafieldsMutationParams
  >,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const queryClient = useQueryClient()
  const { customerAccessToken } = useCustomerContext()
  const onMutationError = useOnMutationError(setError)
  const locale = useLocale()

  return useMutation<any, any, UseSetMetafieldsMutationParams>(
    async ({ metafields }: UseSetMetafieldsMutationParams) => {
      return await requestNextApiEndpoint(SHOPIFY_METAFIELDS_SET_PARAMS, {
        accessToken: customerAccessToken,
        payload: {
          locale,
          metafields,
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: (data, ...rest) => {
        queryClient.invalidateQueries([QUERY_KEYS.METAFIELDS], { exact: false })
        mutationOptions?.onSuccess(data, ...rest)
      },
      onError: (err, ...rest) => {
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}
