import classnames from 'classnames/bind'
import { useSelect, UseSelectStateChange } from 'downshift'
import { SelectHTMLAttributes, useEffect, useState } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import {
  DownshiftSelectMockProps,
  Option,
} from '~/components/Form/DownshiftSelectMock'
import { CheckmarkSelectIcon } from '~/components/Icons'
import { ArrowBottomIcon } from '~/components/UI/Filters'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface HandleSelectedItemChangeParams {
  selectedItem?: Option
}

export interface DownshiftSelectProps
  extends SelectHTMLAttributes<any>,
    DownshiftSelectMockProps {
  forwardRef?: any
  isInMarketPanel?: boolean
  options?: Option[]
  layout?: 'default' | 'light' | 'big'
  customLabel?: string
  borderBottom?: boolean
  withOverflow?: boolean
  disabled?: boolean
  onChange?: (values) => void
  shouldAutoOpen?: boolean
  openingDirection?: 'up' | 'down'
}

function DownshiftSelect({
  className,
  productSelect,
  langSelect,
  dateSelect,
  accountSelect,
  currentValue,
  customLabel,
  options,
  onChange,
  withOverflow,
  disabled,
  shouldAutoOpen = false,
  openingDirection = 'down',
}: DownshiftSelectProps) {
  const [currentItem, setCurrentItem] = useState<Option>(currentValue)
  const [isOpen, setIsOpen] = useState<boolean>(shouldAutoOpen ?? false)

  const selectTextStyle = useStyle({
    textPreset: GlobalTextPreset.Input12Haffer,
    textStyling: GlobalTextStyling.UpperCase,
    color: GlobalThemeColors.Black,
  })

  useEffect(() => {
    if (shouldAutoOpen) {
      setIsOpen(true)
    }
  }, [shouldAutoOpen])

  const itemToString = (item) => item?.label ?? customLabel ?? ''

  const handleSelectedItemChange = ({
    selectedItem,
  }: HandleSelectedItemChangeParams) => {
    setCurrentItem(selectedItem)
    setIsOpen(false)
    onChange && onChange(selectedItem)
  }

  const handleIsOpenChange = (params: UseSelectStateChange<any>) => {
    setIsOpen(params.isOpen)
  }

  const { getToggleButtonProps, getMenuProps, getItemProps } = useSelect({
    items: options,
    itemToString,
    initialSelectedItem: currentItem,
    onSelectedItemChange: handleSelectedItemChange,
    initialIsOpen: shouldAutoOpen,
    onIsOpenChange: handleIsOpenChange,
  })

  useEffect(() => {
    setCurrentItem(currentValue)
  }, [currentValue])

  return (
    <div
      className={cx(className, css.DownshiftSelect, {
        productSelect,
        langSelect,
        dateSelect,
        accountSelect,
        isOpen,
        disabled,
      })}>
      <button
        className={cx(selectTextStyle, css.button, { disabled })}
        type="button"
        {...getToggleButtonProps({ disabled })}>
        <span>
          <CheckmarkSelectIcon className={cx(css.checkIcon)} />
          <span>{itemToString(currentItem)}</span>
        </span>
        <ArrowBottomIcon className={css.icon} />
      </button>
      {!disabled && (
        <ul
          className={cx(css.options, {
            overflow: options?.length > 5 && withOverflow,
            isOpeningUp: openingDirection === 'up',
            isOpen,
          })}
          {...getMenuProps()}
          aria-label="Lang Switcher options list">
          {isOpen &&
            options?.map(({ label, value }) => {
              const index = options.findIndex(
                (option) => option?.value === value,
              )

              if (!index && index !== 0) return null

              return (
                <li
                  key={`${label}${index}`}
                  className={cx(selectTextStyle, css.option)}
                  {...getItemProps({ item: label, index })}>
                  <span>{label}</span>
                </li>
              )
            })}
        </ul>
      )}
    </div>
  )
}

export default DownshiftSelect
