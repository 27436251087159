import classnames from 'classnames/bind'
import React from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import {
  Accordion,
  AccordionPanel,
} from '@unlikelystudio/react-abstract-components'

import { MinusIcon, PlusIcon } from '~/components/Abstracts/Icons'
import DotCheckbox from '~/components/Form/DotCheckbox'
import { FilterPanelProps } from '~/components/UI/Filters'

import { useStyle } from '~/providers/StyleProvider'

import { FILTER, GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function Filter({ className, filtersColumns }: FilterPanelProps) {
  const t = useTranslate()

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title12_14HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={cx(className, css.Filter)}>
      <Accordion className={css.accordion} index={0}>
        {filtersColumns?.map(({ type, values, inputType }, index) => {
          return (
            <AccordionPanel
              ariaLabels={{
                open: t(GENERAL.ACCORDION_OPEN),
                close: t(GENERAL.ACCORDION_CLOSE),
              }}
              index={index}
              className={css.accordionPanel}
              key={`accordion_panel_${index}`}
              head={(isActive: boolean) => {
                return (
                  <div
                    className={cx(css.panelHead, titleStyle, {
                      isActive,
                    })}
                  >
                    <div>{t(FILTER.FACET(type))}</div>
                    {isActive ? (
                      <MinusIcon className={css.icon} fill="#000000" />
                    ) : (
                      <PlusIcon className={css.icon} fill="#000000" />
                    )}
                  </div>
                )
              }}
              body={
                <div className={css.panelBody}>
                  {values?.map(({ name, label }, subindex) => {
                    return (
                      <DotCheckbox
                        className={css.columnFilter}
                        key={`filter_${index}_${subindex}`}
                        id={`filter_${index}_${subindex}`}
                        label={label}
                        name={type}
                        value={name}
                        type={inputType}
                      />
                    )
                  })}
                </div>
              }
            />
          )
        })}
      </Accordion>
    </div>
  )
}

Filter.defaultProps = {}

export default Filter
