import classnames from 'classnames/bind'
import React from 'react'

import {
  OrderElement,
  OrderElementItem,
} from '~/components/Account/OrdersGrid/types'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface PanelBodyProps extends Pick<OrderElement, 'items'> {
  className?: string
  children?(
    item: OrderElementItem,
    isButtonsVisible: boolean,
  ): JSX.Element | JSX.Element[]
}

function PanelBody({ className, items, children }: PanelBodyProps) {
  let fragranceItemsCount = 0
  let fashionItemsCount = 0
  let firstFragranceIndex = -1
  let firstFashionIndex = -1

  items?.fashionProducts?.forEach((product, index) => {
    if (!product.isGift && !product.isSample && !product.isPouch) {
      fashionItemsCount++

      if (firstFashionIndex == -1) {
        firstFashionIndex = index
      }
    }
  })

  items?.fragranceProducts?.forEach((product, index) => {
    if (!product.isGift && !product.isSample && !product.isPouch) {
      fragranceItemsCount++

      if (firstFragranceIndex == -1) {
        firstFragranceIndex = index
      }
    }
  })

  return (
    <div className={cx(css.PanelBody, className)}>
      {items?.fragranceProducts?.length > 0 &&
        items?.fragranceProducts?.map((item, index) => {
          const isButtonsVisible =
            fragranceItemsCount !== 0 &&
            firstFragranceIndex === index &&
            !item.isGift &&
            !item.isPouch &&
            !item.isSample

          return (
            <div key={`${item.id}_${index}`} className={cx(css.line)}>
              {children(item, isButtonsVisible)}
            </div>
          )
        })}

      {items?.fashionProducts?.length > 0 &&
        items?.fashionProducts?.map((item, index) => {
          const isButtonsVisible =
            fashionItemsCount !== 0 &&
            firstFashionIndex === index &&
            !item.isGift &&
            !item.isPouch &&
            !item.isSample

          return (
            <div
              key={`${item.id}_${index}`}
              className={cx(css.line, {
                isMixed: index === 0 && items.isOrderMixed,
              })}>
              {children(item, isButtonsVisible)}
            </div>
          )
        })}
    </div>
  )
}

export default PanelBody
