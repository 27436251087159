import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { FooterNewsletterProps } from '~/@types/footer'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { GYGIA_TAGS } from '~/lib/shopify-tags'

import RichText from '~/components/Abstracts/RichText'
import Newsletter from '~/components/Form/Newsletter'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function FooterNewsletter({
  className,
  headline,
  text,
  ...newsletterProps
}: FooterNewsletterProps) {
  const headlineStyle = useStyle({
    textPreset: GlobalTextPreset.Title24HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Haffer,
    color: GlobalThemeColors.DoveGray,
  })

  return (
    <div className={cx(css.FooterNewsletter, className)}>
      <Newsletter {...newsletterProps} fromTag={GYGIA_TAGS.WEBSITE_FOOTER}>
        {headline && (
          <div className={cx(css.headline, headlineStyle)}>{headline}</div>
        )}
        <RichText className={cx(css.text, textStyle)} render={text} />
      </Newsletter>
    </div>
  )
}

FooterNewsletter.defaultProps = {}

export default FooterNewsletter
