export default function getAlgoliaQueryString(obj: { [key: string]: any }) {
  return Object?.keys(obj)
    ?.filter((key) => Boolean(obj?.[key]))
    ?.reduce((queryString, currentKey) => {
      const value = obj?.[currentKey]
      const formattedValue = Array.isArray(value) ? value : [value]

      const currentQueryString = formattedValue?.reduce(
        (prevValue, currentValue) => {
          return {
            ...prevValue,
            [`${currentKey}_${currentValue}`]: true,
          }
        },
        {},
      )
      return {
        ...queryString,
        ...currentQueryString,
      }
    }, {})
}
