import { imageLoaderPrismic, HOSTNAME as HOSTNAME_PRISMIC } from './prismic'
import { imageLoaderShopify, HOSTNAME as HOSTNAME_SHOPIFY } from './shopify'

export interface LoaderParams {
  src: string
  quality?: number
  width: number
  rect?: string
}

export const imageLoaderStoryblok = ({ src, width, quality }: LoaderParams) => {
  return src.indexOf('.svg') === -1
    ? `${src}/m/${width}x0/filters:quality(${quality || 80})`
    : src
}

const LOADERS: Record<string, (params: LoaderParams) => string> = {
  [HOSTNAME_PRISMIC]: imageLoaderPrismic,
  [HOSTNAME_SHOPIFY]: imageLoaderShopify,
}

const REGEX = /^https?:\/\/([^/]+)/

export function imageLoader(params: LoaderParams) {
  // Check if it's an SVG
  if (params.src.indexOf('.svg') !== -1) return params.src

  // Get Hostname
  const result = REGEX.exec(params.src)
  if (!result) return params.src

  return LOADERS?.[result[1]]?.(params) ?? params.src
}
