import NextImage, {
  ImageLoader,
  ImageProps as NextImageProps,
} from 'next/legacy/image'
import {
  CSSProperties,
  ForwardedRef,
  forwardRef,
  useCallback,
  useState,
} from 'react'

import Ratio from '~/components/Abstracts/Ratio'

import { Sizes, useSizesFromBreakpoints } from './maths'

export type ImageProps = Omit<NextImageProps, 'src' | 'width' | 'height'> & {
  sizesFromBreakpoints?: Sizes
  width?: number
  height?: number
  src: string
  ratio?: string
  asPlaceholder?: boolean
  imageClassName?: string
  placeholderClassName?: string
  screens?: Record<string, string>
  imageLoader?: ImageLoader
  style?: CSSProperties
}

function ImageForwarded(
  {
    sizes,
    sizesFromBreakpoints,
    className,
    imageClassName,
    asPlaceholder = false,
    onLoadingComplete,
    ratio,
    onClick,
    placeholderClassName,
    screens,
    imageLoader,
    style,
    ...props
  }: ImageProps,
  ref?: ForwardedRef<HTMLDivElement>,
) {
  const processedSizes = useSizesFromBreakpoints(
    sizesFromBreakpoints ?? [],
    props.src,
    screens,
  )

  const [loaded, setLoaded] = useState(false)

  const onLoadingCompleteCallback = useCallback(
    (img: HTMLImageElement) => {
      onLoadingComplete?.(img)

      asPlaceholder && setLoaded?.(true)
    },
    [onLoadingComplete, setLoaded, asPlaceholder],
  )

  const placeholderStyle: CSSProperties = {
    zIndex: 1,
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    transition: 'opacity 0.1s linear',
    opacity: loaded ? 0 : 1,
  }

  const basicProps = {
    ref,
    className,
    style,
  }

  const renderChildren = (style: CSSProperties | null) => (
    <>
      {!props?.priority && asPlaceholder && (
        <span
          className={placeholderClassName ?? undefined}
          style={placeholderStyle}
        />
      )}

      <NextImage
        className={imageClassName}
        sizes={processedSizes ? processedSizes : sizes ?? undefined}
        loader={imageLoader}
        onClick={onClick}
        onLoadingComplete={onLoadingCompleteCallback}
        style={style ?? undefined}
        {...props}
      />
    </>
  )

  return props.src ? (
    ratio ? (
      <Ratio {...basicProps} ratio={ratio}>
        {renderChildren(null)}
      </Ratio>
    ) : (
      <div {...basicProps}>{renderChildren(null)}</div>
    )
  ) : null
}

export const Image = forwardRef<HTMLDivElement, ImageProps>(ImageForwarded)

export type { LoaderParams } from './loader'
export type { Size, Sizes } from './maths'
