import PrismicRichText from '~/components/Abstracts/Prismic'
import { RichTextBlocks } from '~/components/Abstracts/RichText'

export function stringIsNotEmpty(str: string) {
  return (
    str !== '' && str !== null && str !== undefined && typeof str === 'string'
  )
}

export function isRTFilled(content: RichTextBlocks) {
  if (!content) return false
  const str = PrismicRichText.asText(content)
  return str !== '' && content !== null && content !== undefined
}
