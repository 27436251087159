import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useLayoutEffect, useState } from 'react'
import { useSessionStorage } from 'react-use'

import LoadingComponent from '~/components/Abstracts/LoadingComponent'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { usePopIn } from '~/providers/PopInProvider'

const ALLOWED_PATHNAMES = [
  '/',
  '/fragrance',
  // '/fragrance/[uid]',
  // '/fragrance/product/[uid]',
  // '/fashion',
  // '/fashion/[uid]',
  // '/fashion/product/[uid]',
]

const DELAY = 5000

const PopinContests = dynamic(
  () => import('~/components/Popins/PopinContests'),
  {
    loading: () => <LoadingComponent />,
  },
)

export function usePopinContests() {
  const { pathname } = useRouter()
  const { global } = useGlobalData()
  const { add, removeCurrent } = usePopIn()

  const [sessionIsShown, sessionSetIsShown] = useSessionStorage(
    'POPIN_CONTESTS_IS_SHOWN',
    false,
  )

  const [isShown, setIsShown] = useState(sessionIsShown)

  const handleOnPopinClose = () => {
    removeCurrent()
    sessionSetIsShown(true)
  }

  useLayoutEffect(() => {
    if (!ALLOWED_PATHNAMES.includes(pathname)) return

    setIsShown(() => {
      if (
        global?.popinContests?.backgroundImage &&
        global?.popinContests?.paragraph &&
        !global?.popinContests?.shouldHidePopinContests &&
        !isShown
      ) {
        setTimeout(
          () =>
            add(
              <PopinContests
                {...global.popinContests}
                UITheme="light"
                onPopinClose={handleOnPopinClose}
              />,
              {},
              { onClose: handleOnPopinClose, clickOutside: true },
            ),
          DELAY,
        )
      }

      return true
    })
  }, [])
}
