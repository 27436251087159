import processEnvPublic from 'unlikely-env/public'
import {
  DEFAULT_CMS_LANG,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_INTL,
  DEFAULT_LANGUAGE,
  DEFAULT_LOCALE,
  DEFAULT_MARKET,
  LOCALES,
  PRISMIC_SHOPIFY_PRODUCT_KEY,
} from '~/lib/constants'

import markets from 'config/markets.json'
import multistore from 'config/multistore.json'

import { ShopifySFLanguageCode } from '@unlikelystudio/commerce-connector'

export type MutltistoreEntry = {
  currency: string
  cms_lang: string
  language: string
  intl: string
  market: string
  name: string
  shop: boolean | Record<string, boolean>
  parcel_country_code?: string
}

export type MutltistoreEntryKeys = keyof MutltistoreEntry

// Can be en or en-eu or ja-jp ...
export function getCMSLang(locale: string) {
  return multistore?.[locale]?.cms_lang ?? DEFAULT_CMS_LANG
}

// Only languague code like en / fr / ja ...
export function getLanguage(locale: string) {
  return multistore?.[locale]?.language ?? DEFAULT_LANGUAGE
}

export function isDefaultLocale(locale: string) {
  return locale === DEFAULT_LOCALE
}

export function getPriceMarketKey(market: string) {
  return `price_${market ?? DEFAULT_MARKET}`
}

export function getPrismicShopifyKey() {
  return PRISMIC_SHOPIFY_PRODUCT_KEY
}

export function getCountryCode(locale: string) {
  const marketKey = getMarketName(locale)

  // Get the first item of the choosen market
  if (!markets?.[marketKey]?.[0])
    console.warn(`[Market] Not founded market for the locale ${locale}`)
  return markets?.[marketKey]?.[0] ?? DEFAULT_COUNTRY_CODE
}

export function getMarketName(locale: string) {
  return multistore?.[locale]?.market ?? DEFAULT_MARKET
}

export function getIntlLocale(locale: string) {
  return multistore?.[locale]?.intl ?? DEFAULT_INTL
}

export function getCountryName(locale: string) {
  return multistore?.[locale ?? DEFAULT_LOCALE]?.name
}

export function getShopifyLanguageCodeFromLocale(
  locale: string,
): ShopifySFLanguageCode {
  const lang = getLanguage(locale)?.toUpperCase()
  return lang as ShopifySFLanguageCode
}

// ParcelLab specific
export function getParcelLabCountryCode(locale: string) {
  return multistore?.[locale]?.parcel_country_code ?? null
}

export function hasShop(locale: string, type?: string) {
  if (!locale) return false

  if (processEnvPublic('NEXT_PUBLIC_DISABLE_ECOM') === 'true') return false
  if (processEnvPublic('NEXT_PUBLIC_ALL_ECOM') === 'true') return true
  if (multistore?.[locale ?? DEFAULT_LOCALE]?.shop === false) return false
  if (type) {
    const processedType =
      multistore?.[locale ?? DEFAULT_LOCALE]?.shop?.[type] ?? null

    if (processedType === null || processedType === true) return true
    else return false
  } else
    return Object.values(
      multistore?.[locale ?? DEFAULT_LOCALE]?.shop,
    )?.includes(true)
}

export function getLocaleFromPrismicLang(lang?: string) {
  return (
    Object.entries(multistore).find(([, value]) => {
      return value?.cms_lang === lang
    })?.[0] ?? null
  )
}

export function getMultistoreItemFromProperty(key: string, value: string) {
  const locale =
    LOCALES.find((locale) => multistore[locale][key] === value) ?? null

  return locale ? { locale, ...multistore[locale] } : null
}
