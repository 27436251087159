import classnames from 'classnames/bind'
import React from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import DotCheckbox, { DotCheckboxType } from '~/components/Form/DotCheckbox'
import { FilterColumn, FilterPanelProps } from '~/components/UI/Filters'

import { useStyle } from '~/providers/StyleProvider'

import { FILTER } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function ColumnFilter({ type, values, inputType }: FilterColumn) {
  return (
    <>
      {values?.map(({ name, label }, index) => {
        return (
          <DotCheckbox
            name={type}
            value={name}
            className={css.columnFilter}
            key={`filter_${index}`}
            id={`filter_${index}`}
            label={label}
            type={inputType}
          />
        )
      }) ?? null}
    </>
  )
}

function Filter({ className, filtersColumns }: FilterPanelProps) {
  const t = useTranslate()
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title12_14HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={cx(className, css.Filter)}>
      {filtersColumns?.map(({ type, values, ...rest }, index) => {
        return (
          <div className={css.column} key={`column_${index}`}>
            {type && (
              <div className={cx(css.columnTitle, titleStyle)}>
                {t(FILTER.FACET(type))}
              </div>
            )}

            {values?.length > 0 && (
              <div
                className={cx(css.columnFilters, {
                  scrollable: values?.length > 4,
                })}
              >
                <ColumnFilter
                  type={type}
                  inputType={DotCheckboxType.CHECKBOX}
                  values={values}
                  {...rest}
                />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}

Filter.defaultProps = {}

export default Filter
