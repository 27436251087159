import jwtDecode from 'jwt-decode'
import processEnvPublic from 'unlikely-env/public'

import {
  GetShopifyAdminClientOptions,
  GetShopifyStorefrontClientOptions,
} from '@unlikelystudio/commerce-connector'

export type AdminApiObject = {
  multipassToken: string
} & GetShopifyAdminClientOptions

/**
 * Given a url, replace the domain name of the url with the new domain name
 * @param url - The URL of the page to be loaded.
 * @returns the url with the domain name changed.
 */
export function changeDomainUrlCheckout(url) {
  if (url?.indexOf('ninaricci-global.myshopify.com') > -1)
    return url.replace(
      'ninaricci-global.myshopify.com',
      'checkout.ninaricci.com',
    )
  else return url
}

export function getStorePublicCredentials(
  withCheckoutReplaceUrl = true,
): GetShopifyStorefrontClientOptions {
  const token = processEnvPublic('NEXT_PUBLIC_SHOPIFY_TOKEN')

  const decodedToken = (
    token ? jwtDecode(token) : {}
  ) as GetShopifyStorefrontClientOptions

  if (!decodedToken) return null

  const storeUrl = withCheckoutReplaceUrl
    ? changeDomainUrlCheckout(decodedToken?.storeUrl)
    : decodedToken?.storeUrl

  if (!decodedToken?.storeUrl || !decodedToken?.storefrontAccessToken)
    console.warn('[Shopify] Missing storefront credentials')

  return (
    {
      ...decodedToken,
      storeUrl,
    } ?? null
  )
}
