import { createContext, useContext, useMemo } from 'react'

import { GlobalProps } from '~/data/global-data/'

export type Settings = {
  wishlistEnabled?: boolean
  stockAlertEnabled?: boolean
  loveLetterEnabled?: boolean
}

export const SettingsContext = createContext<Settings>({})

export function useSettings() {
  return useContext(SettingsContext)
}

export interface SettingsProviderProps {
  settings: GlobalProps['settings']
  children?: JSX.Element | JSX.Element[]
}

export default function SettingsProvider({
  children,
  settings,
}: SettingsProviderProps) {
  const value: Settings = useMemo(
    () => ({
      ...settings,
    }),
    [settings],
  )

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  )
}
