import { Variant } from '~/@types/product'
import {
  isPDP,
  isPLP,
  PRISMIC_TYPES,
  TPrismicTypesKey,
} from '~/lib/prismic-types'

import { PRODUCT_TYPE_TRACKING } from '~/components/Panels/CartPanel/CartContent/types'

import { roundDecimal } from '~/utils/maths/rounded'

/**
 * It takes a page type and a slice name and returns a string that can be used to identify the list tracking param
 * @param {TPrismicTypesKey} pageType - The page type of the page you're on.
 * @param {string} [sliceName] - The name of the slice you want to get the list name for.
 * @returns A function that returns a string
 */
export function getListName(
  pageType: TPrismicTypesKey,
  sliceName?: string,
): string {
  if (!pageType) return null

  let type = 'content'

  if (isPDP(pageType)) type = 'pdp'
  else if (isPLP(pageType)) type = 'plp'
  else if (pageType === PRISMIC_TYPES.HOMEPAGE) type = 'home'
  else if (pageType === PRISMIC_TYPES.ERROR_PAGE) type = 'error'
  else if (pageType === PRISMIC_TYPES.STORE_LOCATOR) type = 'storelocator'
  else if (
    pageType === PRISMIC_TYPES.ACCOUNT ||
    pageType === PRISMIC_TYPES.FORGOT_PASSWORD ||
    pageType === PRISMIC_TYPES.RESET_PASSWORD ||
    pageType === PRISMIC_TYPES.LOGIN ||
    pageType === PRISMIC_TYPES.ENABLE_ACCOUNT ||
    pageType === PRISMIC_TYPES.REGISTER
  )
    type = 'account'
  else if (
    pageType === PRISMIC_TYPES.CONTACT ||
    pageType === PRISMIC_TYPES.LEGAL ||
    pageType === PRISMIC_TYPES.LEGAL_ITEM ||
    pageType === PRISMIC_TYPES.FAQ ||
    pageType === PRISMIC_TYPES.FAQ_ITEM
  )
    type = 'information'
  else if (pageType === PRISMIC_TYPES.SEARCH) type = 'search results'
  else if (
    pageType === PRISMIC_TYPES.ARTICLE ||
    pageType === PRISMIC_TYPES.UNIVERSAL ||
    pageType === PRISMIC_TYPES.LOOKBOOK ||
    pageType === PRISMIC_TYPES.LIVESTREAM
  )
    type = 'content'

  // Delete /sliceName if PLP page
  const processedSliceName =
    sliceName && !isPLP(pageType) ? `/${sliceName?.toLowerCase()}` : ''

  return `${type?.toLowerCase()}${processedSliceName}`
}

/**
 * It takes a Prismic type and returns a GTM type
 * @param {TPrismicTypesKey} [type] - The type of the product.
 * @returns A function that returns a string
 */
export function getGTMTypeFromPrismicType(type?: string) {
  switch (type) {
    case PRISMIC_TYPES.PRODUCT_FASHION:
      return 'fashion'
      break
    case PRISMIC_TYPES.PRODUCT_FRAGRANCE:
    case PRISMIC_TYPES.PRODUCT_SETS:
      return 'fragrances'
      break
    default:
      return type
      break
  }
}

/**
 * It returns the business unit of a page based on the Prismic type of the page
 * @param {string} [type] - The Prismic type of the page.
 * @returns The business unit
 */
export function getBusinessUnitFromPrismicType(type?: string) {
  switch (type) {
    case PRISMIC_TYPES.HOMEPAGE:
      return 'multi'
      break
    case PRISMIC_TYPES.PRODUCT_FASHION:
    case PRISMIC_TYPES.ROOT_CATEGORY_FASHION:
    case PRISMIC_TYPES.CATEGORY_FASHION:
      return 'fashion'
      break
    case PRISMIC_TYPES.PRODUCT_FRAGRANCE:
    case PRISMIC_TYPES.PRODUCT_SETS:
    case PRISMIC_TYPES.ROOT_CATEGORY_FRAGRANCE:
    case PRISMIC_TYPES.CATEGORY_FRAGRANCE:
      return 'fragrances'
      break
    default:
      return 'none'
      break
  }
}

/**
 * This TypeScript function calculates the discount percentage of a product variant based on its price
 * and compare at price.
 * @param {Variant} variant - The variant parameter is an object that represents a specific version or
 * variation of a product. It contains information such as the price, compareAtPrice (the original
 * price before any discounts), and other attributes that differentiate it from other variants of the
 * same product.
 * @returns This function returns the discount percentage of a product variant. If the variant has a
 * `compareAtPriceAmount` (the original price) and a `priceAmount` (the current price), it calculates
 * the discount percentage by subtracting the `priceAmount` from the `compareAtPriceAmount`, dividing
 * the result by the `compareAtPriceAmount`, multiplying by 100, and rounding to 2
 */
export function getDiscountPercentOfProduct(variant: Variant) {
  return variant?.compareAtPriceAmount
    ? `${roundDecimal(
        (1 -
          parseFloat(variant?.priceAmount) /
            parseFloat(variant?.compareAtPriceAmount)) *
          100,
        2,
      )}`
    : null
}

/**
 * This TypeScript function calculates the discount value of a product variant based on its
 * compareAtPriceAmount and priceAmount properties.
 * @param {Variant} variant - The `variant` parameter is an object that represents a specific variation
 * of a product. It contains information such as the price, compare-at price, and other attributes that
 * differentiate it from other variants of the same product.
 * @returns The function `getDiscountValueOfProduct` returns a string that represents the difference
 * between the `compareAtPriceAmount` and `priceAmount` properties of a `Variant` object, rounded to
 * two decimal places. If the `compareAtPriceAmount` property is not defined, the function returns
 * `null`.
 */
export function getDiscountValueOfProduct(variant: Variant) {
  return variant?.compareAtPriceAmount
    ? `${roundDecimal(
        parseFloat(variant?.compareAtPriceAmount) -
          parseFloat(variant?.priceAmount),
        2,
      )}`
    : null
}

/**
 * It returns the compareAtPriceAmount property of the variant object if it exists, otherwise it
 * returns null
 * @param {Variant} variant - Variant - this is the variant object that is passed to the function.
 * @returns The compareAtPriceAmount of the variant
 */
export function getNonDiscountedPriceProduct(variant: Variant) {
  return variant?.compareAtPriceAmount
    ? `${variant?.compareAtPriceAmount}`
    : null
}

export function getProductTypeTracking(type: string) {
  return type === PRISMIC_TYPES.PRODUCT_SETS
    ? PRODUCT_TYPE_TRACKING.SETS
    : PRODUCT_TYPE_TRACKING.PRODUCT
}
