import { Elements, RichTextBlock } from '~/components/Abstracts/Prismic/types'
import { RichTextBlocks } from '~/components/Abstracts/RichText'

export const nElements = (n: number, element: any[]) =>
  Array.from(Array(n).keys()).map(() => element[0])

export const asRichText = ({
  type = Elements.paragraph,
  text,
  spans = [],
}: RichTextBlock): RichTextBlocks => [
  {
    type,
    text,
    spans,
  },
]

export const LINK = { href: '/' }

export const INLINE_CTA_LINK = { href: '/', children: 'Fragrances' }

export const SQUARED_CTA_LINK = { href: '/', children: 'All Products' }

export const PLACEHOLDER = 'Placeholder'

export const INLINE_CTA = 'Discover'

export const SQUARED_CTA = 'Suscribe'

export const COLOR_HEXA = '#B1B08D'

export const PRICE = '14€'

export const DATE = '10/03/2021'

export const PIN = asRichText({ type: Elements.label, text: 'New' })

export const H1 = asRichText({
  type: Elements.heading1,
  text: 'Les belles de Nina',
})

export const H2 = asRichText({
  type: Elements.heading2,
  text: 'New show of Nina',
})

export const LABEL_PARAGRAPH = asRichText({
  type: Elements.label,
  text: 'Eau de parfum',
})

export const SUBTITLE_PARAGRAPH = asRichText({
  type: Elements.label,
  text: 'Short Information',
})

export const SMALL_PARAGRAPH = asRichText({
  type: Elements.paragraph,
  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hac iaculis tortor, augue magna cras sit aliquam varius.',
})

export const SINGLE_PARAGRAPH = asRichText({
  type: Elements.paragraph,
  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Hac iaculis tortor, augue magna cras sit aliquam varius. Suspendisse pulvinar erat massa a purus nec. Nam arcu hac tortor nam est quam. Nisl egestas tristique nisi, faucibus vitae a. At neque, sit at accumsan, volutpat vel in. Turpis volutpat orci, quis id ultrices massa nunc. Condimentum dui fames amet sed dolor. At dui turpis scelerisque quis posuere. At et non tempor pretium. Blandit feugiat eu sit auctor consectetur elit elementum. Venenatis, netus urna bibendum egestas id aliquet odio.',
})

export const MULTIPLE_PARAGRAPH = nElements(2, SINGLE_PARAGRAPH)
