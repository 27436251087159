import React from 'react'

import { Elements } from '~/components/Abstracts/Prismic/types'
import Slice404 from '~/components/Slices/Slice404'

import { asRichText } from '~/data/prismic/texts'

function BrokenPagePlaceholder() {
  const title = asRichText({ type: Elements.paragraph, text: 'Error' })
  const text = asRichText({
    type: Elements.paragraph,
    text: 'We are sorry, an error occured.',
  })

  return (
    <Slice404
      title={title}
      text={text}
      cta={{ href: '/', children: 'Go back to homepage' }}
    />
  )
}

BrokenPagePlaceholder.defaultProps = {}

export default BrokenPagePlaceholder
