export const ALGOLIA_HITS_PER_PAGE = 8
export const ALGOLIA_PRODUCT_INDEX = 'horizon_product_migration'
export const ALGOLIA_SHOPIFY_ID_PROPERTY_KEY = 'objectID'
export const ALGOLIA_PRISMIC_ID_PROPERTY_KEY = 'prismicID'
export const ALGOLIA_PAGE_TYPE_PROPERTY_KEY = 'page_type'
export const ALGOLIA_PRODUCT_TYPE_PROPERTY_KEY = 'product_type'
export const ALGOLIA_PRODUCT_IS_INDEXABLE_KEY = 'is_indexable'
export const REPLICAS_SUFFIX = {
  PRICE_ASC: 'price_asc',
  PRICE_DESC: 'price_desc',
  DEFAULT: '',
}

export const algoliaDefaultRanking = [
  'typo',
  'geo',
  'words',
  'filters',
  'proximity',
  'attribute',
  'exact',
  'custom',
]

export const replicas = [
  {
    name: `_${REPLICAS_SUFFIX.PRICE_ASC}`,
    ranking: 'asc(price)',
  },
  {
    name: `_${REPLICAS_SUFFIX.PRICE_DESC}`,
    ranking: 'desc(price)',
  },
]

export const DEFAULT_FACETS = [
  ALGOLIA_PRISMIC_ID_PROPERTY_KEY,
  ALGOLIA_PAGE_TYPE_PROPERTY_KEY,
  ALGOLIA_PRODUCT_TYPE_PROPERTY_KEY,
  'size',
]
export const FASHION_FACETS = ['color']
export const FRAGRANCE_FACETS = ['intensity']

export const ATTRIBUTES_FOR_FACETING = [
  ...DEFAULT_FACETS,
  ...FASHION_FACETS,
  ...FRAGRANCE_FACETS,
]

// Shoes sizes from 30 to 60
const SHOES_SIZES = Array.from({ length: 31 }, (_, i) => `${30 + i}`)
// Fragrance sizes from 10 ML to 200 ML
const FRAGRANCE_SIZES = Array.from(
  { length: 20 },
  (_, i) => `${10 * (i + 1)} ml`,
)
export const SIZE_ORDERING = [
  // 5 ML specific case
  '5 ml',
  ...FRAGRANCE_SIZES,
  'TU',
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  '3XL',
  '4XL',
  ...SHOES_SIZES,
]
