import classnames from 'classnames/bind'
import dynamic from 'next/dynamic'
import { useMemo } from 'react'
import { useTranslate } from 'react-polyglot'
import { ALERT_CODES } from '~/@types/alert'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGender } from '~/@types/gender'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import {
  MailingAddress,
  useCustomerContext,
  useDeleteCustomerAddress,
  useGetCustomerAddresses,
} from '@unlikelystudio/react-ecommerce-hooks'

import LoadingComponent from '~/components/Abstracts/LoadingComponent'
import { AccountDashboardProps } from '~/components/Account'
import { AddressFormProps } from '~/components/Account/AddressForm'
import SquaredCta from '~/components/UI/SquaredCta'

import { usePopIn } from '~/providers/PopInProvider'
import { useStyle } from '~/providers/StyleProvider'

import useAlerts from '~/hooks/useAlerts'

import joinTexts from '~/utils/join-texts'

import { ACCOUNT, GENDER } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const PopIn = dynamic(() => import('~/components/UI/Popin'), {
  loading: () => <LoadingComponent />,
})

const AddressForm = dynamic(() => import('~/components/Account/AddressForm'), {
  loading: () => <LoadingComponent />,
})

const computedText = (values: unknown[]) => joinTexts(values, ' ')

export interface AddressCardProps extends Partial<MailingAddress> {
  className?: string
  isDefault?: boolean
  gender?: GlobalGender
  addressName?: string
}

export interface AddressBookProps extends AccountDashboardProps {
  className?: string
  cardClassName?: string
}

function AddressCard({ className, ...address }: AddressCardProps) {
  const { customerAccessToken } = useCustomerContext()
  const {
    id,
    firstName,
    lastName,
    address1,
    address2,
    city,
    zip,
    country,
    phone,
    gender,
  } = address

  const t = useTranslate()
  const triggerAlert = useAlerts()
  const { add } = usePopIn()

  const { mutate } = useDeleteCustomerAddress({
    onSuccess: () => triggerAlert(ALERT_CODES.ENTITY_SAVED),
    onError: () => {
      triggerAlert(ALERT_CODES.SERVER_ERROR)
    },
  })

  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Text12_14Haffer,
    color: GlobalThemeColors.MineShaft,
  })

  const computedFullName = computedText([
    gender && t(GENDER[gender]),
    lastName,
    firstName,
  ])

  const computedCity = computedText([zip, city])

  const onDelete = () => mutate({ id, customerAccessToken })

  const handleAddressPopinAction = (props: AddressFormProps) =>
    add(
      <PopIn className={cx(css.popin)} closeClassName={css.closeIcon}>
        <AddressForm className={cx(css.addressForm)} {...props} />
      </PopIn>,
    )

  return (
    <div className={cx(css.AddressCard, className)}>
      <div className={css.textWrapper}>
        {computedFullName && (
          <p className={cx(css.fullName, textStyle)}>{computedFullName}</p>
        )}
        {address1 && <p className={cx(css.address, textStyle)}>{address1}</p>}
        {address2 && <p className={cx(css.address, textStyle)}>{address2}</p>}
        {computedCity && (
          <p className={cx(css.city, textStyle)}>{computedCity}</p>
        )}
        {country && <p className={cx(css.country, textStyle)}>{country}</p>}
        {phone && <p className={cx(css.phone, textStyle)}>{phone}</p>}
      </div>

      <div className={cx(css.buttons)}>
        <SquaredCta
          className={cx(css.button, css.edit)}
          theme={GlobalThemeColors.White}
          onClick={() => {
            handleAddressPopinAction({ update: true, address })
          }}>
          {t(ACCOUNT.EDIT)}
        </SquaredCta>
        <SquaredCta className={cx(css.button, css.delete)} onClick={onDelete}>
          {t(ACCOUNT.DELETE)}
        </SquaredCta>
      </div>
    </div>
  )
}

function AddressBook({ className, cardClassName }: AddressBookProps) {
  const t = useTranslate()
  const { add } = usePopIn()

  const { data, isLoading } = useGetCustomerAddresses()

  const headerStyle = useStyle({
    textPreset: GlobalTextPreset.Title14HafferSemiBold,
    color: GlobalThemeColors.MineShaft,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const { defaultAddress, addresses } = data ?? {}

  const filteredAddresses = useMemo(
    () =>
      addresses?.filter((address) => address?.id !== defaultAddress?.id) ?? [],
    [addresses, defaultAddress?.id],
  )

  const processedAddresses = useMemo(() => {
    return [
      ...(defaultAddress
        ? [
            {
              isDefault: true,
              ...defaultAddress,
            },
          ]
        : []),
      ...filteredAddresses,
    ]
  }, [defaultAddress, filteredAddresses])

  const hasAddresses = processedAddresses?.length > 0

  const showNewAdressPopIn = () =>
    add(
      <PopIn className={cx(css.popin)} closeClassName={css.closeIcon}>
        <AddressForm className={cx(css.addressForm)} />
      </PopIn>,
    )

  return (
    <div className={cx(css.AddressBook, className)}>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          {hasAddresses &&
            processedAddresses?.map((item, index) => (
              <AddressCard
                key={`${item?.name}_${index}`}
                className={cx(cardClassName)}
                {...item}
              />
            ))}
          {!hasAddresses && (
            <div className={cx(css.noAddresses)}>
              <p className={cx(css.noAddressesText, headerStyle)}>
                {t(ACCOUNT.NO_ADDRESSES)}
              </p>
            </div>
          )}
          <SquaredCta
            className={cx(css.button, css.add)}
            onClick={showNewAdressPopIn}>
            {t(ACCOUNT.ADD_ADDESSS)}
          </SquaredCta>
        </>
      )}
    </div>
  )
}

AddressBook.defaultProps = {}

export default AddressBook
