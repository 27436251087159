import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useLayoutEffect } from 'react'

import configObject from '~/components/UI/Zendesk/config'

import useShop from '~/hooks/useShop'
import useShowZendeskButton from '~/hooks/useShowZendeskButton'

import css from './styles.module.scss'

declare global {
  interface Window {
    Smooch: any
  }
}

const cx = classnames.bind(css)

const PATHS = [
  '/fragrance',
  '/fragrance/[uid]',
  '/fashion',
  '/fashion/[uid]',
  '/fragrance/product/[uid]',
  '/fashion/product/[uid]',
  '/contact',
]

export default function Zendesk() {
  const router = useRouter()
  const shop = useShop()
  const { isZendeskButtonShown, setIsZendeskButtonShown } =
    useShowZendeskButton()

  function loadScript(id, url, callback) {
    const head = document.head
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = url
    script.id = id

    //@ts-ignore
    script.onreadystatechange = callback
    script.onload = callback

    head.appendChild(script)
  }

  function loadScriptText(id, scriptText, callback) {
    const head = document.head
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.text = scriptText
    script.id = id
    //@ts-ignore
    script.onreadystatechange = callback
    script.onload = callback

    head.appendChild(script)
  }

  function loadCSSText(id, cssText) {
    const head = document.head
    const style = document.createElement('style')
    style.type = 'text/css'
    style.textContent = cssText
    //@ts-ignore
    style.text = cssText
    style.id = id

    head.appendChild(style)
  }

  function loadCSSTextiniFrame(id, cssText, iframeId) {
    //var head = document.getElementById(iframeId).contentWindow.document.head;
    const style = document.createElement('style')
    style.type = 'text/css'
    style.textContent = cssText
    // @ts-ignore
    style.text = cssText
    style.id = id

    console.log(style)

    document
      .getElementById(iframeId)
      // @ts-ignore
      .contentWindow.document.head.appendChild(style)
  }

  function updateChatInputField(id, disabled, placeholder) {
    document
      .getElementById(id)
      // @ts-ignore
      .contentWindow.document.querySelector(
        '#footer > form > div > textarea',
      ).placeholder = placeholder
  }

  const chatLoaded = function () {
    console.log('Chat Widget Script Loaded')
  }

  const loadChat = function () {
    let langCode = configObject.defaultLangCode
    const urlPath = window.location.pathname

    for (let i = 0; i < configObject.langCodes.length; i++) {
      if (
        configObject.langCodes[i] ===
        urlPath
          .split('/')
          .filter((item) => item !== '')[0]
          .split('-')[0]
      ) {
        langCode = configObject.langCodes[i]
        break
      }
    }

    console.log('Config lang', langCode)

    let conversationId = null
    let processedMessages = []

    const delegate = {
      beforeDisplay(message, data) {
        //suppress automated lanugage selection

        if (
          message.text == langCode ||
          message.text == 'Select language' ||
          message.text == 'router' ||
          message.text ==
            'Please fill in the on screen form or select an option.' ||
          message.text ==
            "I'm sorry, I can't answer that. But here are some topics I can help with."
        ) {
          return null
        }

        //set logo on all business messages
        if (message.role == 'business' && message.avatarUrl) {
          message.avatarUrl = configObject.avatarUrl
        }

        //translate form items
        if (message.type == 'form') {
          for (let i = 0; i < message.fields.length; i++) {
            const fieldName = message.fields[i].name.replaceAll('.', '')

            if (configObject.translations[langCode].formItems[fieldName]) {
              message.fields[i].label =
                configObject.translations[langCode].formItems[fieldName]
            }
          }
        }

        return message
      },
      beforeSend(message, data) {
        return message
      },
      beforePostbackSend(postback, data) {
        return postback
      },
    }

    window.Smooch.on('widget:opened', function () {
      const conversation = window.Smooch.getDisplayedConversation()

      if (conversation) {
        conversationId = conversation.id
      } else {
        window.Smooch.createConversation({
          displayName: configObject.translations[langCode].chatDisplayName,
          // messages: [
          //   {
          //     text: langCode,
          //     type: 'text',
          //   },
          // ],
        }).then((conversation) => {
          conversationId = conversation.id
        })
      }
    })

    window.Smooch.on('connected', function (data) {
      conversationId = data.conversation.id
    })

    window.Smooch.on('message:received', function (message, data) {
      //auto-answer language selections
      if (
        message.text == 'Select language' &&
        !processedMessages.includes(message.id)
      ) {
        console.log(`sending langCode:${langCode}`)
        processedMessages.push(message.id)
        window.Smooch.sendMessage(
          {
            type: 'text',
            text: langCode,
          },
          conversationId,
        )
      }
    })

    //Duncans changes
    window.Smooch.on('message:received', function (message, data) {
      //auto-answer language selections
      if (
        message.text ==
          "I'm sorry, I can't answer that. But here are some topics I can help with." &&
        !processedMessages.includes(message.id)
      ) {
        console.log(`resetting chat`)
        processedMessages.push(message.id)
        window.Smooch.sendMessage(
          {
            type: 'text',
            text: 'router',
          },
          conversationId,
        )
      }

      if (!processedMessages.includes(message.id)) {
        //disable text input if quick actions or a form is returned
        if (message.actions && message.actions.length > 0) {
          updateChatInputField(
            'web-messenger-container',
            false,
            configObject.translations[langCode].chatInputDefaultPlaceholder,
          )
        }

        processedMessages.push(message.id)
      }
    })

    //hide standard button if using custom button
    if (configObject.useCustomButton) {
      configObject.buttonHeight = '0px'
      configObject.buttonWidth = '0px'
    }

    console.log(configObject.buttonWidth)

    window.Smooch.init({
      integrationId: configObject.integrationId,
      appId: configObject.appId,
      locale: langCode,
      integrationOrder: [],
      conversationHistory: configObject.conversationHistory,
      customColors: configObject.color,
      businessIconUrl: configObject.avatarUrl,
      businessName: configObject.businessName,
      launcher: {
        text: '',
        showTextInMobile: false,
        shape: 'none',
        shapeSettings: null,
      },
      baseUrl: configObject.baseUrl,
      channelLinkingEnabled: false,
      attachmentsEnabled: configObject.attachmentsEnabled,
      proactiveMessagingEnabled: false,
      soundNotificationEnabled: false,
      positionOffset: configObject.positionOffset,
      canUserSeeConversationList: false,
      canUserCreateMoreConversations: false,
      customText: configObject.translations[langCode],
      buttonIconUrl: configObject.buttonIconUrl,
      buttonWidth: configObject.buttonWidth,
      buttonHeight: configObject.buttonHeight,
    }).then(function (w) {
      setIsZendeskButtonShown(false)
      window.Smooch.setDelegate(delegate)

      //hide powered by
      document
        .getElementById('web-messenger-container')
        //@ts-ignore
        .contentWindow.document.getElementsByClassName(
          'logo',
        )[0].style.display = 'none'

      //create custom button
      if (configObject.useCustomButton) {
        const button_id = `${configObject.customButtonIdPrefix}button`
        const button_icon_id = `${configObject.customButtonIdPrefix}icon`
        const button_label_id = `${configObject.customButtonIdPrefix}label`

        document
          .getElementById('web-messenger-container')
          //@ts-ignore
          .contentWindow.document.getElementById(
            'messenger-button',
          ).style.display = 'none'

        const button = document.createElement('button')
        button.name = button_id
        button.id = button_id

        const icon = document.createElement('span')
        icon.id = button_icon_id

        if (
          configObject.customButtonIconContents &&
          configObject.customButtonIconContents.length > 0
        ) {
          icon.innerHTML = configObject.customButtonIconContents
        }

        button.appendChild(icon)

        const label = document.createElement('span')
        label.innerHTML = configObject.translations[langCode].customButton
          ? configObject.translations[langCode].customButton
          : ''
        label.id = button_label_id
        button.appendChild(label)

        const body = document.getElementsByTagName('body')[0]
        body.appendChild(button)

        button.style.display = 'none'
        window.Smooch.open()

        button.addEventListener('click', function () {
          window.Smooch.open()
          //@ts-ignore
          document.querySelector('#' + button_id).style.display = 'none'
        })

        window.Smooch.on('widget:closed', function () {
          //@ts-ignore
          document.querySelector('#' + button_id).style.display = 'block'
        })
      }

      //set placeholder message
      updateChatInputField(
        'web-messenger-container',
        false,
        configObject.translations[langCode].chatInputDefaultPlaceholder,
      )
    })
  }

  function loadCustomCSS() {
    if (configObject.additionalCSS && configObject.additionalCSS.length > 0) {
      loadCSSText('chat-additional-css', configObject.additionalCSS)
    }
    if (configObject.iframeCSS && configObject.iframeCSS.length > 0) {
      console.log('injecting iframe CSS')
      setTimeout(function () {
        loadCSSTextiniFrame(
          'chat-additional-css-iframe',
          configObject.iframeCSS,
          'web-messenger-container',
        )
      }, 2000)
    }
  }

  function tryLoadChat() {
    if (window.Smooch) {
      loadChat()
      loadCustomCSS()
    } else {
      setTimeout(function () {
        tryLoadChat()
      }, 1000)
    }
  }

  useLayoutEffect(() => {
    if (!PATHS.includes(router.pathname)) {
      if (window?.Smooch?.close) window.Smooch.close()
      setIsZendeskButtonShown(false)
    } else {
      setIsZendeskButtonShown(true)
    }
  }, [router.pathname])

  useLayoutEffect(() => {
    if (window.Smooch) setIsZendeskButtonShown(false)
  }, [router])

  return (
    <div className={css.Zendesk}>
      {shop ? (
        <>
          <button
            name="support_zendesk"
            aria-label={'help'}
            className={cx(css.supportZendesk, {
              isVisible:
                isZendeskButtonShown && PATHS.includes(router.pathname),
            })}
            onClick={() => {
              loadScriptText(
                'ven-snippet',
                `!function (o, d, s, e, f) { var i, a, p, c = [], h = []; function t() { var t = "You must provide a supported major version."; try { if (!f) throw new Error(t); var e, n = "https://cdn.smooch.io/", r = "smooch"; e = "string" == typeof this.response ? JSON.parse(this.response) : this.response; var o = f.match(/([0-9]+)\.?([0-9]+)?\.?([0-9]+)?/), s = o && o[1], i = o && o[2], a = o && o[3], p = e["v" + s], c = e["v" + s + "." + i + ".patch"]; if (e.url || p || c) { var h = d.getElementsByTagName("script")[0], u = d.createElement("script"); if (u.async = !0, a) u.src = c || n + r + "." + f + ".min.js"; else { if (!(5 <= s && p)) throw new Error(t); u.src = p } h.parentNode.insertBefore(u, h) } } catch (e) { e.message === t && console.error(e) } } o[s] = { init: function () { i = arguments; var t = { then: function (e) { return h.push({ type: "t", next: e }), t }, catch: function (e) { return h.push({ type: "c", next: e }), t } }; return t }, on: function () { c.push(arguments) }, render: function () { a = arguments }, destroy: function () { p = arguments } }, o.__onWebMessengerHostReady__ = function (e) { if (delete o.__onWebMessengerHostReady__, o[s] = e, i) for (var t = e.init.apply(e, i), n = 0; n < h.length; n++) { var r = h[n]; t = "t" === r.type ? t.then(r.next) : t.catch(r.next) } a && e.render.apply(e, a), p && e.destroy.apply(e, p); for (n = 0; n < c.length; n++)e.on.apply(e, c[n]) }; var n = new XMLHttpRequest; n.addEventListener("load", t), n.open("GET", "https://" + e + ".webloader.smooch.io/", !0), n.responseType = "json", n.send() }(window, document, "Smooch", "${configObject.appId}", "5");`,
                null,
              )
              tryLoadChat()
            }}>
            <span className={css.supportIcon} />
          </button>
        </>
      ) : null}
    </div>
  )
}
