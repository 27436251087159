declare global {
  interface Window {
    _snwEventsBuffer?: any[]
  }
}

export default function swavenTrackingCallback(data) {
  const pushToDataLayer = (event: any) => {
    if (!window._snwEventsBuffer) window._snwEventsBuffer = []

    if (
      window._snwEventsBuffer &&
      window.dataLayer.findIndex(({ event }) => event === 'gtm.load') > -1
    ) {
      window._snwEventsBuffer.forEach((e) => {
        console.log('[Swaven Tracking]', e)
        window.dataLayer.push(e)
      })
      window._snwEventsBuffer = []
      console.log('[Swaven Tracking]', event)
      window.dataLayer.push(event)
    } else {
      if (window._snwEventsBuffer) {
        window?._snwEventsBuffer?.push(event)
      }
    }
  }

  const onGtmReady = () => {
    if (window._snwEventsBuffer && window._snwEventsBuffer.length > 0) {
      window._snwEventsBuffer.forEach((e) => {
        console.log('[Swaven Tracking]', e)
        window.dataLayer.push(e)
      })
    }

    window._snwEventsBuffer = []
  }

  window.addEventListener('gtmReady', onGtmReady)

  const SWAVEN_TRACKING_EVENTS = {
    OPEN: 'storeLocatorOpen',
    CLOSE: 'storeLocatorClose',
    ETAILER_CLICK_HOME: 'storeLocatorEtailerClickHome',
    ETAILER_CLICK: 'storeLocatorEtailerClick',
    PRODUCT_DETAIL: 'storeLocatorProductDetail',
    SEARCH_SUBMISSION: 'storeLocatorSearchSubmission',
    GET_DIRECTIONS: 'storeLocatorGetDirections',
    LOCATE_ME: 'storeLocatorLocateMe',
    MAP_MARKER: 'storeLocatorMapMarker',
    ITINERATOR: 'storeLocatorItinerator',
    VIEW_INFO: 'storeLocatorViewInfo',
  }

  const store = data?.store ?? {}
  const prd = data?.prd ?? {}

  const interaction = {
    storeLocator: {
      city: store?.scity,
      country: store?.country,
      storeId: store?.rid,
      storeName: store?.sname,
      zipCode: store?.szc,
    },
    swaven: {
      action: data.action,
      productId: prd.pid,
      productName: prd.pname,
      productPrice: store?.pr,
      productVariant: prd.pkg,
    },
  }

  if (data.action === 'dploy' && prd.pid !== undefined && prd.pid !== '') {
    pushToDataLayer({
      event: SWAVEN_TRACKING_EVENTS.OPEN,
      eventAction: 'open',
      eventCategory: 'etailers',
      eventLabel: undefined,
      interaction,
    })
  } else if (data.action === 'close') {
    pushToDataLayer({
      event: SWAVEN_TRACKING_EVENTS.CLOSE,
      eventAction: 'close',
      eventCategory: 'etailers',
      eventLabel: undefined,
      interaction,
    })
  } else if (
    data.action === 'buy' &&
    (data.surl === undefined || data.surl === '')
  ) {
    pushToDataLayer({
      event: SWAVEN_TRACKING_EVENTS.ETAILER_CLICK_HOME,
      eventAction: 'etailer click home',
      eventCategory: 'etailers',
      eventLabel: store?.sname,
      interaction,
    })
  } else if (
    data.action === 'buy' &&
    (data.surl !== undefined || data.surl !== '')
  ) {
    pushToDataLayer({
      event: SWAVEN_TRACKING_EVENTS.ETAILER_CLICK,
      eventAction: 'etailer click pdp',
      eventCategory: 'etailers',
      eventLabel: store?.sname,
      interaction,
    })
  } else if (data.action === 'clk') {
    pushToDataLayer({
      event: SWAVEN_TRACKING_EVENTS.PRODUCT_DETAIL,
      eventAction: 'product detail',
      eventCategory: 'etailers',
      eventLabel: prd.pname,
      interaction,
    })
  } else if (data.action === 'clkloc') {
    pushToDataLayer({
      event: SWAVEN_TRACKING_EVENTS.SEARCH_SUBMISSION,
      eventAction: 'location search',
      eventCategory: 'etailers',
      eventLabel: undefined,
      interaction,
    })
  } else if (data.action === 'clkRoute') {
    pushToDataLayer({
      event: SWAVEN_TRACKING_EVENTS.GET_DIRECTIONS,
      eventAction: 'get directions',
      eventCategory: 'etailers',
      eventLabel: undefined,
      interaction,
    })
  } /** STORE LOCATOR EVENTS */ else if (data.action === 'geoloc') {
    pushToDataLayer({
      event: SWAVEN_TRACKING_EVENTS.LOCATE_ME,
      eventAction: 'store locator',
      eventCategory: 'locate me',
      eventLabel: store?.sname,
      interaction,
    })
  } else if (data.action === 'buy') {
    pushToDataLayer({
      event: SWAVEN_TRACKING_EVENTS.VIEW_INFO,
      eventAction: 'etailer click pdp',
      eventCategory: 'etailers',
      eventLabel: store?.sname,
      interaction,
    })
  }
}
