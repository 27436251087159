import { useRouter } from 'next/router'
import linkResolver from '~/lib/link-resolver'
import { PRISMIC_TYPES } from '~/lib/prismic-types'

import { useIsMountedState } from '@unlikelystudio/react-hooks'

import LoadingPage from '~/components/Abstracts/LoadingPage'

import useAuthentication from '~/hooks/account/useAuthentication'
import useLocale from '~/hooks/useLocale'

export default function withPrivate(WrappedComponent) {
  const HOC = (pageProps) => {
    const { asPath } = useRouter()
    const locale = useLocale()
    const { isInitialLoading, isSuccess, data } = useAuthentication(
      `${linkResolver(
        { type: PRISMIC_TYPES.PRIVATE },
        locale,
      )}?redirectUrl=${asPath}`,
      null,
    )

    const isMounted = useIsMountedState()

    return (isInitialLoading && !isSuccess) || data === null || !isMounted ? (
      <LoadingPage />
    ) : (
      <WrappedComponent {...pageProps} />
    )
  }

  return (pageProps) =>
    pageProps?.privatePage ? HOC(pageProps) : WrappedComponent(pageProps)
}
