import classnames from 'classnames/bind'
import { useMemo } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import {
  Accordion,
  AccordionPanel,
  Spinner,
} from '@unlikelystudio/react-abstract-components'
import { useGetCustomerOrders } from '@unlikelystudio/react-ecommerce-hooks'

import PanelBody from '~/components/Account/OrdersGrid/PanelBody'
import PanelButtons from '~/components/Account/OrdersGrid/PanelButtons'
import PanelHeader from '~/components/Account/OrdersGrid/PanelHeader'
import PanelItem from '~/components/Account/OrdersGrid/PanelItem'
import { OrdersGridProps } from '~/components/Account/OrdersGrid/types'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useStyle } from '~/providers/StyleProvider'

import useLocale from '~/hooks/useLocale'

import { ACCOUNT, GENERAL } from '~/data/dictionary'
import serializeOrder from '~/data/serialize-order'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function OrdersGrid({ className }: OrdersGridProps) {
  const locale = useLocale()
  const t = useTranslate()
  const headerStyle = useStyle({
    textPreset: GlobalTextPreset.Title14HafferSemiBold,
    color: GlobalThemeColors.MineShaft,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const { global } = useGlobalData()

  const { data, isLoading } = useGetCustomerOrders({ ordersAmount: 20 })
  const items = useMemo(
    () =>
      data?.pages?.[0]?.map((order) =>
        serializeOrder({
          locale,
          order,
          parcelLabPages: global?.parcelLab ?? null,
        }),
      ) ?? [],
    [data],
  )

  const hasOrders = items?.length > 0

  return (
    <div className={cx(css.OrdersGrid, className)}>
      {isLoading ? (
        <Spinner className={css.spinner} />
      ) : hasOrders ? (
        <>
          <div className={cx(css.main, css.header, headerStyle)}>
            <p>{t(ACCOUNT.ORDER_NUMBER)}</p>
            <p>{t(ACCOUNT.DATE)}</p>
            <p>{t(ACCOUNT.TOTAL)}</p>
            <p>{t(ACCOUNT.DELIVERY)}</p>
            <p>{t(ACCOUNT.DETAILS)}</p>
          </div>

          <Accordion className={cx(css.accordion)}>
            {items?.map(
              (
                {
                  items,
                  returnsPage,
                  trackingPage,
                  reference,
                  status,
                  ...rest
                },
                index,
              ) => (
                <AccordionPanel
                  ariaLabels={{
                    open: t(GENERAL.ACCORDION_OPEN),
                    close: t(GENERAL.ACCORDION_CLOSE),
                  }}
                  key={`${rest.id}_${index}`}
                  index={index}
                  className={cx(css.panel, css.container)}
                  head={(isActive: boolean) => (
                    <PanelHeader
                      id={`order_${index}`}
                      isActive={isActive}
                      status={status}
                      reference={reference}
                      {...rest}
                    />
                  )}
                  body={
                    <PanelBody items={items}>
                      {(item, isButtonsVisible) => {
                        return (
                          <>
                            <PanelItem {...item} />
                            {isButtonsVisible && (
                              <PanelButtons
                                reference={reference}
                                status={status}
                                trackingPage={trackingPage}
                                returnsPage={returnsPage}
                              />
                            )}
                          </>
                        )
                      }}
                    </PanelBody>
                  }
                />
              ),
            )}
          </Accordion>
        </>
      ) : (
        <div className={cx(css.noOrders)}>
          <p className={cx(css.noOrdersText, headerStyle)}>
            {t(ACCOUNT.NO_ORDERS)}
          </p>
        </div>
      )}
    </div>
  )
}

OrdersGrid.defaultProps = {}

export default OrdersGrid
