import isMobile from 'ismobilejs'
import { useCallback, useRef } from 'react'

import {
  useIsomorphicLayoutEffect,
  useOnResize,
} from '@unlikelystudio/react-hooks'

export function useStableVh() {
  const isMobileRef = useRef(false)
  const body = useRef<HTMLElement>()

  useIsomorphicLayoutEffect(() => {
    body.current = document.body
  }, [])

  useIsomorphicLayoutEffect(() => {
    isMobileRef.current = isMobile(window.navigator).any
  }, [])

  const isFirstResize = useRef(true)

  const onResize = useCallback(({ height }) => {
    if (!isMobileRef.current || isFirstResize.current) {
      const vh = height * 0.01
      body.current.style.setProperty('--stable-vh', `${vh}px`)
    }
    isFirstResize.current = false
  }, [])

  useIsomorphicLayoutEffect(() => {
    const resetFirstResize = () => {
      if (isMobileRef.current) {
        isFirstResize.current = true
      }
    }
    window.addEventListener('orientationchange', resetFirstResize)

    return () => {
      window.removeEventListener('orientationchange', resetFirstResize)
    }
  })

  useOnResize(onResize)
}
