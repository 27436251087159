import countriesLocaleAttributions from '~/../config/countries-locale-attributions.json'
import multistore from '~/../config/multistore.json'

import { Option } from '~/components/Abstracts/Form/Select'

import getCountryNameFromCountryCode from '~/utils/get-country-name-from-country-code'
import getLanguageNameFromLanguageCode from '~/utils/get-language-name-from-language-code'

interface GetMarketCountriesValuesProps {
  locale: string
  hasAdditionalOptions?: boolean
  additionalOptions?: Option[]
}

/**
 * This function returns an array of country options with their corresponding country codes and
 * locales, sorted alphabetically, and with the option to add additional options.
 * @param {GetMarketCountriesValuesProps}  - - `locale`: a string representing the locale for which the
 * countries should be retrieved
 * @returns an array of `Option` objects, which contain a `label` and a `value` property. The `label`
 * property is a string representing the name of a country in a specific `locale`, and the `value`
 * property is an object containing the `countryCode` and an array of `locales`. If
 * `hasAdditionalOptions` is `true` and `additional
 */
export function getMarketCountriesValues({
  locale,
  hasAdditionalOptions = false,
  additionalOptions = [],
}: GetMarketCountriesValuesProps): Option[] {
  let countries =
    Object.entries(countriesLocaleAttributions)
      .map(([key, value]) => {
        const currentLocale = key
        const currentCountries = value

        const foundCountries = currentCountries?.map((country) => {
          return {
            label: getCountryNameFromCountryCode(locale, country),
            value: { countryCode: country, locales: [currentLocale] },
          }
        })

        return foundCountries
      })
      ?.flat()
      ?.sort((a, b) => {
        return a?.label.localeCompare(b?.label)
      }) ?? []

  if (hasAdditionalOptions && additionalOptions?.length > 0) {
    countries = countries.concat(additionalOptions)
  }

  return countries
}

/**
 * It takes an array of locales and returns an array of objects with a label and value property
 * @param {string[]} locales - string[] - an array of locales that you want to get the market
 * associations for.
 * @returns An array of objects with a label and value property.
 */
export function getMarketLangsAssociationsFromLocales(
  locales: string[],
): Option[] {
  const langs =
    locales
      ?.map((locale) => {
        const foundAttribution = multistore?.[locale] ?? null

        return {
          label: getLanguageNameFromLanguageCode(foundAttribution.language),
          value: locale,
        }
      })
      ?.sort((a, b) => {
        return a?.label.localeCompare(b?.label)
      }) ?? []

  return langs
}

/**
 * "Find the country attribution from the country code."
 *
 * The function takes two parameters:
 *
 * - countryCode: string
 * - countriesList: Option[]
 *
 * The function returns an Option
 * @param {string} countryCode - The country code of the country you want to find.
 * @param {Option[]} countriesList - Option[] - this is the list of countries that you get from the
 * API.
 * @returns The country attribution object that matches the country code.
 */
export function findCountryAttributionFromCountryCode(
  countryCode: string,
  countriesList: Option[],
): Option {
  const foundCountryAttribution =
    countriesList?.find((country) => {
      return country?.value?.countryCode === countryCode
    }) ?? null

  return foundCountryAttribution
}
