import React, { createContext, useContext, useMemo, useState } from 'react'

export const ClickOutsideContext = createContext<ValueType>({})

interface ClickOutsideProviderProps {
  children: JSX.Element | JSX.Element[]
}

export interface ValueType {
  canClickOutside?: boolean
  setCanClickOutside?: (value: boolean) => void
}

export default function ClickOutsideProvider({
  children,
}: ClickOutsideProviderProps) {
  const [canClickOutside, setCanClickOutside] = useState(true)

  const processedValue: ValueType = useMemo(
    () => ({
      canClickOutside,
      setCanClickOutside,
    }),
    [canClickOutside, setCanClickOutside],
  )

  return (
    <ClickOutsideContext.Provider value={processedValue}>
      {children}
    </ClickOutsideContext.Provider>
  )
}

export function useClickOutsideContext() {
  return useContext(ClickOutsideContext)
}
