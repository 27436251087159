import React, { createContext, useContext, useMemo } from 'react'
import {
  TRACKING_PRODUCT_COMPONENTS,
  TRACKING_PROMOTION_COMPONENTS,
} from '~/lib/constants'

interface PromotionComponentsProviderContext {
  promotionsSlicesNumber?: number
  productsSlicesNumber?: number
}

const Context = createContext<PromotionComponentsProviderContext>({})

export function usePromotionComponentsProvider() {
  return useContext(Context)
}

export interface PromotionComponentsProviderProps {
  children?: JSX.Element | JSX.Element[]
  slices?: any[]
}

function getPromotionSlicesCountFromSlices(slices: any) {
  let count = 0

  slices?.forEach((slice) => {
    const hasPromotion = TRACKING_PROMOTION_COMPONENTS.includes(slice?.type)

    if (hasPromotion) {
      count++
    }
  })

  return count
}

function getProductSlicesCountFromSlices(slices: any) {
  let count = 0

  slices?.forEach((slice) => {
    const hasProduct = TRACKING_PRODUCT_COMPONENTS.includes(slice?.type)

    if (hasProduct) {
      count++
    }
  })

  return count
}

function PromotionComponentsProvider({
  children,
  slices,
}: PromotionComponentsProviderProps) {
  const providerValue = useMemo(
    () => ({
      promotionsSlicesNumber: getPromotionSlicesCountFromSlices(slices) ?? 0,
      productsSlicesNumber: getProductSlicesCountFromSlices(slices) ?? 0,
    }),
    [slices],
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}

PromotionComponentsProvider.defaultProps = {}

export default PromotionComponentsProvider
