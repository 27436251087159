import React from 'react'

import PanelWrapper from '~/components/Panels/PanelWrapper'

import Panel, { MarketPanelProps } from './MarketPanel'

export default function MarketPanel(props: MarketPanelProps) {
  return (
    <PanelWrapper withoutCloseButton>
      <Panel {...props} />
    </PanelWrapper>
  )
}
