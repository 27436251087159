/**
 * The `pickEntriesFromObject` function in TypeScript allows you to extract specific key-value pairs
 * from an object based on a list of keys.
 * @param {T} object - The `object` parameter is the input object from which we want to pick specific
 * entries.
 * @param {K[]} keys - The `keys` parameter is a rest parameter that allows you to pass in multiple
 * keys of type `K` to pick from the `object`. The `K` type is a union of keys from the `T` object
 * type.
 * @returns a new object that contains only the specified keys from the original object. The returned
 * object has the same type as the original object, but with only the specified keys.
 */
export function pickSubsetFromObject<
  T extends Record<string, string>,
  K extends keyof T,
>(object: T, ...keys: K[]) {
  return Object.fromEntries(
    keys.filter((key) => key in object).map((key) => [key, object[key]]),
  ) as Pick<T, K>
}
