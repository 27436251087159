import { GlobalThemeColors } from '~/@types/colors'

export function squaredCtaNegativeTheme(
  theme: GlobalThemeColors,
): GlobalThemeColors {
  return theme === GlobalThemeColors.White ||
    theme === GlobalThemeColors.Transparent
    ? GlobalThemeColors.Black
    : GlobalThemeColors.White
}

export function inlineCtaNegativeTheme(
  theme: GlobalThemeColors,
): GlobalThemeColors {
  return theme === GlobalThemeColors.White
    ? GlobalThemeColors.Black
    : GlobalThemeColors.White
}
