import { useTranslate } from 'react-polyglot'
import { ALERT_CODES, AlertPreset } from '~/@types/alert'

import { useAlertContext } from '@unlikelystudio/react-abstract-components'

import { ALERT } from '~/data/dictionary'

export default function useAlerts() {
  const { triggerAlert } = useAlertContext()
  const t = useTranslate()

  const getPreset = (preset: AlertPreset) => {
    switch (preset) {
      case ALERT_CODES.ALERT_STOCK_SUCCESS:
      case ALERT_CODES.ENTITY_SAVED:
      case ALERT_CODES.EMAIL_SENT:
      case ALERT_CODES.NEWSLETTER_SUBSCRIBE:
      case ALERT_CODES.COOKIES_UPDATED:
        return {
          title: t(ALERT.SUCCESS_TITLE),
          message: t(ALERT?.[preset] ?? preset),
          type: 'success',
        }
      default:
        return {
          title: t(ALERT.ERROR_TITLE),
          message: t(ALERT?.[preset] ?? preset),
          type: 'error',
        }
    }
  }

  return (preset?: AlertPreset) => {
    triggerAlert(getPreset(preset))
  }
}
