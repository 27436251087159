import classnames from 'classnames/bind'
import React, { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

import {
  PopInHorizontalAlignement,
  PopInVerticalAlignement,
} from '~/providers/PopInProvider'

import useNewsletterPopIn from '~/hooks/useNewsletterPopIn'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface PopInNewsletterHandlerProps {
  className?: string
}

function PopInNewsletterHandler({ className }: PopInNewsletterHandlerProps) {
  const [showPopIn] = useNewsletterPopIn({
    withBackground: false,
    verticalAlignement: PopInVerticalAlignement.TOP,
    horizontalAlignement: PopInHorizontalAlignement.RIGHT,
  })

  const [inViewRef, inView] = useInView({ initialInView: true })

  useEffect(() => {
    if (!inView) showPopIn()
  }, [inView, showPopIn])

  return (
    <div
      ref={inViewRef}
      className={cx(css.PopInNewsletterHandler, className)}
    />
  )
}

PopInNewsletterHandler.defaultProps = {}

export default PopInNewsletterHandler
