import Cookies from 'js-cookie'
import { useCallback } from 'react'
import { COOKIE_KEYS } from '~/lib/constants'

import { useMarketContext } from '~/providers/MarketProvider'

export interface MarketCookie {
  countryCode: string
  locale: string
  marketName: string
}

export default function useMarketCookie() {
  const PANEL_DISPLAYED_KEY = COOKIE_KEYS.globalMarketPanelDisplayed
  const BANNER_DISPLAYED_KEY = COOKIE_KEYS.globalMarketBannerDisplayed
  const BANNER_USER_PREFERENCES = COOKIE_KEYS.globalMarketBannerUserPreferences

  const { setDisplayMarketPanel } = useMarketContext()

  const setMarketBannerUserPreferences = useCallback((data: MarketCookie) => {
    Cookies.set(BANNER_USER_PREFERENCES, JSON.stringify(data), { expires: 365 })
    setDisplayMarketPanel(false)
  }, [])

  const setMarketPanelDisplayed = useCallback((value: boolean) => {
    Cookies.set(PANEL_DISPLAYED_KEY, JSON.stringify(value), { expires: 365 })
  }, [])

  const getMarketBannerUserPreferences = useCallback(() => {
    return (
      Cookies.get(BANNER_USER_PREFERENCES) &&
      JSON.parse(Cookies.get(BANNER_USER_PREFERENCES))
    )
  }, [])

  const getMarketPanelDisplayed = useCallback(() => {
    return (
      (Cookies.get(PANEL_DISPLAYED_KEY) &&
        JSON.parse(Cookies.get(PANEL_DISPLAYED_KEY))) ??
      false
    )
  }, [])

  const setMarketBannerDisplayed = useCallback((value: boolean) => {
    Cookies.set(BANNER_DISPLAYED_KEY, JSON.stringify(value), { expires: 365 })
  }, [])

  const getMarketBannerDisplayed = useCallback(() => {
    return (
      (Cookies.get(BANNER_DISPLAYED_KEY) &&
        JSON.parse(Cookies.get(BANNER_DISPLAYED_KEY))) ??
      false
    )
  }, [])

  return {
    setMarketBannerUserPreferences,
    getMarketBannerUserPreferences,
    setMarketPanelDisplayed,
    getMarketPanelDisplayed,
    setMarketBannerDisplayed,
    getMarketBannerDisplayed,
  }
}
