import { useEffect } from 'react'
import { TRACKING_EVENTS } from '~/lib/constants'

import { useTracker } from '~/providers/TrackerProvider'

import useGTMTracking from '~/hooks/tracking/useGTMTracking'
import useOnRouteChange from '~/hooks/useOnRouteChange'

function Tracking() {
  const { tracker } = useTracker()

  useGTMTracking()

  useEffect(() => {
    tracker.emit(TRACKING_EVENTS.PAGE_VIEW)
  }, [])

  useOnRouteChange((url, { shallow }) => {
    if (!shallow) {
      tracker.emit(TRACKING_EVENTS.PAGE_VIEW)
    }
  })
  return null
}

export default Tracking
