import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { useIsMountedState } from '@unlikelystudio/react-hooks'

import useLocale from '~/hooks/useLocale'
import useMarketCookie, { MarketCookie } from '~/hooks/useMarketCookie'

interface MarketContextProps {
  displayMarketPanel?: boolean
  setDisplayMarketPanel?: (boolean) => void
  userPreferences?: MarketCookie
  displayMarketBanner?: boolean
  setDisplayMarketBanner?: (boolean) => void
  marketBannerHeight?: number
  setMarketBannerHeight?: (number) => void
  isLocaleSameAsUserPreferences?: boolean
  showBanner?: boolean
}

export const Context = createContext<MarketContextProps>({})

export function useMarketContext() {
  return useContext(Context)
}

export interface MarketProviderProps {
  children?: JSX.Element | JSX.Element[]
}

export default function MarketProvider({ children }: MarketProviderProps) {
  const {
    getMarketBannerUserPreferences,
    getMarketPanelDisplayed,
    getMarketBannerDisplayed,
  } = useMarketCookie()
  const mounted = useIsMountedState()
  const locale = useLocale()

  const cookieUserPreferences = getMarketBannerUserPreferences()
  const shouldShowMarketPanel = getMarketPanelDisplayed()
  const shouldHideMarketBanner = getMarketBannerDisplayed()

  const [userPreferences, setUserPreferences] = useState(
    cookieUserPreferences ?? null,
  )

  // Checking the cookie on mount
  useEffect(() => {
    if (mounted) {
      if (!shouldShowMarketPanel) {
        if (!userPreferences && cookieUserPreferences) {
          setUserPreferences(cookieUserPreferences)
        }
      }
    }
  }, [mounted, shouldShowMarketPanel, userPreferences, cookieUserPreferences])

  // Locale state of the banner
  const [displayMarketPanel, setDisplayMarketPanel] = useState(null)
  const [displayMarketBanner, setDisplayMarketBanner] = useState(null)
  const [marketBannerHeight, setMarketBannerHeight] = useState(0)
  const [isLocaleSameAsUserPreferences, setIsLocaleSameAsUserPreferences] =
    useState(null)

  // Need to display the banner
  const isSameLocale = locale === userPreferences?.locale

  useEffect(() => {
    setDisplayMarketPanel(shouldShowMarketPanel && !shouldHideMarketBanner)
    setDisplayMarketBanner(shouldHideMarketBanner)
  }, [shouldShowMarketPanel])

  useEffect(() => {
    setIsLocaleSameAsUserPreferences(isSameLocale)
  }, [isSameLocale])

  const providerValue = useMemo(
    () => ({
      showPanel: shouldShowMarketPanel,
      isLocaleSameAsUserPreferences,
      userPreferences,
      displayMarketPanel,
      setDisplayMarketPanel,
      marketBannerHeight,
      setMarketBannerHeight,
      displayMarketBanner,
      setDisplayMarketBanner,
    }),
    [
      displayMarketBanner,
      displayMarketPanel,
      marketBannerHeight,
      setMarketBannerHeight,
    ],
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}
