import classnames from 'classnames/bind'
import React from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { OrderElementItem } from '~/components/Account/OrdersGrid/types'
import Image from '~/components/UI/Image'

import { useStyle } from '~/providers/StyleProvider'

import { ACCOUNT } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 1 / 24 }, { ratio: 4 / 24 }]

interface PanelItemProps extends OrderElementItem {
  className?: string
}

function PanelItem({
  className,
  image,
  name,
  label,
  variants,
  quantity,
  price,
}: PanelItemProps) {
  const t = useTranslate()
  const nameStyle = useStyle({
    textPreset: GlobalTextPreset.Title12_14HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Haffer,
    color: GlobalThemeColors.DoveGray,
  })
  const metaStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Haffer,
    color: GlobalThemeColors.DoveGray,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const priceStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={cx(css.PanelItem, className)}>
      {image && (
        <Image
          className={cx(css.image)}
          ratio={css.ratio}
          sizesFromBreakpoints={IMAGE_SIZES}
          layout="fill"
          objectFit="cover"
          asPlaceholder
          src={image}
        />
      )}
      <div className={cx(css.content)}>
        <p className={cx(css.name, nameStyle)}>{name}</p>
        {label && <p className={cx(labelStyle)}>{label}</p>}
        <p className={cx(css.meta, metaStyle)}>
          {variants?.length > 0 &&
            variants?.map(({ name, value }) => (
              <span className={css.metaWrapper} key={`${name}_${value}`}>
                {name}: <span className={css.metaValue}>{value}</span>
              </span>
            ))}
          <span className={css.metaWrapper}>
            {t(ACCOUNT.QUANTITY)} :{' '}
            <span className={css.metaValue}>{quantity}</span>
          </span>
        </p>
      </div>
      <span className={cx(css.price, priceStyle)}>{price}</span>
    </div>
  )
}

export default PanelItem
