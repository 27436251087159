import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import AbstractErrorList, {
  ErrorListProps as AbstractErrorListProps,
} from '~/components/Abstracts/Form/ErrorList'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ErrorListProps extends AbstractErrorListProps {
  className?: string
}

function ErrorList({ className, ...props }: ErrorListProps) {
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Label10_12Haffer,
    color: GlobalThemeColors.Pomegranate,
  })

  return (
    <AbstractErrorList
      className={cx(css.ErrorList, textStyle, className)}
      {...props}
    />
  )
}

ErrorList.defaultProps = {}

export default ErrorList
