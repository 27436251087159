import classnames from 'classnames/bind'
import React, { InputHTMLAttributes, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import objectFilter from '~/utils/filter-object'

import css from './styles.module.scss'

const cx = classnames.bind(css)
export interface InputProps
  extends Omit<InputHTMLAttributes<any>, 'onError' | 'children'> {
  className?: string
  textarea?: boolean
  rows?: number
  forwardRef?: any
  withError?: boolean
  errorClassname?: string
  addRequiredIndicatorOnLabel?: boolean
  validate?: Record<string, (val?: unknown) => boolean>
  isRegistered?: boolean
  onError?: (node) => void
  children?:
    | JSX.Element
    | JSX.Element[]
    | ((params: any) => JSX.Element | JSX.Element[])
}

function Input({
  className,
  forwardRef,
  type,
  textarea,
  rows,
  withError,
  errorClassname,
  name,
  required,
  placeholder,
  addRequiredIndicatorOnLabel,
  validate,
  min,
  max,
  minLength,
  maxLength,
  defaultValue,
  defaultChecked,
  onBlur,
  onError,
  children,
  ...inputProps
}: InputProps) {
  const { register, formState } = useFormContext() || {}

  const error = formState?.errors?.[name] ?? null
  const placeHolder = placeholder
    ? placeholder + (addRequiredIndicatorOnLabel && required ? ' *' : '')
    : null

  const validations = {
    required,
    min,
    max,
    minLength,
    maxLength,
    defaultValue,
    defaultChecked,
    validate,
  }

  const filteredValidations = objectFilter(
    validations,
    ([, val]) => val !== undefined,
  )

  const cssError =
    (withError || error?.type || Object.keys(error?.types ?? {})?.length > 0) &&
    errorClassname
      ? errorClassname
      : null

  useEffect(() => {
    onError?.(error)
  }, [cssError])

  const textareaComponent = (
    <textarea
      className={cx(css.textarea, className, cssError)}
      rows={rows}
      name={name}
      placeholder={placeHolder}
      ref={forwardRef}
      {...(error?.type ? { 'aria-describedby': 'req' } : {})}
      {...inputProps}
      {...(name && register?.(name, { ...filteredValidations, onBlur }))}
    />
  )

  const inputComponent = (
    <input
      type={type}
      name={name}
      maxLength={maxLength}
      defaultChecked={defaultChecked}
      defaultValue={defaultValue}
      ref={forwardRef}
      placeholder={placeHolder}
      className={cx(css.input, className, cssError)}
      {...(error?.type ? { 'aria-describedby': 'req' } : {})}
      {...inputProps}
      {...(name && register?.(name, { ...filteredValidations, onBlur }))}
    />
  )

  return textarea ? textareaComponent : inputComponent
}

Input.defaultProps = {
  addRequiredIndicatorOnLabel: true,
}

export default Input
