import { useMutation, UseMutationOptions } from '@tanstack/react-query'
import { ErrorOption } from 'react-hook-form'
import { TRACKING_EVENTS } from '~/lib/constants'
import {
  requestNextApiEndpoint,
  SHOPIFY_NEWSLETTER_PARAMS,
} from '~/lib/handle-api'
import { getTagsFromCurrencyFromAndCountry } from '~/lib/shopify-tags'

import { useGeolocation } from '~/providers/GeolocationProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useCurrency from '~/hooks/useCurrency'
import useLanguage from '~/hooks/useLanguage'
import useLocale from '~/hooks/useLocale'
import { setCookiesAttempts } from '~/hooks/useNewsletterPopIn'
import useOnMutationError from '~/hooks/useOnMutationError'

export default function useShopifyNewsletterMutation(
  mutationOptions?: UseMutationOptions<any, any, any>,
  setError?: (name?: string, error?: ErrorOption) => void,
) {
  const onMutationError = useOnMutationError(setError)
  const lang = useLanguage()
  const currency = useCurrency()
  const locale = useLocale()
  const { countryISO } = useGeolocation()
  const { tracker } = useTracker()

  return useMutation<any, any, any>(
    async ({
      email,
      from,
      campaign,
      firstName,
      lastName,
      acceptsMarketing = true,
    }) => {
      return await requestNextApiEndpoint(SHOPIFY_NEWSLETTER_PARAMS, {
        payload: {
          firstName: firstName ? firstName : null,
          lastName: lastName ? lastName : null,
          email,
          lang,
          tags: getTagsFromCurrencyFromAndCountry({
            currency,
            from,
            country: countryISO,
            campaign,
            language: lang,
          }),
          acceptsMarketing,
        },
      })
    },
    {
      ...mutationOptions,
      onSuccess: (data, ...rest) => {
        tracker.emit(TRACKING_EVENTS.TAGGED_EVENT, {
          eventName: 'subscriptionSuccess',
          eventAction: 'newsletter',
          eventCategory: 'form',
          eventLabel: 'ok',
        })

        setCookiesAttempts(locale)
        mutationOptions?.onSuccess(data, ...rest)
      },
      onError: (err, ...rest) => {
        console.log(err)
        onMutationError(err, rest, mutationOptions?.onError)
      },
    },
  )
}
