import Head from 'next/head'
import React from 'react'

import { ImageProps } from '~/components/UI/Image'

import { isDefaultLocale } from '~/utils/locales'

export interface Language {
  id: string
  uid: string
  lang: string
  url: string
  type?: string
  locale: string
}

export interface MetaProps extends Language {
  langSwitcher: Language[]
  alternateLanguages: Language[]
  title?: string
  description?: string
  image?: ImageProps
  asDuplicatedAlternateDefaultLang?: boolean
  isRootPage?: boolean
}

function generateAlternateMeta(language: Language) {
  return (
    <link
      rel="alternate"
      href={language?.url}
      hrefLang={language?.lang}
      key={`alternate-${language?.lang}`}
    />
  )
}

function Meta(meta: MetaProps) {
  const { title, description, image, url, alternateLanguages } = meta

  const metaTitle = title ? `${title}` : ''
  const metaDesc = description ?? null
  const metaImage = image ?? null
  const metasUrl = url ?? null

  // Get default alternate from locale
  const xDefault = alternateLanguages?.find((item) =>
    isDefaultLocale(item.locale),
  )

  return meta ? (
    <Head>
      <meta charSet="utf-8" />
      <title>{`${metaTitle}`}</title>
      {metaDesc && <meta name="description" content={metaDesc} />}
      <meta property="og:type" content="website"></meta>
      <meta property="og:title" content={`${metaTitle}`} key="title" />
      {metaDesc && <meta property="og:description" content={metaDesc} />}
      {metaImage && <meta property="og:image" content={metaImage?.src} />}

      {alternateLanguages
        ?.filter(
          (item) =>
            meta.asDuplicatedAlternateDefaultLang ??
            !isDefaultLocale(item.locale),
        )
        ?.map((language) => generateAlternateMeta(language))}

      {/* Genereate x-default alternate */}
      {generateAlternateMeta({
        ...xDefault,
        lang: 'x-default',
      })}

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={metasUrl} />
      <meta property="twitter:title" content={`${metaTitle}`} />
      {metaDesc && <meta property="twitter:description" content={metaDesc} />}
      {metaImage && <meta property="twitter:image" content={metaImage?.src} />}
      {metasUrl && (
        <link rel="canonical" href={metasUrl} key={`canonical-${metasUrl}`} />
      )}
    </Head>
  ) : null
}

export default Meta
