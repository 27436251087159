import qs from 'qs'

import { LoaderParams } from '~/components/Abstracts/Image/loader'

export const HOSTNAME = 'images.prismic.io'

const PRISMIC_BASED_PARAMS = {
  q: 60,
  auto: ['compress', 'format'],
  fit: 'max',
}

export const imageLoaderPrismic = ({ src, ...rest }: LoaderParams) => {
  const stringified = qs.stringify(
    {
      ...PRISMIC_BASED_PARAMS,
      ...(rest ? rest : {}),
    },
    { skipNulls: true, arrayFormat: 'comma', encode: false },
  )

  return `${src}?${stringified}`
}
