import classnames from 'classnames/bind'
import React from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { ArrowBottomIcon } from '~/components/Abstracts/Icons'
import { OrderElement } from '~/components/Account/OrdersGrid/types'

import { useStyle } from '~/providers/StyleProvider'

import { ACCOUNT } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface PanelHeaderProps extends Omit<OrderElement, 'items'> {
  className?: string
  isActive?: boolean
}

function PanelHeader({
  className,
  reference,
  id,
  date,
  total,
  status,
  isActive,
}: PanelHeaderProps) {
  const t = useTranslate()
  const headerStyle = useStyle({
    textPreset: GlobalTextPreset.Label12Haffer,
    color: GlobalThemeColors.DoveGray,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12HafferSemiBold,
    color: GlobalThemeColors.DoveGray,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={cx(css.PanelHeader, className, headerStyle)}>
      <p>
        <span className={labelStyle}>{t(ACCOUNT.ORDER_NUMBER)}</span>
        <span>{reference}</span>
      </p>
      <p>
        <span className={labelStyle}>{t(ACCOUNT.DATE)}</span>
        <span>{date}</span>
      </p>
      <p>
        <span className={labelStyle}>{t(ACCOUNT.TOTAL)}</span>
        <span>{total}</span>
      </p>
      <p>
        <span className={labelStyle}>{t(ACCOUNT.DELIVERY)}</span>
        <span>{status}</span>
      </p>
      <p>
        <span className={cx(labelStyle, css.orderToggler)}>
          {isActive ? t(ACCOUNT.CLOSE) : t(ACCOUNT.VIEW)} {t(ACCOUNT.DETAILS)}
          <ArrowBottomIcon
            className={cx(css.arrowBottom, {
              isActive,
            })}
          />
        </span>
      </p>
    </div>
  )
}

export default PanelHeader
