import classnames from 'classnames/bind'
import { useCallback } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { FooterLinksProps } from '~/@types/footer'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { AccordionPanel } from '@unlikelystudio/react-abstract-components'

import { MinusIcon, PlusIcon } from '~/components/Abstracts/Icons'
import {
  trackInternalFooterLink,
  trackSocialFooterLink,
} from '~/components/UI/Footer/utils'
import Image from '~/components/UI/Image'
import InlineCta from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ ratio: 0.5 / 24 }]

function Title({ title }: Pick<FooterLinksProps, 'title'>) {
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title12HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  return title ? <div className={cx(css.title, titleStyle)}>{title}</div> : null
}
interface LinksProps {
  links?: FooterLinksProps['links']
  index?: FooterLinksProps['index']
}

function Links({ links, index }: LinksProps) {
  const { tracker } = useTracker()

  const onClickCallback = useCallback(
    (children, link, isSocialLink, socialName) => {
      if (isSocialLink && socialName) {
        trackSocialFooterLink(tracker, link?.href ?? '', socialName)
      } else {
        trackInternalFooterLink(tracker, children)
      }
    },
    [],
  )

  const t = useTranslate()

  return links?.length > 0 ? (
    <div className={cx(css.links)}>
      {links?.map(
        ({ icon, children, isSocialLink, socialName, ...link }, index) => (
          <div key={`${link.href}_${index}`}>
            <InlineCta
              {...link}
              className={css.inlineLink}
              textPreset={GlobalTextPreset.Cta12Haffer}
              theme={GlobalThemeColors.DoveGray}
              lineProps={{ initialLineState: 'none' }}
              onClick={() => {
                onClickCallback(children, link, isSocialLink, socialName)
              }}>
              <span className={cx(css.link)}>
                {icon && (
                  <Image
                    className={cx(css.icon)}
                    ratio={css.ratio}
                    sizesFromBreakpoints={IMAGE_SIZES}
                    layout="fill"
                    objectFit="cover"
                    {...icon}
                  />
                )}
                {children}
              </span>
            </InlineCta>
          </div>
        ),
      )}
      {index === 1 && (
        <div>
          <InlineCta
            className={css.inlineLink}
            textPreset={GlobalTextPreset.Cta12Haffer}
            theme={GlobalThemeColors.DoveGray}
            lineProps={{ initialLineState: 'none' }}
            onClick={() => {
              window?.Optanon?.ToggleInfoDisplay?.()
            }}>
            {t(GENERAL.COOKIE_EDIT_CONSENT)}
          </InlineCta>
        </div>
      )}
    </div>
  ) : null
}

function FooterLinks({
  className,
  title,
  links,
  index,
  isAccordion,
}: FooterLinksProps) {
  const t = useTranslate()

  return isAccordion ? (
    <AccordionPanel
      ariaLabels={{
        open: t(GENERAL.ACCORDION_OPEN),
        close: t(GENERAL.ACCORDION_CLOSE),
      }}
      className={cx(css.FooterLinks, className)}
      index={index}
      head={(isActive: boolean) => {
        return (
          <div
            className={cx(css.panelHead, {
              isActive,
            })}>
            <Title title={title} />

            {isActive ? (
              <MinusIcon className={css.icon} fill="#000000" />
            ) : (
              <PlusIcon className={css.icon} fill="#000000" />
            )}
          </div>
        )
      }}
      body={<Links links={links} index={index} />}
    />
  ) : (
    <div className={cx(css.FooterLinks, className)}>
      <Title title={title} />
      <Links links={links} index={index} />
    </div>
  )
}

FooterLinks.defaultProps = {
  isAccordion: false,
}

export default FooterLinks
