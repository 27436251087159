import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'

import { OrderElement } from '~/components/Account/OrdersGrid/types'
import SquaredCta from '~/components/UI/SquaredCta'

import { ACCOUNT } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface PanelButtonsProps
  extends Pick<
    OrderElement,
    'returnsPage' | 'trackingPage' | 'reference' | 'status'
  > {
  className?: string
}

function PanelButtons({
  className,
  status,
  reference,
  returnsPage,
  trackingPage,
}: PanelButtonsProps) {
  const t = useTranslate()
  const router = useRouter()

  const hasButtons = returnsPage || trackingPage

  const onClickTracking = () => {
    router.push({
      pathname: trackingPage,
      query: {
        orderNo: reference,
      },
    })
  }

  return status === 'FULFILLED' && hasButtons ? (
    <div className={cx(css.PanelButtons, className)}>
      {returnsPage && (
        <SquaredCta
          className={cx(css.button)}
          theme={GlobalThemeColors.White}
          href={returnsPage}>
          {t(ACCOUNT.RETURN_REPLACE)}
        </SquaredCta>
      )}
      {trackingPage && reference && (
        <SquaredCta className={cx(css.button)} onClick={onClickTracking}>
          {t(ACCOUNT.TRACK_PACKAGE)}
        </SquaredCta>
      )}
    </div>
  ) : null
}

export default PanelButtons
