import { PRISMIC_SLICES, TPrismicTypesKey } from '~/lib/prismic-types'

import multistore from 'config/multistore.json'

import { UnlikelyPageInfo } from '@unlikelystudio/commerce-connector'

import { uniq } from '~/utils/uniq'
import { isVercelProduction } from '~/utils/vercel-env'

export interface SingleRouteParams {
  type: TPrismicTypesKey
  href: string
}

// usd, eur...
export const CURRENCIES =
  uniq(Object.values(multistore).map((entry) => entry.currency)) ?? []

// usd, eur...
export const LOCALES = Object.keys(multistore) ?? []

// markets
export const MARKETS =
  uniq(Object.values(multistore).map((entry) => entry.market)) ?? []

// cms langs
export const CMS_LANGS =
  uniq(Object.values(multistore).map((entry) => entry.cms_lang)) ?? []

export const SHOPIFY_LANGS =
  uniq(Object.values(multistore).map((entry) => entry.language)) ?? []

export const COOKIE_KEYS = {
  recentlyViewed: 'recently-viewed-cookie',
  checkoutId: 'shopify-checkout-id',
  customerAccessToken: 'customer-access-token',
  globalGeolocationPopIn: 'global-geolocation-pop-in',
  checkoutGeolocationPopIn: 'checkout-geolocation-pop-in',
  globalMarketBannerUserPreferences: 'global-market-banner-user-preferences',
  globalMarketBannerDisplayed: 'global-market-banner-displayed',
  globalMarketPanelDisplayed: 'global-market-panel-displayed',
  userCountryCode: 'user-country-code',
  newsletterPopIn: (locale) => `newsletter-pop-in_${locale}`,
  newsletterPopInAttempts: (locale) => `newsletter-pop-in_attempts_${locale}`,
}

export const SHOPIFY_CHECKOUT_CUSTOM_ATTRIBUTES = {
  checkoutLang: 'checkout_lang',
  params: 'params',
  giftsIds: 'gifts_ids',
  packagingFragrance: 'packaging_fragrance',
  packagingFashion: 'packaging_fashion',
  orderType: 'order_type',
  productTrackingData: '_product_tracking_data',
}

export const SHOPIFY_CUSTOM_ATTRIBUTES = {
  name: '_name',
  setsName: '_setsName',
  label: '_label',
  slug: '_slug',
  image: '_image',
  setsImage: '_setsImage',
  productType: '_productType',
  shopifyId: '_shopifyId',
  category: '_category',
  variant: '_variant',
  productVariantId: '_productVariantId',
  unserializedPrice: '_unserializedPrice',
  realProduct: '_realProduct',
  pouch: '_pouch',
  gift: '_gift',
  giftPrice: '_giftPrice',
  sample: '_sample',
  sampleFromProduct: '_sampleFromProduct',
  productLinkedId: '_productLinkedId',
  prismicType: '_prismicType',
  trackingData: '_trackingData',
  sets: '_sets',
  customPrice: '_customPrice',
  trackingPayload: '_trackingPayload',
  _color: '_color',
  _colorHex: '_colorHex',
}

export enum TRACKING_EVENTS {
  PAGE_VIEW = 'page_view',
  CONTACT_THANK_YOU_PAGE_VIEW = 'contact_thank_you_page_view',
  TAGGED_EVENT = 'event_tagged',
  INTERACTION_TAGGED_EVENT = 'interaction_event_tagged',
  LOGOUT = 'logout',
  LOGIN_SUCCESS = 'login_success',
  LOGIN_ERROR = 'login_error',
  SIGN_UP_SUCCESS = 'sign_up_success',
  SIGN_UP_ERROR = 'sign_up_error',
  OPEN_CART = 'open_cart',
  GO_TO_CHECKOUT = 'go_to_checkout',
  PRODUCT_IMPRESSION = 'product_impression',
  PRODUCT_CLICK = 'product_click',
  PRODUCT_DETAIL = 'product_detail',
  ADD_TO_CART = 'add_to_cart',
  ADD_SAMPLES = 'add_samples',
  CHOOSE_SAMPLES = 'choose_samples',
  REMOVE_FROM_CART = 'remove_from_cart',
  PROMOTION_IMPRESSION = 'promotion_impression',
  PROMOTION_CLICK = 'promotion_click',
  MENU_HEADER_LINKS = 'menu_header_links',
  FILTERS_SORT = 'filters_sort',
  FILTERS_FILTER = 'filters_filter',
}

export const TRACKING_PROMOTION_COMPONENTS: string[] = [
  PRISMIC_SLICES.ANNOUNCEMENT,
  PRISMIC_SLICES.BIG_IMAGE_EDITO,
  PRISMIC_SLICES.CATEGORY_GRID_EDITO,
  PRISMIC_SLICES.COLUMN_EDITO,
  PRISMIC_SLICES.COVER_IMAGE,
  PRISMIC_SLICES.EDITO_COLUMN,
  PRISMIC_SLICES.EDITO_DYPTIC_IMAGE,
  PRISMIC_SLICES.EDITO_IMAGE_TITLE_TEXT,
  PRISMIC_SLICES.EDITO_IMAGE,
  PRISMIC_SLICES.EDITO_PUSH_IMAGE_TEXT,
  PRISMIC_SLICES.EDITO_TITLE_IMAGE_TEXT,
  PRISMIC_SLICES.GRID_LOOKBOOK_COVER_IMAGE,
  PRISMIC_SLICES.GRID_LOOKBOOK_IMAGES,
  PRISMIC_SLICES.GRID_LOOKBOOK_LINKS,
  PRISMIC_SLICES.GRID_LOOKBOOK_PUSH_IMAGE,
  PRISMIC_SLICES.GRID_LOOKBOOK_SINGLE_IMAGE,
  PRISMIC_SLICES.GRID_LOOKBOOK_SLIDER_MULTIPLE_LAYOUT,
  PRISMIC_SLICES.HEADER_STORY,
  PRISMIC_SLICES.HERO_MAIN,
  PRISMIC_SLICES.IMAGE_EDITO_COLUMN,
  PRISMIC_SLICES.IMAGE_TITLE,
  PRISMIC_SLICES.MASONRY_IMAGE_EDITO,
  PRISMIC_SLICES.MASONRY_IMAGE,
  PRISMIC_SLICES.PRODUCT_HEADER_EDITO_IMAGE,
  PRISMIC_SLICES.PRODUCT_HEADER_EDITO_TITLE_TEXT,
  PRISMIC_SLICES.PRODUCT_HEADER_EDITO_VIDEO,
  PRISMIC_SLICES.PUSH_COLLECTION,
  PRISMIC_SLICES.PUSH_COVER,
  PRISMIC_SLICES.PUSH_IMAGE_COVER,
  PRISMIC_SLICES.PUSH_IMAGE_TEXT,
  PRISMIC_SLICES.PUSH_IMAGE,
  PRISMIC_SLICES.PUSH_PRODUCT,
  PRISMIC_SLICES.PUSH_PRODUCTS_SLIDER,
  PRISMIC_SLICES.STORIES_GRID_IMAGE,
  PRISMIC_SLICES.STORIES_GRID2_IMAGES,
  PRISMIC_SLICES.STORIES_GRID3_IMAGES,
  PRISMIC_SLICES.STORIES_SLIDER,
  PRISMIC_SLICES.TESTIMONIAL,
  PRISMIC_SLICES.VIDEO_PLAYER,
  PRISMIC_SLICES.WYSIWYG,
]

export const TRACKING_PRODUCT_COMPONENTS: string[] = [
  PRISMIC_SLICES.PRODUCTS_GRID,
  PRISMIC_SLICES.PRODUCTS_GRID_EDITO,
  PRISMIC_SLICES.PRODUCTS_SLIDER,
  PRISMIC_SLICES.PUSH_PRODUCTS_IMAGES,
]

export const contactEmailSubject = 'Demande de renseignements'

export const FORBIDDEN_COUNTRIES_NEWSLETTER = ['RU']

export const PRISMIC_PRODUCTS_INTEGRATION_FIELD_KEY = 'shopify_products'
export const PRISMIC_COLLECTIONS_INTEGRATION_FIELD_KEY = 'shopify_collections'

export const UPSTASH_PREFIX = 'dynamic_redirects:'

export const DEFAULT_LOCALE = 'en-ww'
export const DEFAULT_INTL = 'en-US'
export const DEFAULT_CMS_LANG = 'en'
export const DEFAULT_LANGUAGE = 'en'

export const DEFAULT_CURRENCY = 'eur'
export const DEFAULT_MARKET = 'EU'
export const DEFAULT_COUNTRY_CODE = 'US'

export const SHOPIFY_ADMIN_API_VERSION = '2023-01'
export const SHOPIFY_API_VERSION = '2021-07'
export const RECAPTCHA_PUBLIC_API_KEY =
  '6LcBam0eAAAAACV3o-Lns_eTDMWWOZB1qZPYGwxB'

export const DEFAULT_REST_OF_THE_WORLD_LOCALES = ['en-ww', 'es-ww']

export const MAX_PRODUCT_SAME_ID = 5

export const PRISMIC_SHOPIFY_PRODUCT_KEY = 'shopify_product'
export const PRISMIC_SHOPIFY_COLLECTION_KEY = 'shopify_collection'

export const TRACKING_BRAND = 'nr'

export const NEXT_PUBLIC_SWAVEN = '61f913845874650b7d71d6bc'

export const UPSTASH_HOST = 'https://eu1-valued-horse-34586.upstash.io'

export const PARCEL_LAB_ID = `1618468`
export const PARCEL_LAB_BASE_URL = isVercelProduction
  ? 'https://returns.parcellab.com'
  : 'https://staging-returns.parcellab.com'

export const QUERY_KEYS = {
  METAFIELDS: 'getCustomerMetafields',
  ORDER_ADMIN: 'getOrderBillingAddress',
}

export const DEFAULT_PAGE_INFO: UnlikelyPageInfo = {
  endCursor: null,
  startCursor: null,
  hasNextPage: false,
  hasPreviousPage: false,
}

export const PERSISTENT_DOMAIN_TAGS = ['campaign', 'from']
export const ALLOWED_TAGS = [
  'campaign',
  'from',
  'from_modified',
  'currency',
  'country',
  'language',
]

export const SIZE_GUIDE_TYPES = {
  CHEST: 'chest',
  SHOULDERS: 'shoulders',
  WAIST: 'waist',
  HIPS: 'hips',
  RING: 'ring',
} as const

export const NEXT_LOCALE_COOKIE = 'NEXT_LOCALE_V5'

export const MAXIMUM_CATEGORY_SLICES_NUMBER = 30
