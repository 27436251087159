import classnames from 'classnames/bind'
import React from 'react'
import { GlobalGridPreset } from '~/@types/grid-preset'

import LinkListNavigation, {
  LinkListNavigationProps,
} from '~/components/Navigation/LinkListNavigation'
import SelectNavigation from '~/components/Navigation/SelectNavigation'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface DashboardNavigationProps {
  className?: string
  navigation: LinkListNavigationProps
  children?: JSX.Element | JSX.Element[]
}

function DashboardNavigation({
  className,
  navigation,
  children,
}: DashboardNavigationProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  return navigation ? (
    <div className={cx(css.DashboardNavigationAccordion, className, gridStyle)}>
      <LinkListNavigation
        className={cx(css.navigation, css.hideOnSmallScreen)}
        {...navigation}
      />

      <SelectNavigation
        className={cx(css.navigation, css.hideOnLargeScreen)}
        links={navigation?.links}
      />

      {children}
    </div>
  ) : null
}

DashboardNavigation.defaultProps = {}

export default DashboardNavigation
