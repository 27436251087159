import classnames from 'classnames/bind'
import React from 'react'

import { SelectProps } from '~/components/Abstracts/Form/Select'
import { ArrowSelectIcon } from '~/components/Icons'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function MockSelect({
  className,
  selectClassName,
  optionClassName,
  iconClassName,
  iconColor,
  icon,
  currentValue,
  options,
  placeholder,
  autoFocus,
  prefixLabel,
  name,
  required,
  addRequiredIndicatorOnLabel,
  forwardRef,
  ...selectProps
}: SelectProps) {
  const placeHolder = placeholder
    ? placeholder + (addRequiredIndicatorOnLabel && required ? ' *' : '')
    : null
  const Icon = icon ?? ArrowSelectIcon

  return (
    <div className={cx(className, css.Select)}>
      <select
        {...(forwardRef && { ref: forwardRef })}
        className={cx(css.select, selectClassName)}
        autoFocus={autoFocus}
        name={name}
        {...(currentValue && { value: currentValue })}
        {...selectProps}
      >
        {placeHolder && (
          <option className={cx(css.option, optionClassName)} value="" disabled>
            {`${prefixLabel ? `${prefixLabel} ` : ''}${placeHolder}`}
          </option>
        )}
        {options?.map(({ value, label, ...rest }, index) => {
          return (
            <option
              className={cx(css.option, optionClassName)}
              key={`${value}-${index}`}
              label={label}
              value={value}
              {...rest}
            >
              {prefixLabel && `${prefixLabel} ${label}`}
              {label}
            </option>
          )
        })}
      </select>
      <Icon
        fill={iconColor || '#000000'}
        className={cx(css.icon, iconClassName)}
      />
    </div>
  )
}

MockSelect.defaultProps = {
  addRequiredIndicatorOnLabel: true,
}

export default MockSelect
