// SYSTEM
const SYSTEM = {
  VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV ?? 'development',
  NODE_ENV: process.env.NODE_ENV,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF:
    process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF ?? 'development',
} as const

export type TSYSTEM = keyof typeof SYSTEM

/**
 * If the variable is not defined, throw an error. Otherwise, return the variable.
 * @param {TSYSTEM} variable - TSYSTEM
 */
export default function processEnvSystem(variable: TSYSTEM) {
  const envVar = SYSTEM[variable]

  if (!envVar) {
    console.warn(`[${variable}] => Not founded system variable`)
  }

  return envVar
}
