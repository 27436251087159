export enum PRODUCT_TYPE {
  PRODUCT = 'a',
  SETS = 'b',
  GIFT = 'c',
  SAMPLE = 'd',
}

export enum PRODUCT_TYPE_TRACKING {
  PRODUCT = 'none',
  SETS = 'sets',
  GIFT = 'gwp',
  SAMPLE = 'sample',
  MINIATURE = 'miniature',
}

export enum SAMPLE_TYPE_TRACKING {
  TRY_ME = 'trybuy',
  COMPLIMENTARY = 'swp',
}
