import { useRouter } from 'next/router'
import { useEffect } from 'react'

import { useGetCustomer } from '@unlikelystudio/react-ecommerce-hooks'

export default function useAuthentication(
  redirectOnError: string,
  redirectOnSuccess?: string,
) {
  const router = useRouter()

  useEffect(() => {
    redirectOnError && router.prefetch(redirectOnError as string)
    redirectOnSuccess && router.prefetch(redirectOnSuccess as string)
  }, [])

  const { isSuccess, isInitialLoading, ...profileQuery } = useGetCustomer({
    refetchOnMount: true,
    keepPreviousData: true,
    retry: 0,
    onSuccess: (data) => {
      data && redirectOnSuccess && router.replace(redirectOnSuccess)
      !data && redirectOnError && router.replace(redirectOnError)
    },
  })

  const shouldRedirectOnError =
    profileQuery.fetchStatus === 'idle' && !profileQuery.data && redirectOnError

  useEffect(() => {
    if (shouldRedirectOnError) {
      redirectOnError && router.replace(redirectOnError)
    }
  }, [shouldRedirectOnError])

  return {
    isIdle: profileQuery.fetchStatus === 'idle',
    isInitialLoading,
    isSuccess,
    ...profileQuery,
  }
}
