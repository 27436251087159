import classnames from 'classnames/bind'
import React from 'react'
import { useTranslate } from 'react-polyglot'

import { NavigationLogoProps } from '~/components/Navigation/Navigation/types'
import Link from '~/components/UI/Link'

import useLocale from '~/hooks/useLocale'

import { GENERAL } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

function NavigationLogo({
  className,
  theme,
  onClick,
  fluidSize = false,
}: NavigationLogoProps) {
  const t = useTranslate()
  const locale = useLocale()

  const linkProps = {
    onClick,
    className: cx(css.NavigationLogo, className, {
      [`${theme}Theme`]: theme,
    }),
    title: t(GENERAL.LOGO_TITLE),
    href: `/${locale}`,
  }

  return (
    <Link {...linkProps}>
      <svg
        {...(!fluidSize && { width: '243', height: '29' })}
        viewBox="0 0 243 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.3713 1.24576H19.7128C19.7128 1.24576 20.3531 1.24577 21.1812 1.38984C23.1533 1.64408 23.5034 2.11865 23.5034 4.55933V22.2712L6.66768 0.398305L6.56523 0.262709H0V1.24576H0.341489C2.45875 1.38136 3.33811 1.90679 4.24307 2.89832V24.3305C4.24307 26.7966 3.90156 27.2712 1.96358 27.5C1.10985 27.6017 0.461016 27.6441 0.452479 27.6441H0.11099V28.6186H10.0314V27.6441H9.69845C9.69845 27.6441 9.00691 27.6017 8.18732 27.5C6.24081 27.2712 5.90786 26.7966 5.90786 24.3305V5.05932L24.34 28.7881L24.451 28.9237H25.1938V4.63559C25.1938 2.19492 25.5438 1.72035 27.4818 1.46611C28.3355 1.35594 28.9843 1.32203 28.9929 1.32203H29.3258V0.34746H19.3713V1.24576Z"
          fill="#000000"
        />
        <path
          d="M34.8239 1.24576H35.1654C35.1654 1.24576 35.9252 1.24577 36.779 1.38984C38.6913 1.6356 39.0243 2.11864 39.0243 4.60169V24.2966C39.0243 26.7712 38.6913 27.2458 36.779 27.5C35.9252 27.6017 35.1739 27.6441 35.1654 27.6441H34.8239V28.6186H46.503V27.6441H46.1615C46.1615 27.6441 45.4273 27.6017 44.5821 27.5C42.6356 27.2458 42.3026 26.7712 42.3026 24.2966V4.63559C42.3026 2.15254 42.6356 1.67796 44.5821 1.42372C45.4358 1.31355 46.153 1.27967 46.1615 1.27967H46.503V0.305098H34.8239V1.24576Z"
          fill="#000000"
        />
        <path
          d="M71.3979 1.24576H71.7394C71.7394 1.24576 72.3883 1.24577 73.2079 1.38984C75.1885 1.64408 75.53 2.11865 75.53 4.55933V22.2712L58.6431 0.398305L58.5321 0.262709H51.9754V1.24576H52.3084C54.4171 1.38136 55.305 1.90679 56.21 2.89832V24.3305C56.21 26.7966 55.8685 27.2712 53.9305 27.5C53.0768 27.6017 52.4194 27.6441 52.4194 27.6441H52.0779V28.6186H62.0581V27.6441H61.7166C61.7166 27.6441 61.025 27.6017 60.2055 27.5C58.2675 27.2712 57.926 26.7966 57.926 24.3305V5.05932L76.3667 28.7881L76.4691 28.9237H77.2204V4.63559C77.2204 2.19492 77.5619 1.72035 79.4914 1.46611C80.3451 1.35594 81.0025 1.32203 81.011 1.32203H81.344V0.34746H71.3979V1.24576Z"
          fill="#000000"
        />
        <path
          d="M110.55 27.6017C109.406 27.5085 108.664 27.339 107.707 25.1949L97.1551 0.211863L97.0612 -9.53674e-07H96.4295L86.62 24.3051C85.587 26.8474 85.3565 27.3051 83.3759 27.5678L82.1038 27.7119V28.6695H90.5302V27.6949H90.1972C90.1972 27.6949 89.591 27.6949 89.3435 27.6271C88.4897 27.5339 88.0458 27.3559 87.875 27.0509C87.7043 26.7458 87.7726 25.9746 88.319 24.6271L90.3423 19.678H101.962L104.463 25.6102C104.779 26.3475 104.847 26.8136 104.685 27.0763C104.523 27.339 104.002 27.5424 103.114 27.6441C102.773 27.6441 102.26 27.6441 102.26 27.6441H101.91V28.6441H111.754V27.6695H111.421C111.421 27.6695 110.823 27.6441 110.55 27.6017ZM101.253 18.0424H90.9143L95.9599 5.59322L101.253 18.0424Z"
          fill="#000000"
        />
        <path
          d="M148.106 27.517C147.797 27.64 147.466 27.7005 147.133 27.6949C145.314 27.6949 144.333 26.5254 141.899 21.4576C139.791 17.0593 138.809 15.7373 136.632 15.0085C138.256 14.5975 139.691 13.6505 140.701 12.323C141.711 10.9954 142.236 9.36634 142.19 7.70339C142.19 5.95762 141.473 0.271192 132.261 0.271192H122.87V1.24576H123.211C123.211 1.24576 123.971 1.24577 124.825 1.38984C126.737 1.64408 127.07 2.11864 127.07 4.60169V24.2966C127.07 26.7712 126.737 27.2458 124.825 27.5C123.971 27.6017 123.22 27.6441 123.211 27.6441H122.87V28.6186H134.728V27.6865H134.387C134.387 27.6865 133.473 27.6441 132.628 27.5424C130.664 27.2881 130.348 26.839 130.348 24.339V15.7627H132.619C134.967 15.7627 136.034 16.8814 138.322 21.7458C140.508 26.3983 141.737 28.9576 145.844 28.9576C146.774 28.9892 147.699 28.8004 148.542 28.4068L148.823 28.2542L148.431 27.4068L148.106 27.517ZM130.357 1.88984H132.064C136.478 1.88984 138.536 3.74578 138.536 7.72035C138.536 11.839 136.179 14.1949 132.064 14.1949H130.357V1.88984Z"
          fill="#000000"
        />
        <path
          d="M150.906 1.24576H151.248C151.248 1.24576 152.008 1.24577 152.861 1.38984C154.765 1.6356 155.107 2.11864 155.107 4.60169V24.2966C155.107 26.7712 154.774 27.2458 152.861 27.5C152.008 27.6017 151.256 27.6441 151.248 27.6441H150.906V28.6186H162.585V27.6441H162.244C162.244 27.6441 161.51 27.6017 160.656 27.5C158.718 27.2458 158.385 26.7712 158.385 24.2966V4.63559C158.385 2.09322 158.701 1.67796 160.656 1.42372C161.51 1.31355 162.235 1.27967 162.244 1.27967H162.585V0.305098H150.906V1.24576Z"
          fill="#000000"
        />
        <path
          d="M192.944 21.6356C191.872 23.3308 190.389 24.7324 188.631 25.7124C186.873 26.6924 184.895 27.2195 182.879 27.2457C175.571 27.2457 170.85 22.2203 170.85 14.4407C170.85 6.66101 175.229 1.6017 181.743 1.6017C189.862 1.6017 191.595 9.39829 191.664 9.73727L191.724 10.0169H192.628V0.288121H191.655V0.610159C191.655 0.610159 191.587 1.41524 191.51 1.79659C191.288 3.01693 190.887 3.01694 190.699 3.01694C190.003 2.8902 189.347 2.59959 188.787 2.16948C186.726 0.760548 184.28 0.0122712 181.777 0.025411C173.402 0.025411 167.324 6.08473 167.324 14.4322C167.324 22.7796 173.53 28.983 182.076 28.983C184.484 28.9978 186.851 28.366 188.926 27.1546C191.002 25.9432 192.709 24.1973 193.866 22.1017L194.012 21.7966L193.158 21.322L192.944 21.6356Z"
          fill="#000000"
        />
        <path
          d="M224.644 21.6356C223.571 23.3308 222.088 24.7324 220.33 25.7124C218.572 26.6924 216.594 27.2195 214.578 27.2457C207.27 27.2457 202.549 22.2203 202.549 14.4407C202.549 6.66101 206.929 1.60169 213.443 1.60169C221.562 1.60169 223.295 9.39828 223.363 9.73727L223.423 10.0169H224.276V0.28812H223.303V0.610158C223.303 0.610158 223.226 1.41524 223.158 1.79659C222.928 3.01693 222.535 3.01693 222.304 3.01693C221.607 2.8918 220.951 2.60103 220.392 2.16948C218.331 0.761536 215.885 0.0133571 213.383 0.02541C205.008 0.02541 198.92 6.08473 198.92 14.4322C198.92 22.7796 205.127 28.983 213.673 28.983C216.082 28.9975 218.45 28.3657 220.527 27.1544C222.603 25.9431 224.312 24.1974 225.472 22.1017L225.617 21.7966L224.763 21.3135L224.644 21.6356Z"
          fill="#000000"
        />
        <path
          d="M240.088 1.42372C240.941 1.31355 241.667 1.27967 241.667 1.27967H242.009V0.305098H230.329V1.24576H230.671C230.671 1.24576 231.439 1.24577 232.285 1.38984C234.197 1.6356 234.538 2.11864 234.538 4.60169V24.2966C234.538 26.7712 234.197 27.2458 232.293 27.5C231.439 27.6017 230.679 27.6441 230.671 27.6441H230.329V28.6186H242.009V27.6441H241.667C241.667 27.6441 240.941 27.6017 240.088 27.5C238.15 27.2458 237.808 26.7712 237.808 24.2966V4.63559C237.808 2.09322 238.124 1.67796 240.088 1.42372Z"
          fill="#000000"
        />
      </svg>
    </Link>
  )
}

NavigationLogo.defaultProps = {}

export default NavigationLogo
