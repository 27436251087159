import { TRACKING_EVENTS } from '~/lib/constants'

function trackInternalFooterLink(tracker, linkLabel: string) {
  tracker.emit(TRACKING_EVENTS.TAGGED_EVENT, {
    eventName: 'menuFooterLinks',
    eventAction: 'footer',
    eventCategory: 'menu',
    eventLabel: linkLabel?.replaceAll("'", ' '),
  })
}

function trackSocialFooterLink(tracker, href: string, socialName: string) {
  tracker.emit(TRACKING_EVENTS.INTERACTION_TAGGED_EVENT, {
    eventName: 'linkSocial',
    eventAction: socialName,
    eventCategory: 'social',
    eventLabel: href?.replaceAll("'", ' '),
    interaction: {
      outboundLink: {
        link: href,
        socialNetworkID: socialName?.toLowerCase(),
        type: 'social',
      },
    },
  })
}

export { trackInternalFooterLink, trackSocialFooterLink }
