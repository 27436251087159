import classnames from 'classnames/bind'
import { ComponentProps } from 'react'
import { useTranslate } from 'react-polyglot'

import {
  formDictionary,
  TemplatesProvider,
  useStoreContext,
} from '@unlikelystudio/react-ecommerce-hooks'

import SquaredCta from '~/components/UI/SquaredCta'

import { FORM } from '~/data/dictionary'

import css from './styles.module.scss'

type TemplatesProviderProps = ComponentProps<typeof TemplatesProvider>

const cx = classnames.bind(css)

export interface FormTemplateProps extends TemplatesProviderProps {
  className?: string
  isLoading?: boolean
  oneLineForm?: boolean
}

function FormTemplate({
  className,
  dictionary,
  components,
  children,
  isLoading,
  oneLineForm,
}: FormTemplateProps) {
  const { locale } = useStoreContext()
  const t = useTranslate()

  /* Adding a new key to the dictionary object. */
  const processedDictionary = Object.keys(dictionary)?.reduce((acc, lang) => {
    return {
      ...acc,
      [lang]: {
        ...dictionary[lang],
        requiredAcceptsPrivacyPolicy: t(FORM.REQUIRED_ACCEPTS_PRIVACY_POLICY),
      },
    }
  }, {})

  return (
    <div className={cx(className, css.FormTemplate, { oneLineForm })}>
      <TemplatesProvider
        dictionary={processedDictionary}
        components={{
          input: (props) => {
            if (props?.type === 'submit') {
              return (
                <SquaredCta
                  className={css.submit}
                  type="submit"
                  withBackground
                  disabled={props?.disabled || isLoading}>
                  {/**
                   * Add custom loading to display loading string longer if necessary
                   * (the same applies to the disabled prop used before)
                   * eg: during metafield setting
                   */}
                  {isLoading
                    ? formDictionary?.[locale]?.loading ?? props?.value
                    : props?.value}
                </SquaredCta>
              )
            }
          },
          ...components,
        }}>
        {children}
      </TemplatesProvider>
    </div>
  )
}

FormTemplate.defaultProps = {
  dictionary: formDictionary,
  components: {},
}

export default FormTemplate
