import BannerMarket from '~/components/UI/BannerMarket/BannerComponent'

import { useMarketContext } from '~/providers/MarketProvider'

import useMarketCookie from '~/hooks/useMarketCookie'

export interface BannerMarketManagerProps {
  className?: string
}

function BannerMarketManager({ className }: BannerMarketManagerProps) {
  const { setDisplayMarketBanner, displayMarketBanner } = useMarketContext()

  const { setMarketBannerUserPreferences, setMarketBannerDisplayed } =
    useMarketCookie()

  if (displayMarketBanner) {
    return null
  }

  return (
    <div className={className}>
      <BannerMarket
        onClickClose={() => {
          setDisplayMarketBanner?.(true)

          // Cookie side
          setMarketBannerDisplayed?.(true)
        }}
        onGlobalChange={(data) => {
          setMarketBannerUserPreferences?.(data)
          setDisplayMarketBanner?.(true)

          // Cookie side
          setMarketBannerDisplayed?.(true)
        }}
      />
    </div>
  )
}

export default BannerMarketManager
