import type { DownshiftSelectProps } from '~/components/Form/DownshiftSelect'
import DownshiftSelect from '~/components/Form/DownshiftSelect'

export type { Option } from '~/components/Form/DownshiftSelectMock'

function DropdownSelect({
  className,
  productSelect,
  isInMarketPanel,
  langSelect,
  dateSelect,
  accountSelect,
  currentValue,
  customLabel,
  textPreset,
  disabled,
  ...selectProps
}: DownshiftSelectProps) {
  const defaultProps = {
    productSelect,
    langSelect,
    dateSelect,
    accountSelect,
    currentValue,
    customLabel,
    textPreset,
    disabled,
    isInMarketPanel,
  }

  return (
    <DownshiftSelect className={className} {...defaultProps} {...selectProps} />
  )
}

export default DropdownSelect
