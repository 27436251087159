import React, {
  createContext,
  JSXElementConstructor,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'

import { PanelsData } from '~/components/Abstracts/Panels/PanelManager/types'

export const PanelContext = createContext<PanelType>({})

export function usePanel() {
  return useContext(PanelContext)
}

interface ProviderPanelProps {
  data?: PanelsData
  children: JSX.Element | JSX.Element[]
}

interface Panel {
  component: ReactElement<any, string | JSXElementConstructor<any>>
  direction?: 'left' | 'right' | 'top' | 'bottom'
  context?: 'default' | 'market'
  key?: string
  actions?: PanelAction
}

interface PanelAction {
  onClose?: () => void
}

export interface PanelType {
  panels?: Panel[]
  data?: PanelsData
  add?: (panel: Panel, actions?: Partial<PanelAction>) => string
  removeCurrent?: () => void
  removeAll?: () => void
  update?: (key: string, props: object) => void
}

export default function PanelProvider({ data, children }: ProviderPanelProps) {
  const [panels, setPanels] = useState<Panel[]>([])
  const keysRef = useRef(0)

  const add = useCallback((panel: Panel, actions: Partial<PanelAction>) => {
    const key = `panel_${keysRef.current}`

    /**
     * Wrap panel add action in a setTimeout
     * to prevent useClickOutide (PanelManager) from triggering
     */
    setTimeout(() => {
      setPanels((panels) => [
        ...panels,
        {
          ...panel,
          direction: panel?.direction || 'right',
          context: panel?.context || 'default',
          key,
          actions,
        },
      ])

      keysRef.current += 1
    }, 0)

    return key
  }, [])

  const removeCurrent = useCallback(() => {
    setPanels((panels) => {
      if (panels.length > 0) {
        panels?.[panels?.length - 1]?.actions?.onClose?.()
        panels.pop()
      }
      return [...panels]
    })
  }, [])

  const removeAll = useCallback(() => {
    panels?.forEach((panel) => {
      panel?.actions?.onClose?.()
    })

    setPanels(() => {
      return []
    })
  }, [])

  const update = useCallback((key, props) => {
    setPanels((panels) => {
      return panels.map((panel) => {
        if (panel.key === key)
          panel.component = React.cloneElement(panel.component, props)
        return panel
      })
    })
  }, [])

  const value: PanelType = useMemo(
    () => ({
      panels,
      data,
      add,
      removeCurrent,
      removeAll,
      update,
    }),
    [panels, data, add, removeCurrent, removeAll, update],
  )

  return <PanelContext.Provider value={value}>{children}</PanelContext.Provider>
}
