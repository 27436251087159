import Cookies from 'js-cookie'
import React, {
  useMemo,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react'
import GEOLOCATION_MATCHINGS from '~/../config/geolocation.json'
import { COOKIE_KEYS } from '~/lib/constants'

import { ShopifyCountryCode as CountryCode } from '@unlikelystudio/commerce-connector'

import useGeolocationCookie from '~/hooks/useGeolocationCookie'
import useGeolocationMutation from '~/hooks/useGeolocationMutation'
import useLocale from '~/hooks/useLocale'

interface GeolocationContextProps {
  countryISO?: CountryCode
  preferedLocation?: string
  isShippingCountry?: boolean
  displayGlobalPopIn?: boolean
  displayCheckoutPopIn?: boolean
  setDisplayGlobalPopIn?: (boolean) => void
  setDisplayCheckoutPopIn?: (boolean) => void
}

type Locales =
  (typeof GEOLOCATION_MATCHINGS)[keyof typeof GEOLOCATION_MATCHINGS]

export const Context = createContext<GeolocationContextProps>({})

export function useGeolocation() {
  return useContext(Context)
}

export interface GeolocationProviderProps {
  children?: JSX.Element | JSX.Element[]
}

const NON_SHIPPING_AREA = 'NON_SHIPPING_AREA'

export default function GeolocationProvider({
  children,
}: GeolocationProviderProps) {
  const { data } = useGeolocationMutation({
    refetchOnWindowFocus: false,
  })
  const cookieCountryCode = Cookies.get(COOKIE_KEYS.userCountryCode)

  const { getGlobalGeoLocation } = useGeolocationCookie()
  const locale = useLocale()

  const countryCodeWithLastLetterInLowercase = `${
    cookieCountryCode?.[0] ?? 'U'
  }${cookieCountryCode?.[1]?.toLowerCase() ?? 's'}`

  const [countryISO, setCountryISO] = useState(
    CountryCode[countryCodeWithLastLetterInLowercase],
  )

  useEffect(() => {
    if (data) {
      setCountryISO(
        data?.country ?? CountryCode[countryCodeWithLastLetterInLowercase],
      )
    }
  }, [data])

  // Get the good locale from your country iso code
  const preferedLocation = countryISO
    ? GEOLOCATION_MATCHINGS?.[countryISO] ?? NON_SHIPPING_AREA
    : undefined

  // Is a shipping area depending on geolocation file
  const isShippingCountry = preferedLocation !== NON_SHIPPING_AREA

  // Has already a cookie
  const isGlobalGeolocCookie = getGlobalGeoLocation()

  // Wrong locale from detection
  const isWrongLocale = locale !== preferedLocation

  // Locale state of the popin
  const [displayGlobalPopIn, setDisplayGlobalPopIn] = useState(null)
  const [displayCheckoutPopIn, setDisplayCheckoutPopIn] = useState(null)

  // Need to display the popin
  const needPopIn =
    (!isShippingCountry || isWrongLocale) && !isGlobalGeolocCookie

  useEffect(() => {
    if (preferedLocation) {
      setDisplayGlobalPopIn(needPopIn)
    }
  }, [preferedLocation, needPopIn])

  const providerValue = useMemo(
    () => ({
      countryISO,
      preferedLocation,
      displayGlobalPopIn,
      displayCheckoutPopIn,
      setDisplayGlobalPopIn,
      setDisplayCheckoutPopIn,
      isShippingCountry,
    }),
    [countryISO, preferedLocation, displayCheckoutPopIn, displayGlobalPopIn],
  )

  return <Context.Provider value={providerValue}>{children}</Context.Provider>
}
