import { ErrorMessage } from '@hookform/error-message'
import classnames from 'classnames/bind'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ErrorListProps {
  className?: string
  name?: string
  id?: string
}

export function ErrorList({ className, name, id }: ErrorListProps) {
  const t = useTranslate()

  const { formState } = useFormContext() || {}
  const error = formState?.errors?.[name] ?? null

  const hasErrors = error?.type || Object.keys(error?.types ?? {})?.length > 0

  const divProps = {
    className: cx(css.ErrorList, className),
    ...(id ? { id: id } : {}),
  }

  return (
    hasErrors && (
      <div {...divProps}>
        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={() => <div>{t(`error.${error?.type}`)}</div>}
        />

        <ErrorMessage
          errors={formState.errors}
          name={name}
          render={({ messages }) =>
            messages &&
            Object.entries(messages).map(([type]) => (
              <div key={type}>{t(`error.${error?.type}`)}</div>
            ))
          }
        />
      </div>
    )
  )
}

ErrorList.defaultProps = {}

export default ErrorList
