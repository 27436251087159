import { UnlikelyCustomAttributes } from '@unlikelystudio/commerce-connector'

export interface Attribute {
  key: string
  value: string
}

export function customAttributesAsObject(
  customAttributes: UnlikelyCustomAttributes,
): {
  [key: string]: string
} {
  return customAttributes
    ?.map(({ key, value }) => ({
      [key]: value,
    }))
    ?.reduce((obj, current) => {
      return {
        ...obj,
        ...current,
      }
    }, {})
}

export function objectAsCustomAttributes(attributes: {
  [key: string]: string
}): Attribute[] {
  return Object.keys(attributes ?? {})?.map((key) => ({
    key,
    value: attributes[key],
  }))
}
