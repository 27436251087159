import { useState } from 'react'

export default function useShowZendeskButton() {
  const [isZendeskButtonShown, setIsZendeskButtonShown] = useState(true)

  return {
    isZendeskButtonShown,
    setIsZendeskButtonShown,
  }
}
