import { HTMLAttributes } from 'react'
import { UrlObject } from 'url'

import { Spinner } from '@unlikelystudio/react-abstract-components'
import {
  DeviceType,
  useDeviceType,
  useIsHover,
} from '@unlikelystudio/react-hooks'

import Link, { LinkProps } from '~/components/UI/Link'

export interface CtaBaseProps extends HTMLAttributes<HTMLElement> {
  className?: string
  href?: string | UrlObject
  children?: any
  theme?: string
  isLoading?: boolean
  withBackground?: boolean
  withBorder?: boolean
  withHover?: boolean
  disabled?: boolean
  isDiv?: boolean
  negativeColor?: string
  type?: 'submit' | 'reset' | 'button' | undefined
  cx?: (...args: any[]) => string
  css?: Record<string, string>
}

export type CtaProps = CtaBaseProps | (LinkProps & CtaBaseProps)

function Cta({
  className,
  children,
  href,
  withBackground,
  theme,
  onMouseEnter,
  onMouseLeave,
  withBorder,
  withHover,
  isLoading,
  isDiv,
  negativeColor,
  disabled,
  cx,
  css,
  ...rest
}: CtaProps) {
  const [isHover, mouseEvents] = useIsHover({
    onMouseEnter,
    onMouseLeave,
  })
  const device = useDeviceType()

  const classNames = cx(className, {
    withBackground,
    withBorder,
    hovered: !isLoading
      ? device === DeviceType.mobile
        ? false
        : isHover
      : false,
    loading: isLoading,
    [`${theme}Theme`]: theme,
    disabled,
  })

  const CtaChildren = (
    <>
      {isLoading && (
        <div className={cx(css.spinner, { isLoading })}>
          <Spinner color={negativeColor} />
        </div>
      )}
      <span className={cx(css.childrenContent, { isLoading })}>
        {typeof children === 'function' ? children({ isHover }) : children}
      </span>
    </>
  )

  return href ? (
    <Link href={href} className={classNames} {...mouseEvents} {...rest}>
      {CtaChildren}
    </Link>
  ) : isDiv ? (
    <div className={classNames} {...mouseEvents} {...rest}>
      {CtaChildren}
    </div>
  ) : (
    <button
      className={classNames}
      {...mouseEvents}
      {...rest}
      disabled={disabled}>
      {CtaChildren}
    </button>
  )
}

Cta.defaultProps = {
  withBackground: true,
  withBorder: false,
  withHover: true,
}

export default Cta
