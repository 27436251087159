import useLocale from '~/hooks/useLocale'

import { getLanguage } from '~/utils/locales'

function useLanguage(): string {
  const locale = useLocale()
  return getLanguage(locale) ?? locale
}

export default useLanguage
