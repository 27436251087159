export const GYGIA_TAGS = {
  WEBSITE_HEADER: 'website_header',
  WEBSITE_FOOTER: 'website_footer',
  WEBSITE_POPUP: 'website_popup',
  WEBSITE_CHECKOUT: 'website_checkout',
  WEBSITE: 'website',
} as const

export const POPIN_TAGS = {
  NINA_CONTEST: 'website_popup_venus',
} as const

export type GYGIA_TAGS_KEYS = (typeof GYGIA_TAGS)[keyof typeof GYGIA_TAGS]

export interface GetTagsFromCurrencyFromAndCountryProps {
  currency: string
  from: GYGIA_TAGS_KEYS
  country: string
  campaign?: string
  language?: string
}

export function getTagsFromCurrencyFromAndCountry({
  currency,
  from,
  country,
  campaign,
  language,
}: GetTagsFromCurrencyFromAndCountryProps) {
  return [
    `currency:${currency}`,
    from ? `from:${from}` : null,
    country ? `country:${country?.toUpperCase()}` : null,
    campaign ? `campaign:${campaign}` : null,
    language ? `language:${language}` : null,
  ]?.filter(Boolean)
}
