import {
  ALGOLIA_PAGE_TYPE_PROPERTY_KEY,
  ALGOLIA_PRISMIC_ID_PROPERTY_KEY,
} from '~/lib/algolia/constants'
import { algoliaSearch } from '~/lib/algolia/public-client'

import type { Facets, FacetsResult } from '@unlikelystudio/horizon-algolia'

import getAlgoliaProductIndex from '~/utils/get-algolia-product-index'

interface FetchFacetsParams {
  locale: string
  facets?: Facets
}

export default async function fetchFacets({
  locale,
  facets,
}: FetchFacetsParams): Promise<FacetsResult[]> {
  const indiceName = getAlgoliaProductIndex()

  const algoliaFacets =
    (await algoliaSearch.facets({
      lang: locale,
      indice_name: indiceName,
      facets,
    })) ?? []

  const filteredFacets = algoliaFacets
    ?.filter(
      (facet) =>
        facet?.type !== ALGOLIA_PRISMIC_ID_PROPERTY_KEY &&
        facet?.type !== ALGOLIA_PAGE_TYPE_PROPERTY_KEY &&
        !Object.keys(facets)?.includes(facet?.type),
    )
    ?.map((facet) => ({
      ...facet,
      values: facet?.values?.map((value) => ({
        ...value,
        label: value?.name,
      })),
    }))

  return filteredFacets
}

export type { FacetsResult }
