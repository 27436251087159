import { DotCheckboxType } from '~/components/Form/DotCheckbox'

import { SortByValues } from '~/providers/FilterProvider'

export const ORDERING_DATA = (isShop: boolean) => [
  {
    type: 'order_by',
    inputType: DotCheckboxType.RADIO,
    values: [
      {
        name: SortByValues.DEFAULT,
        available: true,
      },
      ...(isShop
        ? [
            {
              name: SortByValues.PRICE_DESC,
              available: true,
            },
            {
              name: SortByValues.PRICE_ASC,
              available: true,
            },
          ]
        : []),
    ],
  },
]
