import classnames from 'classnames'

import {
  Image as AbstractImage,
  ImageProps,
} from '~/components/Abstracts/Image'
import { imageLoader } from '~/components/Abstracts/Image/loader'

import { useTheme } from '~/providers/ThemeProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)
function Image(props: ImageProps) {
  const { screens } = useTheme() ?? {}

  const { className, ...rest } = props
  return (
    <AbstractImage
      className={cx(css.Image, className)}
      screens={screens}
      placeholderClassName={css.placeholder}
      imageLoader={(loaderProps) => imageLoader({ ...loaderProps })}
      {...rest}
    />
  )
}

export { imageLoaderPrismic } from '@unlikelystudio/react-abstract-components'

export type { Sizes } from '@unlikelystudio/react-abstract-components'
export type { ImageProps } from '~/components/Abstracts/Image'

export default Image
