import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { trackClickEventForMenuLink } from '~/components/Navigation/Navigation/utils'
import InlineCta from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import { MainLinkProps } from '../types'
import NavigationImageItem from './ImageItem'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export default function NavigationSubPanel({
  columns,
  itemLeft,
  itemRight,
}: MainLinkProps) {
  const { tracker } = useTracker()
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title14HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={css.NavigationSubPanel}>
      <div className={css.columns}>
        {columns?.map(({ sections }, index) => {
          return (
            <div className={css.column} key={`column_${index}`}>
              {sections?.map(({ title, links }, index) => {
                return (
                  <div className={css.section} key={`section_${index}`}>
                    {title && (
                      <div className={cx(css.sectionTitle, titleStyle)}>
                        {title}
                      </div>
                    )}
                    <div className={css.sectionLinks}>
                      {links?.map((link, linkIndex) => (
                        <InlineCta
                          hasLine={false}
                          className={css.sectionLink}
                          textPreset={GlobalTextPreset.Cta12Haffer}
                          theme={GlobalThemeColors.DoveGray}
                          {...link}
                          key={`link_${index}_${linkIndex}`}
                          onClick={() => {
                            trackClickEventForMenuLink(
                              tracker,
                              link?.children ?? '',
                            )
                          }}
                        />
                      ))}
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      <div className={css.imageItems}>
        {itemLeft && <NavigationImageItem {...itemLeft} className={css.left} />}
        {itemRight && (
          <NavigationImageItem {...itemRight} className={css.right} />
        )}
      </div>
    </div>
  )
}
