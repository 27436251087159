import { useQuery } from '@tanstack/react-query'
import { QUERY_KEYS } from '~/lib/constants'
import { METAFIELDS } from '~/lib/shopify/metafields'
import { getStorePublicCredentials } from '~/lib/shopify/public-client'

import { sfCustomer } from '@unlikelystudio/commerce-connector'
import { useCustomerContext } from '@unlikelystudio/react-ecommerce-hooks'

export default function useGetCustomerMetafields() {
  const { customerAccessToken } = useCustomerContext()
  const credentials = getStorePublicCredentials()

  return useQuery(
    [QUERY_KEYS.METAFIELDS, customerAccessToken],
    async () => {
      try {
        const response = await sfCustomer(credentials, {
          token: customerAccessToken,
          includeMetafields: true,
          metafieldKeys: Object.values(METAFIELDS).map(
            (item) => item.processedKey,
          ),
        })

        return response?.customer?.metafields ?? {}
      } catch (error) {
        console.log(error)
      }
    },
    { enabled: true },
  )
}
