import React from 'react'

import { LoadingBar } from '@unlikelystudio/react-abstract-components'

import useNextPageLoadingState from '~/hooks/useNextPageLoadingState'

import css from './styles.module.scss'

function NextLoadingBar() {
  const loadingState = useNextPageLoadingState()
  return (
    <LoadingBar
      loadingState={loadingState}
      className={css.LoadingBar}
      isVisibleClassName={css.isVisible}
      loadingClassName={css.loadingProgress}
    />
  )
}

export default NextLoadingBar
