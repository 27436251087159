import classnames from 'classnames/bind'
import { useCallback, useEffect, useTransition } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'
import { GlobalBreakpoints } from '~/@types/breakpoints'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { Spinner } from '@unlikelystudio/react-abstract-components'
import { useBodyScrollLock } from '@unlikelystudio/react-hooks'

import { CloseIcon } from '~/components/Abstracts/Icons'
import { FilterPanelProps } from '~/components/UI/Filters'
import Filter from '~/components/UI/FiltersPanelAccordion/Filter'
import InlineCta from '~/components/UI/InlineCta'
import SquaredCta from '~/components/UI/SquaredCta'

import { FilterPanelTypes } from '~/providers/FilterProvider'
import { useStyle } from '~/providers/StyleProvider'

import useBreakpoint from '~/hooks/useBreakpoint'
import useFormWithMutation from '~/hooks/useFormWithMutation'

import deepEqual from '~/utils/deep-equal'

import { FILTER } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface FiltersPanelAccordionProps extends FilterPanelProps {
  handleReset(form: UseFormReturn): void
}

export function FiltersPanelAccordion({
  className,
  filters,
  setFilters,
  panel,
  setPanel,
  handleReset,
  ...rest
}: FiltersPanelAccordionProps) {
  const [pending, startTransition] = useTransition()

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title18HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const clearStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12_14HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const t = useTranslate()

  const isMobile = useBreakpoint(GlobalBreakpoints.LG)

  useBodyScrollLock(isMobile)

  const handleSubmit = useCallback((props) => {
    // Update filters if deep comparison is different
    if (!deepEqual(filters, props)) {
      setFilters(props)
      setPanel(null)
    }
  }, [])

  const { form, onSubmit } = useFormWithMutation(handleSubmit, null)

  // Update filters on mount
  useEffect(() => {
    form.reset(filters)
  }, [])

  return (
    <FormProvider {...form}>
      <form
        className={cx(className, css.FiltersPanelAccordion)}
        onSubmit={() => {
          startTransition(() => onSubmit())
        }}>
        <div className={cx(gridStyle, css.topContainer)}>
          <div className={cx(css.content, css.top)}>
            <div className={css.close}>
              <CloseIcon onClick={() => setPanel(null)} />
            </div>
            <div className={cx(css.name, titleStyle)}>
              {panel === FilterPanelTypes.FILTER_BY
                ? t(FILTER.FILTER_BY)
                : t(FILTER.SORT_BY)}
            </div>
            <InlineCta
              href={null}
              type="button"
              onClick={() => handleReset(form)}
              className={cx(css.clear, clearStyle)}>
              {t(FILTER.CLEAR_ALL)}
            </InlineCta>
          </div>
        </div>
        <div className={cx(gridStyle, css.centerContainer)}>
          <div className={cx(css.center, css.content)}>
            <Filter {...rest} />
          </div>
        </div>
        <div className={cx(gridStyle, css.bottomContainer)}>
          <SquaredCta type="submit" className={cx(css.bottom, css.content)}>
            {t(FILTER.VALIDATE)}
            {pending && <Spinner className={css.spinner} color="white" />}
          </SquaredCta>
        </div>
      </form>
    </FormProvider>
  )
}
