/**
 * "It takes an object, and returns a new object with all the string values lowercased."
 *
 * The first line of the function is a TypeScript type annotation. It says that the function takes an
 * object of type T, and returns an object of type T
 * @param {T} obj - T
 * @returns {T} obj - T
 */
export default function lowercaseObj<T>(obj: T): T {
  return Object.fromEntries(
    Object.entries(obj).map(([_, v]) => [
      _,
      typeof v === 'string' ? v.toLowerCase() : v,
    ]),
  ) as T
}
