import classnames from 'classnames/bind'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import qs from 'qs'
import { useEffect, useState } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_REST_OF_THE_WORLD_LOCALES,
  NEXT_LOCALE_COOKIE,
} from '~/lib/constants'
import {
  findCountryAttributionFromCountryCode,
  getMarketCountriesValues,
  getMarketLangsAssociationsFromLocales,
} from '~/lib/shopify-market-countries'

import { CloseIcon } from '~/components/Abstracts/Icons'
import DropdownSelect from '~/components/Form/DropdownSelect'
import SquaredCta from '~/components/UI/SquaredCta'

import { useGeolocation } from '~/providers/GeolocationProvider'
import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useMarketContext } from '~/providers/MarketProvider'
import { useStyle } from '~/providers/StyleProvider'

import useLocale from '~/hooks/useLocale'
import useUpdateLayoutEffect from '~/hooks/useUpdateLayoutEffect'

import getAlternateUrl from '~/utils/alternate-url'
import getCountryNameFromCountryCode from '~/utils/get-country-name-from-country-code'
import { getQueryParams } from '~/utils/get-query-params'
import { getCountryCode } from '~/utils/locales'

import { GENERAL, MARKET } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface BannerMarketProps {
  className?: string
  onGlobalChange?: (data) => void
  onClickClose?(): void
}

function BannerMarket({
  className,
  onClickClose,
  onGlobalChange,
}: BannerMarketProps) {
  const locale = useLocale()
  const router = useRouter()
  const t = useTranslate()
  const { metas } = useGlobalData()
  const { countryISO } = useGeolocation()

  const [isLoaded, setIsLoaded] = useState(false)

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title12_14HafferSemiBold,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const { isLocaleSameAsUserPreferences, userPreferences } = useMarketContext()

  const countriesList =
    getMarketCountriesValues({
      locale,
      hasAdditionalOptions: true,
      additionalOptions: [
        {
          label: t(MARKET.BANNER_REST_OF_THE_WORLD),
          value: {
            countryCode: '',
            locales: DEFAULT_REST_OF_THE_WORLD_LOCALES,
          },
        },
      ],
    }) ?? []

  const [selectedCountry, setSelectedCountry] = useState(
    userPreferences?.countryCode && isLocaleSameAsUserPreferences
      ? findCountryAttributionFromCountryCode(
          userPreferences?.countryCode,
          countriesList,
        )
      : countryISO
      ? findCountryAttributionFromCountryCode(countryISO, countriesList)
      : null,
  )

  const langsList = selectedCountry
    ? getMarketLangsAssociationsFromLocales(selectedCountry?.value?.locales)
    : []

  const [selectedLang, setSelectedLang] = useState(
    langsList.length > 0 ? langsList[0] : null,
  )

  useEffect(() => {
    setIsLoaded(true)
  }, [router])

  useUpdateLayoutEffect(() => {
    if (!isLoaded) {
      setIsLoaded(true)

      if (countryISO !== selectedCountry?.value?.countryCode) {
        const foundCountryAttributions = findCountryAttributionFromCountryCode(
          countryISO,
          countriesList,
        )

        if (foundCountryAttributions) {
          setSelectedCountry(foundCountryAttributions)

          const langsList = foundCountryAttributions
            ? getMarketLangsAssociationsFromLocales(
                foundCountryAttributions?.value?.locales,
              )
            : []

          setSelectedLang(langsList.length > 0 ? langsList[0] : null)
        }
      }
    }
  }, [countryISO])

  const memoizedValues = {
    countries: countriesList,
    langs: langsList,
  }

  const onChangeCountry = (value) => {
    setSelectedCountry(value)
    const langsForSelectedCountry = getMarketLangsAssociationsFromLocales(
      value?.value?.locales,
    )

    if (langsForSelectedCountry.length === 1) {
      setSelectedLang(langsForSelectedCountry[0])
    } else {
      setSelectedLang(
        langsForSelectedCountry.length > 0 ? langsForSelectedCountry[0] : null,
      )
    }
  }

  const onChangeLang = (value) => {
    setSelectedLang(value)
  }

  const onSubmit = () => {
    let countryCode = selectedCountry?.value?.countryCode ?? null

    if (!countryCode) {
      countryCode = getCountryCode(selectedLang?.value) ?? DEFAULT_COUNTRY_CODE
    }

    onGlobalChange?.({
      countryCode,
      marketName: 'Nina Ricci',
      locale: selectedLang?.value,
    })

    if (locale !== selectedLang?.value) {
      redirectToLang()
    }
  }

  const redirectToLang = () => {
    const selectedLocale = metas?.langSwitcher?.find(
      (altLng) => altLng?.locale === selectedLang?.value,
    )

    const params = qs.stringify(getQueryParams(), {
      skipNulls: true,
      arrayFormat: 'repeat',
      encode: false,
    })

    const processedParams = `${params ? '?' + params : ''}`
    const pathname = selectedLocale?.url
      ? `${selectedLocale?.url}${processedParams}`
      : getAlternateUrl('/' + selectedLang?.value + processedParams)

    Cookies.set(NEXT_LOCALE_COOKIE, selectedLang?.value)

    window.location.href = pathname
  }

  return isLoaded ? (
    <div className={cx(css.BannerMarket, className)}>
      <div className={cx(gridStyle, css.container)}>
        <div className={css.left}>
          <p className={cx(titleStyle, css.title)}>
            {t(MARKET.BANNER_TEXT, {
              country: countryISO
                ? getCountryNameFromCountryCode(locale, countryISO)
                : null,
            })}
          </p>
        </div>
        <div className={css.right}>
          <DropdownSelect
            className={cx(css.item, css.selectItem, css.countrySelect)}
            onChange={(item) => {
              item?.value && onChangeCountry(item)
            }}
            key={`country_selector`}
            customLabel={t(MARKET.BANNER_CHOOSE_COUNTRY)}
            currentValue={selectedCountry}
            options={memoizedValues.countries}
            isInMarketPanel
            layout="light"
            openingDirection="up"
            langSelect
          />
          {selectedCountry && (
            <DropdownSelect
              shouldAutoOpen={memoizedValues?.langs?.length > 1}
              disabled={memoizedValues?.langs?.length <= 1}
              className={cx(css.item, css.selectItem, css.langSelect, {
                hasNoValues: memoizedValues?.langs?.length === 0,
              })}
              onChange={(item) => {
                item?.value && onChangeLang(item)
              }}
              key={`lang_selector`}
              customLabel={t(MARKET.BANNER_CHOOSE_LANG)}
              currentValue={selectedLang}
              options={memoizedValues?.langs ?? []}
              layout="light"
              openingDirection="up"
              langSelect
            />
          )}
          <SquaredCta
            className={cx(css.item, css.cta)}
            onClick={onSubmit}
            disabled={!selectedCountry || !selectedLang}>
            {t(MARKET.BANNER_SUBMIT)}
          </SquaredCta>
        </div>
        <button
          className={css.close}
          aria-label={t(GENERAL.CLOSE)}
          onClick={onClickClose}>
          <CloseIcon className={css.icon} />
        </button>
      </div>
    </div>
  ) : null
}

export default BannerMarket
