import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import AbstractCheckbox, {
  CheckboxProps as AbstractCheckboxProps,
} from '~/components/Abstracts/Form/Checkbox'
import ErrorList from '~/components/Form/ErrorList'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

type DotCheckBoxTheme = GlobalThemeColors.Black

export enum DotCheckboxType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export interface DotCheckboxProps extends AbstractCheckboxProps {
  className?: string
  theme?: DotCheckBoxTheme
  type?: DotCheckboxType
}

function DotCheckbox({ className, theme, type, ...props }: DotCheckboxProps) {
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Label12_14Haffer,
    color: theme,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={cx(css.DotCheckbox, className, css?.[`theme-${theme}`])}>
      <AbstractCheckbox
        className={css.checkbox}
        errorClassname={cx(css.error)}
        inputClassName={css.input}
        contentClassName={css.content}
        checkmarkClassName={cx(css.checkmark)}
        labelClassName={cx(css.label, labelStyle)}
        type={type}
        {...props}
      />
      <ErrorList className={cx(css.errorList)} name={props.name} />
    </div>
  )
}

DotCheckbox.defaultProps = {
  theme: GlobalThemeColors.Black,
  type: DotCheckboxType.CHECKBOX,
}

export default DotCheckbox
