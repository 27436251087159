import React, { ReactNode } from 'react'

import {
  BreadcrumbData,
  BreadcrumbDataProps,
} from '@unlikelystudio/react-abstract-components'

import Link, { LinkProps } from '~/components/UI/Link'

export interface BreadcrumbProps {
  className?: string
  separatorClassName?: string
  itemClassName?: string
  links?: LinkProps[]
  jsonLd?: BreadcrumbDataProps
  customSeparator?: string | ReactNode | JSX.Element
}

export default function Breadcrumb({
  className,
  itemClassName,
  separatorClassName,
  links,
  jsonLd,
  customSeparator,
}: BreadcrumbProps) {
  return (
    <div className={className}>
      {jsonLd && <BreadcrumbData {...jsonLd} />}
      {links?.map((link, index) => (
        <div className={itemClassName} key={index}>
          <Link {...link} />
          {index < links?.length - 1 && (
            <div className={separatorClassName}>
              {customSeparator ? customSeparator : '/'}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

Breadcrumb.defaultProps = {}
