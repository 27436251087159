import { METAFIELDS } from '~/lib/shopify/metafields'

import { UnlikelyMetafields } from '@unlikelystudio/commerce-connector'

import findByProperty from '~/utils/find-by-property'

export function formatCustomerMetafieldsInput(obj) {
  return Object.keys(obj)?.map((key) => {
    const metafieldConfig = findByProperty(
      Object.values(METAFIELDS),
      'key',
      key,
    )

    return {
      ...metafieldConfig,
      value: obj?.[key],
    }
  })
}

export function getMetafieldValue(key, metafields: UnlikelyMetafields) {
  return metafields?.[key?.processedKey]?.value ?? null
}
