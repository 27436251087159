import { useRouter } from 'next/router'
import { useEffect } from 'react'
import linkResolver from '~/lib/link-resolver'
import { PRISMIC_TYPES } from '~/lib/prismic-types'

import { useGeolocation } from '~/providers/GeolocationProvider'

import useLocale from '~/hooks/useLocale'

export default function useGeopositionRedirect() {
  const router = useRouter()
  const locale = useLocale()
  const { countryISO } = useGeolocation()

  const isLegalItalianRecycleQRCode =
    router.asPath === '/how-to-recycle-in-italia'

  useEffect(() => {
    if (
      countryISO !== 'IT' &&
      countryISO !== null &&
      isLegalItalianRecycleQRCode
    ) {
      router.push(linkResolver({ type: PRISMIC_TYPES.HOMEPAGE }, locale))
    }
  }, [countryISO, isLegalItalianRecycleQRCode, locale, router])
}
