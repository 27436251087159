import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useRouter } from 'next/router'
import { useEffect, useState, useTransition } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalBreakpoints } from '~/@types/breakpoints'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import linkResolver from '~/lib/link-resolver'
import { inlineCtaNegativeTheme } from '~/lib/negative-theme-colors'
import { PRISMIC_TYPES } from '~/lib/prismic-types'

import { useBodyScrollLock } from '@unlikelystudio/react-hooks'

import { CloseIcon, SearchIcon } from '~/components/Abstracts/Icons'
import {
  AccountIcon,
  BigArrowLeftIcon,
  BigArrowRightIcon,
  CartIcon,
  MobileMenuIcon,
  MobileStoreLocatorIcon,
} from '~/components/Icons'
import NavigationLogo from '~/components/Navigation/Navigation/commons/NavigationLogo'
import { NavigationProps } from '~/components/Navigation/Navigation/types'
import { trackClickEventForMenuLink } from '~/components/Navigation/Navigation/utils'
import PanelCart from '~/components/Panels/CartPanelWrapped'
import InlineCta from '~/components/UI/InlineCta'
import Link from '~/components/UI/Link'

import { usePanel } from '~/providers/PanelProvider'
import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useGetCountProducts from '~/hooks/cart/useGetCountProducts'
import useBreakpoint from '~/hooks/useBreakpoint'
import useLocale from '~/hooks/useLocale'
import useShop from '~/hooks/useShop'

import getCSSThemeClassName from '~/utils/get-css-theme-classname'

import { GENERAL } from '~/data/dictionary'

import NavigationSubPanel from './SubPanel'
import css from './styles.module.scss'

const cx = classnames.bind(css)

function NavigationMobile({
  className,
  theme,
  allLinks,
  searchLink,
  storeLocatorLink,
  hasScrolled,
  accountLabel,
  searchLabel,
  cartLabel,
  storeLocatorLabel,
}: NavigationProps) {
  const [isPending, startTransition] = useTransition()
  const { tracker } = useTracker()
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const countProducts = useGetCountProducts()

  const [isOpen, setIsOpen] = useState(false)
  const [panelIndex, setPanelIndex] = useState(null)

  const t = useTranslate()

  const isMobile = useBreakpoint(GlobalBreakpoints.LG)

  const router = useRouter()
  const shop = useShop()
  const locale = useLocale()

  const { add: addPanel } = usePanel()

  const computedTheme = hasScrolled || isOpen ? GlobalThemeColors.Black : theme

  const linkStyle = useStyle({
    textPreset: GlobalTextPreset.Title12HafferSemiBold,
    textStyling: GlobalTextStyling.UpperCase,
    color: computedTheme,
  })

  const mainLinkStyle = useStyle({
    textPreset: GlobalTextPreset.Title18HafferSemiBold,
    color: GlobalThemeColors.Black,
  })

  const countStyle = useStyle({
    textPreset: GlobalTextPreset.Label10Haffer,
    textStyling: GlobalTextStyling.UpperCase,
    color: inlineCtaNegativeTheme(computedTheme),
  })

  const openPanel = () => {
    startTransition(() => {
      addPanel({
        component: <PanelCart />,
      })
    })
  }

  useBodyScrollLock(isOpen && isMobile)

  // Listen router to handle route change hide panel
  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false)
      setPanelIndex(null)
    }

    router.events.on('beforeHistoryChange', handleRouteChange)

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange)
    }
  }, [])

  const storeLocator = storeLocatorLink && (
    <Link
      className={cx(css.link, css.topIcon)}
      aria-label={storeLocatorLabel}
      href={linkResolver({ type: PRISMIC_TYPES.STORE_LOCATOR }, locale)}>
      <MobileStoreLocatorIcon
        className={cx(css.icon, css.storeLocator)}
        theme={computedTheme}
      />
    </Link>
  )

  const account = (
    <Link
      className={cx(css.link, css.topIcon)}
      href={linkResolver({ type: PRISMIC_TYPES.ACCOUNT }, locale)}
      aria-label={accountLabel}>
      <AccountIcon className={cx(css.icon)} theme={computedTheme} />
      <span className={linkStyle}>{accountLabel}</span>
    </Link>
  )

  const cart = (
    <button className={cx(css.cartButton)} onClick={openPanel}>
      <span className={css.cartContainer}>
        <span className={linkStyle}>{t(GENERAL.CART)}</span>
        <div className={css.iconContainer}>
          <CartIcon
            className={cx(css.icon, css.topIcon)}
            aria-label={cartLabel}
            theme={computedTheme}
          />
          {countProducts && (
            <span className={cx(css.count, countStyle)}>{countProducts}</span>
          )}
        </div>
      </span>
    </button>
  )

  return (
    <nav
      className={cx(
        css.NavigationMobile,
        className,
        gridStyle,
        getCSSThemeClassName(css, 'theme', computedTheme),
        {
          isOpen,
          hasScrolled,
        },
      )}>
      <div className={css.content}>
        <div className={css.left}>
          {!isOpen && (
            <button
              className={css.open}
              onClick={() => setIsOpen(true)}
              aria-label={t(GENERAL.MENU)}>
              <MobileMenuIcon theme={computedTheme} className={css.open} />
            </button>
          )}
          {isOpen && (
            <button className={css.close} aria-label={t(GENERAL.CLOSE)}>
              <CloseIcon
                className={css.close}
                onClick={() => setIsOpen(false)}
              />
            </button>
          )}
        </div>
        <NavigationLogo className={css.logo} theme={computedTheme} />
        <div className={css.right}>{!shop ? storeLocator : cart}</div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <m.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.15 },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.15 },
            }}
            className={css.panel}>
            <div className={css.panelContent}>
              {panelIndex === null ? (
                allLinks?.map(({ link, text, columns, color }, index) => {
                  return columns?.length === 0 ? (
                    <Link
                      className={cx(css.mainLink, mainLinkStyle)}
                      key={`main_link_${index}`}
                      onClick={() => {
                        trackClickEventForMenuLink(
                          tracker,
                          link?.children ?? '',
                        )
                      }}
                      {...link}
                      style={{ color }}
                    />
                  ) : (
                    <div
                      className={cx(css.mainLink, mainLinkStyle)}
                      key={`main_link_${index}`}
                      onClick={() => setPanelIndex(index)}
                      style={{ color: color }}>
                      {text}
                      <BigArrowRightIcon className={css.icon} />
                    </div>
                  )
                })
              ) : (
                <div className={css.subPanel}>
                  <div
                    className={cx(css.mainLink, mainLinkStyle, { back: true })}
                    onClick={() => setPanelIndex(null)}>
                    <BigArrowLeftIcon className={css.icon} />
                    {allLinks?.[panelIndex]?.text}
                  </div>
                  {allLinks?.[panelIndex] && (
                    <NavigationSubPanel {...allLinks[panelIndex]} />
                  )}
                </div>
              )}
            </div>
            <div className={cx(css.bottomIcons, { hasShop: shop })}>
              {searchLink && (
                <Link
                  className={css.link}
                  href={linkResolver({ type: PRISMIC_TYPES.SEARCH }, locale)}
                  aria-label={searchLabel}>
                  <SearchIcon
                    className={cx(css.icon, css.search)}
                    theme={GlobalThemeColors.Black}
                  />
                  <span className={linkStyle}>{searchLink?.children}</span>
                </Link>
              )}
              {shop && account}
            </div>
          </m.div>
        )}
      </AnimatePresence>
    </nav>
  )
}

NavigationMobile.defaultProps = {}

export default NavigationMobile
