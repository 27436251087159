export enum GlobalThemeColors {
  Black = 'black',
  MineShaft = 'mine-shaft',
  Concrete = 'concrete',
  DoveGray = 'dove-gray',
  Alto = 'alto',
  Gallery = 'gallery',
  Alabaster = 'alabaster',
  White = 'white',
  Pomegranate = 'pomegranate',
  QuickSliver = 'quick-silver',
  Transparent = 'transparent',
}
