import classnames from 'classnames'
import React from 'react'

import css from './styles.module.scss'
import ArrowRight from './svgs/arrow-right.svg'
import ArrowWithBar from './svgs/arrow-with-bar.svg'
import Cart from './svgs/cart.svg'
import Checkbox from './svgs/checkbox.svg'
import Close from './svgs/close.svg'
import ColorPicker from './svgs/color-picker.svg'
import ExitFullscreen from './svgs/exit-fullscreen.svg'
import Fullscreen from './svgs/fullscreen.svg'
import Minus from './svgs/minus.svg'
import Muted from './svgs/muted.svg'
import Pause from './svgs/pause.svg'
import Play from './svgs/play.svg'
import Plus from './svgs/plus.svg'
import Search from './svgs/search.svg'
import Sound from './svgs/sound.svg'

const cx = classnames.bind(css)
export interface IconProps extends React.SVGProps<SVGSVGElement> {
  theme?: string
}

export function iconFromReactSvg<T>(
  Icon: React.FC<React.SVGProps<SVGSVGElement>>,
  style?: string,
): React.FC<T & IconProps> {
  const icon = ({ className, theme, ...props }: T & IconProps) => {
    return (
      <Icon
        className={cx(style, className, css?.[`${theme}Theme`])}
        {...props}
      />
    )
  }
  return icon
}

export const MutedIcon = iconFromReactSvg(Muted)
export const SoundIcon = iconFromReactSvg(Sound)
export const PlayIcon = iconFromReactSvg(Play)
export const PauseIcon = iconFromReactSvg(Pause)
export const ExitFullscreenIcon = iconFromReactSvg(ExitFullscreen)
export const FullscreenIcon = iconFromReactSvg(Fullscreen)

export const CartIcon = iconFromReactSvg(Cart)
export const CloseIcon = iconFromReactSvg(Close, css.CloseIcon)
export const SearchIcon = iconFromReactSvg(Search, css.Search)
export const MinusIcon = iconFromReactSvg(Minus)
export const PlusIcon = iconFromReactSvg(Plus)

export const ArrowLeftWithBarIcon = iconFromReactSvg(
  ArrowWithBar,
  css.ArrowLeftWithBar,
)
export const ArrowRightIcon = iconFromReactSvg(ArrowRight)
export const ArrowLeftIcon = iconFromReactSvg(ArrowRight, css.ArrowLeftIcon)
export const ArrowBottomIcon = iconFromReactSvg(ArrowRight, css.ArrowBottomIcon)
export const ColorPickerIcon = iconFromReactSvg(ColorPicker)
export const CheckboxIcon = iconFromReactSvg(Checkbox)
