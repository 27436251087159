import { useRouter } from 'next/router'
import { useEffect } from 'react'

interface TransitionOptions {
  shallow?: boolean
}

export default function useOnRouteChange(
  onRouteChange: (path: string, transitionOptions: TransitionOptions) => void,
) {
  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = (path, params, ...rest) => {
      onRouteChange(path, params)
    }
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])
}
