import classnames from 'classnames/bind'
import React from 'react'

import { Spinner } from '@unlikelystudio/react-abstract-components'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface LoadingPageProps {
  className?: string
  children?: JSX.Element | JSX.Element[]
}

function LoadingPage({ className, children }: LoadingPageProps) {
  return (
    <section className={cx(css.LoadingPage, className)}>
      {children ?? <Spinner className={css.loader} />}
    </section>
  )
}

LoadingPage.defaultProps = {}

export default LoadingPage
