import { useTranslate } from 'react-polyglot'
import { GlobalInputPreset } from '~/@types/input-preset'
import { METAFIELDS, TMetafieldKey } from '~/lib/shopify/metafields'

import { InputProps } from '~/components/Abstracts/Form/Input'

import { ADDRESS_REGEX, EMAIL_REGEX } from '~/utils/regex'

import { FORM } from '~/data/dictionary'

const dateOffset = 1000 * 60 * 60 * 24 * 365 * 10
const minDate = '1900-01-01'
const maxDate = new Date(Date.now() - dateOffset)
  ?.toISOString()
  ?.split('T')?.[0]

const inputPresets = {
  [GlobalInputPreset.AddressName]: {
    type: 'text',
    required: true,
    name: 'addressName',
    maxLength: 35,
    placeholder: FORM.ADDRESS_NAME,
    validations: null,
  },
  [GlobalInputPreset.City]: {
    type: 'text',
    required: true,
    autoComplete: 'address-level2',
    name: 'city',
    placeholder: FORM.CITY,
    validations: [{ regex: ADDRESS_REGEX, key: 'letters' }],
  },
  [GlobalInputPreset.Email]: {
    type: 'email',
    required: true,
    autoComplete: 'email',
    placeholder: FORM.EMAIL,
    name: 'email',
    validations: [{ regex: EMAIL_REGEX, key: 'email' }],
  },
  [GlobalInputPreset.FirstName]: {
    type: 'text',
    required: true,
    placeholder: FORM.FIRST_NAME,
    name: 'firstName',
    autoComplete: 'given-name',
    validations: null,
  },
  [GlobalInputPreset.LastName]: {
    type: 'text',
    required: true,
    placeholder: FORM.LAST_NAME,
    name: 'lastName',
    autoComplete: 'family-name',
    validations: null,
  },
  [GlobalInputPreset.StreetName]: {
    type: 'text',
    required: true,
    name: 'address1',
    placeholder: FORM.STREET_NAME,
    validations: null,
  },
  [GlobalInputPreset.InfoAddress]: {
    type: 'text',
    required: false,
    name: 'address2',
    placeholder: FORM.INFOS_ADDRESS,
    validations: null,
  },
  [GlobalInputPreset.Region]: {
    type: 'text',
    required: false,
    name: 'region',
    placeholder: FORM.REGION,
    validations: null,
  },
  [GlobalInputPreset.Country]: {
    type: 'text',
    required: false,
    name: 'country',
    placeholder: FORM.COUNTRY,
    validations: null,
  },
  [GlobalInputPreset.State]: {
    type: 'text',
    required: false,
    name: 'state',
    placeholder: FORM.STATE,
    validations: null,
  },
  [GlobalInputPreset.ZipCode]: {
    type: 'text',
    required: true,
    placeholder: FORM.ZIP_CODE,
    name: 'zip',
    autoComplete: 'postal-code',
    validations: null,
  },
  [GlobalInputPreset.Search]: {
    type: 'text',
    required: false,
    placeholder: FORM.SEARCH,
    name: 'search',
    validations: null,
  },
  [GlobalInputPreset.Tel]: {
    type: 'tel',
    required: false,
    placeholder: FORM.TEL,
    name: 'phone',
  },
  [METAFIELDS.CUSTOMER_BIRTHDAY.key]: {
    type: 'date',
    placeholder: FORM.BIRTH_DATE,
    name: METAFIELDS.CUSTOMER_BIRTHDAY.key,
    validations: null,
    min: minDate,
    max: maxDate,
  },
  [GlobalInputPreset.Password]: {
    type: 'password',
    required: true,
    placeholder: FORM.PASSWORD,
    name: 'password',
    minLength: 8,
    validations: null,
  },
  [GlobalInputPreset.NewPassword]: {
    type: 'password',
    required: true,
    placeholder: FORM.NEW_PASSWORD,
    name: 'newPassword',
    minLength: 8,
    validations: null,
  },
  [GlobalInputPreset.RepeatPassword]: {
    type: 'password',
    required: true,
    placeholder: FORM.REPEAT_PASSWORD,
    name: 'repeatPassword',
    minLength: 8,
    validations: null,
  },
  [GlobalInputPreset.RepeatNewPassword]: {
    type: 'password',
    required: true,
    placeholder: FORM.REPEAT_NEW_PASSWORD,
    name: 'repeatNewPassword',
    minLength: 8,
    validations: null,
  },
} as const

export type TInputPresetsKey = GlobalInputPreset | TMetafieldKey

export default function useInputPreset<T extends InputProps>(
  key: TInputPresetsKey,
  args: T,
): Partial<T> {
  const t = useTranslate()
  const currentPreset = inputPresets?.[key]

  const validate =
    currentPreset?.validations?.length > 0 &&
    currentPreset?.validations?.reduce((acc: any, { key, regex }) => {
      acc[key] = (val) => {
        return regex.test(val)
      }
      return acc
    }, {})

  if (!inputPresets[key]) return args

  return {
    ...currentPreset,
    validate,
    placeholder: args?.placeholder || t(currentPreset?.placeholder),
    ...args,
  }
}
