import { NavigationDataProps } from '@unlikelystudio/react-abstract-components'

import getAlternateUrl from '~/utils/alternate-url'

export const serializeJSONLD = (items): NavigationDataProps => {
  const processed = items?.map((item) => {
    const processed =
      item?.columns?.map((column) => {
        return column?.sections?.map((section) => {
          return section?.links
        })
      }) ?? []

    return {
      name: item?.text,
      link: {
        ...item,
        href: getAlternateUrl(item.href),
      },
      items:
        processed?.flat(3)?.map((item) => ({
          name: item?.children,
          link: {
            ...item,
            href: getAlternateUrl(item.href),
          },
        })) ?? null,
    }
  })

  return {
    items: processed,
  }
}
