import { useCallback } from 'react'
import { TRACKING_EVENTS } from '~/lib/constants'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useTracker } from '~/providers/TrackerProvider'

export default function useTrackingPromotionClick(
  insideSlicePosition?: string,
) {
  const { name, isPromotion, promotionPosition } = useSliceProvider()
  const { tracker } = useTracker()

  const handleClick = useCallback(() => {
    tracker.emit(TRACKING_EVENTS.PROMOTION_CLICK, {
      eventLabel: name ?? 'none',
      ecommerce: {
        promoClick: {
          promotions: [
            {
              name: name ?? 'none',
              position:
                `${promotionPosition}${
                  insideSlicePosition ? `_${insideSlicePosition}` : ''
                }` ?? 'none',
              id: `${name?.toLocaleLowerCase()}_${promotionPosition}`,
              creative: 'none',
            },
          ],
        },
      },
    })
  }, [name, tracker])

  return isPromotion ? handleClick : null
}
