import classnames from 'classnames/bind'
import { ALERT_CODES } from '~/@types/alert'
import { METAFIELDS } from '~/lib/shopify/metafields'
import { formatCustomerMetafieldsInput } from '~/lib/shopify/metafields/helpers'

import { Spinner } from '@unlikelystudio/react-abstract-components'
import { UpdateCustomerForm } from '@unlikelystudio/react-ecommerce-hooks'

import FormTemplate from '~/components/Abstracts/FormTemplate'
import { AccountDashboardProps } from '~/components/Account'

import useCustomerErrors from '~/hooks/account/useCustomerErrors'
import useGetCustomerMetafields from '~/hooks/account/useGetCustomerMetafields'
import useSetMetafields from '~/hooks/account/useSetMetafields'
import useAlerts from '~/hooks/useAlerts'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface AccountInformationProps extends AccountDashboardProps {
  className?: string
}

function FormProfile({ className }: AccountInformationProps) {
  const { data: metafields } = useGetCustomerMetafields()

  const onError = useCustomerErrors()
  const triggerAlert = useAlerts()

  const onSuccess = () => {
    triggerAlert(ALERT_CODES.ENTITY_SAVED)
  }

  const { mutate: setMetafields, isLoading } = useSetMetafields({
    onSuccess,
    /**
     * Return onSuccess as the initial update customer was successful
     * If you reach this state
     */
    onError: onSuccess,
  })

  const customerBirthday =
    metafields?.[METAFIELDS.CUSTOMER_BIRTHDAY.processedKey]?.value ?? null

  const customerGender =
    metafields?.[METAFIELDS.CUSTOMER_GENDER.processedKey]?.value ?? null

  return (
    <FormTemplate className={cx(css.form, { isLoading })} isLoading={isLoading}>
      {isLoading && <Spinner className={css.loading} />}
      <UpdateCustomerForm
        defaultValues={{ birthdate: customerBirthday, gender: customerGender }}
        hideFields={[]}
        handleAdditionalFields={({ birthdate, gender }) => {
          if (
            (birthdate && birthdate !== customerBirthday) ||
            (gender && gender !== customerGender)
          ) {
            const metafields = {
              ...(birthdate ? { birthday: birthdate } : {}),
              ...(gender ? { gender } : {}),
            }

            const formattedMetafield = formatCustomerMetafieldsInput(metafields)

            return setMetafields({ metafields: formattedMetafield })
          }
          // If there is no need to upsert metafield, trigger onSuccess for updateCustomer mutation
          onSuccess()
        }}
        mutationOptions={{
          onError: (error) => {
            const { errsGlobals, errsFields } = onError(error)
            const errors = Object.values(errsGlobals)

            if (errors?.length) {
              errors.map(triggerAlert)
            }

            if (!errors?.length && !Object.values(errsFields)?.length) {
              triggerAlert()
            }
          },
        }}
      />
    </FormTemplate>
  )
}

function AccountInformation({ className }: AccountInformationProps) {
  return (
    <div className={cx(css.AccountInformation, className)}>
      <FormProfile className={cx(css.profile)} />
    </div>
  )
}

AccountInformation.defaultProps = {}

export default AccountInformation
