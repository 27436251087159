import React, { createContext, useContext, useMemo } from 'react'

import type { LinkProps } from '~/components/Abstracts/Link'

export const BreadcrumbContext = createContext<BreadcrumbContent>({})

export function useBreadCrumb() {
  return useContext(BreadcrumbContext)
}

export interface BreadcrumbContent {
  links?: LinkProps[]
}

export interface BreadcrumbProviderProps extends BreadcrumbContent {
  children?: JSX.Element | JSX.Element[]
}

export default function BreadCrumbProvider({
  children,
  links,
}: BreadcrumbProviderProps) {
  const memoized = useMemo(
    () => ({
      links,
    }),
    [links],
  )

  return (
    <BreadcrumbContext.Provider value={memoized}>
      {children}
    </BreadcrumbContext.Provider>
  )
}
