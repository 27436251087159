import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { SelectProps } from '~/components/Abstracts/Form/Select'
import MockSelect from '~/components/Abstracts/Form/Select/mock'
import { InlineCtaProps } from '~/components/UI/InlineCta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface SelectNavigationProps extends SelectProps {
  className?: string
  links?: InlineCtaProps[]
}

function SelectNavigation({ className, links }: SelectNavigationProps) {
  const router = useRouter()
  const selectStyle = useStyle({
    textPreset: GlobalTextPreset.Title18HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const onChange = (e) => {
    router.push(e.target.value)
  }

  const options =
    links?.length > 0
      ? links?.map(({ children, href }) => {
          return {
            label: children as string,
            value: href,
          }
        })
      : []

  const defaultValue = links?.find(({ active }) => active)?.href as string

  return links?.length > 0 ? (
    <MockSelect
      className={cx(css.SelectNavigation, className)}
      selectClassName={cx(css.select, selectStyle)}
      iconClassName={css.icon}
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
    />
  ) : null
}

SelectNavigation.defaultProps = {}

export default SelectNavigation
