import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { UseFormReturn } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'
import { GlobalBreakpoints } from '~/@types/breakpoints'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import type { FacetsResult } from '~/lib/algolia/fetch-facets'

import { iconFromReactSvg } from '~/components/Abstracts/Icons'
import { DotCheckboxType } from '~/components/Form/DotCheckbox'
import { FiltersPanelAccordion } from '~/components/UI/FiltersPanelAccordion'
import { FiltersPanelList } from '~/components/UI/FiltersPanelList'

import {
  FilterPanelTypes,
  FilterResultType,
  useFilter,
} from '~/providers/FilterProvider'
import { useStyle } from '~/providers/StyleProvider'

import useBreakpoint from '~/hooks/useBreakpoint'
import useShop from '~/hooks/useShop'

import { FILTER } from '~/data/dictionary'

import ArrowRight from './arrow-bottom.svg'
import { ORDERING_DATA } from './data'
import css from './styles.module.scss'

const cx = classnames.bind(css)

export const ArrowBottomIcon = iconFromReactSvg(ArrowRight)
export interface FiltersProps {
  className?: string
  count?: number
}

export interface Filter {
  value?: string
  key?: string
}

export interface FilterColumn extends Omit<FacetsResult, 'values'> {
  inputType?: DotCheckboxType
  values?: {
    name: string
    label?: string
    available: boolean
  }[]
}

export interface FilterPanelProps {
  className?: string
  filtersColumns?: FilterColumn[]
  filters?: FilterResultType
  setFilters?(filters: FilterResultType): void
  panel?: FilterPanelTypes
  setPanel?(panel: FilterPanelTypes): void
  isMobile?: boolean
}

export function PanelFilter(props: FilterPanelProps) {
  // Reset all form values
  const handleReset = (form: UseFormReturn) => {
    const values = form.getValues()

    form.reset(
      Object.keys(values)?.reduce((acc, field) => {
        return {
          ...acc,
          [field]: null,
        }
      }, {}),
    )
  }

  return (
    <>
      {!props?.isMobile && (
        <FiltersPanelList
          className={css.hideOnSmallScreen}
          handleReset={handleReset}
          {...props}
        />
      )}
      {props?.isMobile && (
        <FiltersPanelAccordion
          className={css.hideOnLargeScreen}
          handleReset={handleReset}
          {...props}
        />
      )}
    </>
  )
}

function Filters({ className, count }: FiltersProps) {
  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })

  const filterStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12_14Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const t = useTranslate()
  const isMobile = useBreakpoint(GlobalBreakpoints.LG)

  const { panel, setPanel, sortBy, setSortBy, filters, setFilters, facets } =
    useFilter()

  const isShop = useShop()

  const panelComponent = (
    <>
      {panel === FilterPanelTypes.SORT_BY && (
        <PanelFilter
          filters={sortBy}
          setFilters={setSortBy}
          panel={panel}
          setPanel={setPanel}
          filtersColumns={ORDERING_DATA(isShop)?.map((entry) => ({
            ...entry,
            values: entry?.values?.map((value) => ({
              ...value,
              label: t(FILTER.FACET(value?.name)),
            })),
          }))}
          isMobile={isMobile}
        />
      )}
      {panel === FilterPanelTypes.FILTER_BY && (
        <PanelFilter
          filters={filters}
          setFilters={setFilters}
          panel={panel}
          setPanel={setPanel}
          filtersColumns={facets}
          isMobile={isMobile}
        />
      )}
    </>
  )

  return (
    <>
      {/* Black opaque background */}
      {!isMobile && (
        <AnimatePresence>
          {panel && (
            <m.div
              className={css.background}
              onClick={() => setPanel?.(null)}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.4 },
              }}
              exit={{
                opacity: 0,
                transition: { duration: 0.4 },
              }}
            />
          )}
        </AnimatePresence>
      )}

      <div className={cx(css.Filters, className)}>
        <div className={cx(css.top, gridStyle)}>
          <div className={css.content}>
            <div className={cx(css.left)}>
              <button
                className={cx(css.filterItem, filterStyle, {
                  isActive:
                    panel === null || panel === FilterPanelTypes.SORT_BY,
                })}
                onClick={() => {
                  setPanel(
                    panel === FilterPanelTypes.SORT_BY
                      ? null
                      : FilterPanelTypes.SORT_BY,
                  )
                }}>
                {t(FILTER.SORT_BY)}
                <ArrowBottomIcon
                  className={cx(css.arrowBottom, {
                    isActive: panel === FilterPanelTypes.SORT_BY,
                  })}
                />
              </button>
              <button
                className={cx(css.filterItem, filterStyle, {
                  isActive:
                    panel === null || panel === FilterPanelTypes.FILTER_BY,
                })}
                onClick={() => {
                  setPanel?.(
                    panel === FilterPanelTypes.FILTER_BY
                      ? null
                      : FilterPanelTypes.FILTER_BY,
                  )
                }}>
                {t(FILTER.FILTER_BY)}
                <ArrowBottomIcon
                  className={cx(css.arrowBottom, {
                    isActive: panel === FilterPanelTypes.FILTER_BY,
                  })}
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={css.panelContainer}>
        {/* White global panel background */}
        {!isMobile && (
          <AnimatePresence>
            {panel && (
              <m.div
                className={css.backgroundPanel}
                initial={{ scaleY: 0 }}
                animate={{
                  scaleY: 1,
                  transition: {
                    duration: 0.4,
                    ease: [0.195, 0.59, 0.435, 0.825],
                  },
                }}
                exit={{
                  scaleY: 0,
                  transition: {
                    duration: 0.3,
                    delay: 0.1,
                    ease: [0.46, 0.255, 0.74, 0.48],
                  },
                }}
              />
            )}
          </AnimatePresence>
        )}

        {/* Container with opacity animation for panel */}
        {isMobile ? (
          <div className={css.panel}>{panelComponent}</div>
        ) : (
          <AnimatePresence>
            {panel && (
              <m.div
                className={css.panel}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.3, delay: 0.25 },
                }}
                exit={{
                  opacity: [1, 0, 0],
                  transition: {
                    duration: 0.4,
                    times: [0, 0.4, 1],
                  },
                }}>
                {panelComponent}
              </m.div>
            )}
          </AnimatePresence>
        )}
      </div>
    </>
  )
}

Filters.defaultProps = {}

export default Filters
