import { renderRichText, asText } from './rich-text'
import { Elements } from './types'

const createHtmlSerializer = (bucket = {}, serializers = []) => {
  const processors = serializers.reduce((acc, { type, fn }) => {
    return Object.assign({}, acc, { [type]: fn })
  }, {})
  return (type, ...args) =>
    processors[type] ? processors[type](type, ...args) : null
}

const RichText = ({
  Component,
  elements,
  htmlSerializer,
  linkResolver,
  render,
  renderAsText,
  serializeHyperlink,
  ...rest
}) => {
  const maybeSerializer =
    htmlSerializer ||
    (serializeHyperlink &&
      createHtmlSerializer({}, [
        {
          type: Elements.hyperlink,
          fn: serializeHyperlink,
        },
      ]))

  return render
    ? renderRichText(
        render,
        linkResolver,
        maybeSerializer,
        Component,
        elements,
        rest,
      )
    : null
}

RichText.asText = asText
RichText.render = renderRichText
RichText.displayName = 'RichText'

export default RichText
