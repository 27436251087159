import classnames from 'classnames/bind'
import { useCallback, useEffect, useRef, useTransition } from 'react'
import { FormProvider, UseFormReturn } from 'react-hook-form'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { Spinner } from '@unlikelystudio/react-abstract-components'

import { FilterPanelProps } from '~/components/UI/Filters'
import Filter from '~/components/UI/FiltersPanelList/Filter'
import InlineCta from '~/components/UI/InlineCta'
import SquaredCta from '~/components/UI/SquaredCta'

import { useStyle } from '~/providers/StyleProvider'

import useFormWithMutation from '~/hooks/useFormWithMutation'

import deepEqual from '~/utils/deep-equal'

import { FILTER } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface FiltersPanelListProps extends FilterPanelProps {
  withClear?: boolean
  handleReset(form: UseFormReturn): void
}

export function FiltersPanelList({
  className,
  filters,
  setFilters,
  setPanel,
  withClear = true,
  handleReset,
  ...rest
}: FiltersPanelListProps) {
  const [pendingValidation, startTransitionValidation] = useTransition()

  const clearStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12_14HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const t = useTranslate()

  const handleSubmit = useCallback((props) => {
    // Update filters if deep comparison is different
    if (!deepEqual(filters, props)) {
      setFilters(props)
    }

    setPanel(null)
  }, [])

  const { form, onSubmit } = useFormWithMutation(handleSubmit)

  const { watch } = form

  // Watch form to get the latest user selection
  const currentFieldsSelectedByUser = watch()
  // Keep a ref of the current user selection to set filters on panel close
  const filtersToSetOnPanelClose = useRef(filters)

  useEffect(() => {
    // Update the user selection ref every time they change a field
    filtersToSetOnPanelClose.current = currentFieldsSelectedByUser
  }, [currentFieldsSelectedByUser])

  useEffect(() => {
    // Update filters on mount
    form.reset(filters)

    return () => {
      /**
       * Only set the filters provider when the panel is closing as this indicated the user selection is complete
       * This cleanup function needs to stay dependanceless useEffect to prevent it from being executing each time a dep changes
       */
      if (!deepEqual(filtersToSetOnPanelClose.current, filters)) {
        setFilters?.(filtersToSetOnPanelClose.current)
      }
    }
  }, [])

  return (
    <div className={cx(className, css.FiltersPanelList)}>
      <div className={cx(css.popUpContainer)}>
        <FormProvider {...form}>
          <form
            className={css.popUpSubContent}
            onSubmit={() => {
              startTransitionValidation(() => onSubmit())
            }}>
            <Filter {...rest} />
            <div className={css.bottomButtons}>
              {withClear && (
                <InlineCta
                  type="button"
                  href={null}
                  onClick={() => handleReset(form)}
                  className={cx(css.clear, clearStyle, css.button)}>
                  {t(FILTER.CLEAR_ALL)}
                </InlineCta>
              )}
              <SquaredCta type="submit" className={cx(css.button)}>
                {t(FILTER.VALIDATE)}{' '}
                {pendingValidation && (
                  <Spinner className={css.spinner} color="white" />
                )}
              </SquaredCta>
              {withClear && (
                <InlineCta
                  type="button"
                  href={null}
                  onClick={() => handleReset(form)}
                  className={cx(clearStyle, css.hidden)}>
                  {t(FILTER.CLEAR_ALL)}
                </InlineCta>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  )
}
