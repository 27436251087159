import classnames from 'classnames/bind'
import { ReactChildren } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { TRACKING_EVENTS } from '~/lib/constants'
import { PRISMIC_TYPES } from '~/lib/prismic-types'

import {
  Customer,
  useGetCustomer,
  useLogout,
} from '@unlikelystudio/react-ecommerce-hooks'

import RichText, { RichTextBlocks } from '~/components/Abstracts/RichText'
import AccountInformation from '~/components/Account/AccountInformation'
import AddressBook from '~/components/Account/AddressBook'
import OrdersGrid from '~/components/Account/OrdersGrid'
import { LogoutIcon } from '~/components/Icons'
import DashboardNavigation, {
  DashboardNavigationProps,
} from '~/components/Navigation/DashboardNavigation'
import HeroSmall, { HeroSmallProps } from '~/components/Slices/HeroSmall'

import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useSingleRoutesRedirect from '~/hooks/useSingleRoutesRedirect'

import { ACCOUNT } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

interface LogOutButtonProps {
  className?: string
  children?: ReactChildren
}

function LogOutButton({ className, children }: LogOutButtonProps) {
  const t = useTranslate()
  const redirect = useSingleRoutesRedirect()
  const { tracker } = useTracker()

  const handleLogout = useLogout({
    onLogout: () => {
      redirect(PRISMIC_TYPES.LOGIN)
      tracker.emit(TRACKING_EVENTS.LOGOUT)
    },
  })
  const logoutStyle = useStyle({
    textPreset: GlobalTextPreset.Cta12_14Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <button
      className={cx(css.LogOutButton, className, logoutStyle)}
      onClick={handleLogout}
      aria-label={t(ACCOUNT.LOGOUT)}>
      {children ?? t(ACCOUNT.LOGOUT)}
      <LogoutIcon className={cx(css.icon)} />
    </button>
  )
}

export enum AccountSections {
  Orders = 'orders',
  AccountInformations = 'account_informations',
  AddressBook = 'address_book',
  ReturnProduct = 'return_product',
}

const mapSectionToDashboard = {
  [AccountSections.Orders]: OrdersGrid,
  [AccountSections.AccountInformations]: AccountInformation,
  [AccountSections.AddressBook]: AddressBook,
  // TODO : Handle return product section -> wait for next move
  [AccountSections.ReturnProduct]: () => null,
}

export interface AccountDashboardProps {
  customer?: Customer
}
export interface AccountProps {
  hero: HeroSmallProps
  dashboard: DashboardNavigationProps
  currentDashboardSection: AccountSections
  title: RichTextBlocks
}

function Account({
  hero,
  dashboard,
  currentDashboardSection,
  title,
}: AccountProps) {
  const t = useTranslate()

  const { data: customer } = useGetCustomer({
    refetchOnMount: false,
    keepPreviousData: true,
    retry: 0,
  })

  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Title30_35HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const Dashboard = mapSectionToDashboard[currentDashboardSection]

  const { firstName, lastName } = customer ?? {}
  const displayName = `${firstName ?? ''} ${lastName ?? ''}`

  return (
    <div className={cx(css.Account)}>
      <HeroSmall
        isFirstSlice={true}
        isAccount
        name={
          <span className={cx(css.heroName)}>
            {`${t(ACCOUNT.HELLO)} ${displayName ?? ''}`}
          </span>
        }
        {...hero}>
        <LogOutButton />
      </HeroSmall>
      {dashboard && (
        <DashboardNavigation {...dashboard}>
          <div className={cx(css.container, css?.[currentDashboardSection])}>
            <RichText className={cx(css.title, titleStyle)} render={title} />
            <Dashboard customer={customer} />
          </div>
        </DashboardNavigation>
      )}
    </div>
  )
}

export default Account
