import Cookies from 'js-cookie'
import dynamic from 'next/dynamic'
import React, { useCallback, useRef, useState, useTransition } from 'react'
import { COOKIE_KEYS } from '~/lib/constants'
import { FORBIDDEN_COUNTRIES_NEWSLETTER } from '~/lib/constants'

import LoadingComponent from '~/components/Abstracts/LoadingComponent'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { PopInOptions, usePopIn } from '~/providers/PopInProvider'

import useIsCountryFromIsoCode from '~/hooks/useCountryFromIsoCode'
import useLocale from '~/hooks/useLocale'

const PopInNewsletter = dynamic(
  () => import('~/components/UI/PopInNewsletter'),
  {
    loading: () => <LoadingComponent />,
  },
)

/**
 * Get the number of attempts to show the cookie pop-in
 * @param locale - The locale of the user.
 * @returns The number of attempts to show the cookie popup.
 */
export function getCookiesAttempts(locale: string) {
  const countAttemptsCookie = Cookies.get(
    COOKIE_KEYS.newsletterPopInAttempts(locale),
  )
  return countAttemptsCookie ? parseInt(countAttemptsCookie) : 0
}

/**
 * It sets the number of attempts to show the newsletter pop-in.
 * @param locale - The locale you want to set the cookie for.
 */
export function setCookiesAttempts(locale: string) {
  const attempts = getCookiesAttempts(locale)
  if (attempts < MAX_ATTEMPTS)
    Cookies.set(
      COOKIE_KEYS.newsletterPopInAttempts(locale),
      `${attempts + 1}`,
      { expires: 30 },
    )
}

export function useIsAllowedToShowNewsletter() {
  const locale = useLocale()
  const isForbiddenCountry = useIsCountryFromIsoCode(
    FORBIDDEN_COUNTRIES_NEWSLETTER,
  )

  if (isForbiddenCountry === null || isForbiddenCountry === true) return false

  const hasCookie = Cookies.get(COOKIE_KEYS.newsletterPopIn(locale)) === '1'

  return !hasCookie && getCookiesAttempts(locale) < MAX_ATTEMPTS
}

// Set the max iterations we show the popin newsetter before stop it
const MAX_ATTEMPTS = 1

/**
 * It shows the newsletter pop-in if it's not shown yet and if the user has not tried to close it yet
 * @param [props] - Partial<PopInOptions>
 * @returns A function that will show the pop in if it is not already shown.
 */
function useNewsletterPopIn(props?: Partial<PopInOptions>) {
  const locale = useLocale()
  const [isPending, startTransition] = useTransition()
  const { add, remove } = usePopIn()
  const { global } = useGlobalData()
  const popInRef = useRef(null)
  const [hasNewsletter, setHasNewsletter] = useState(false)
  const isAllowed = useIsAllowedToShowNewsletter()

  const onHide = useCallback(() => {
    setCookiesAttempts(locale)
  }, [locale])

  const onSuccess = useCallback(() => {
    if (popInRef?.current) {
      setCookiesAttempts(locale)
      remove(popInRef?.current)
      setHasNewsletter(false)
    }
  }, [popInRef, remove])

  const showPopIn = useCallback(() => {
    const popIn = (
      <PopInNewsletter
        {...global?.newsletter}
        onSuccess={onSuccess}
        onHide={onHide}
      />
    )
    popInRef.current = popIn

    startTransition(() => {
      add(popIn, props ?? null)
    })

    setHasNewsletter(true)
  }, [add, global?.newsletter, onHide, onSuccess, props])

  const tryShowPopIn = useCallback(() => {
    if (!hasNewsletter && isAllowed) showPopIn()
  }, [hasNewsletter, locale, showPopIn])

  return [tryShowPopIn]
}

export default useNewsletterPopIn
