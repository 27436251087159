import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { LazyMotion } from 'framer-motion'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { I18n } from 'react-polyglot'
import processEnvSystem from 'unlikely-env/system'
import { DEFAULT_COUNTRY_CODE, RECAPTCHA_PUBLIC_API_KEY } from '~/lib/constants'
import { getStorePublicCredentials } from '~/lib/shopify/public-client'

import {
  ShopifyCurrencyCode,
  ShopifySFCountryCode,
  ShopifySFLanguageCode,
} from '@unlikelystudio/commerce-connector'
import { AlertProvider } from '@unlikelystudio/react-abstract-components'
import {
  AnalyticsProvider,
  CartProvider,
  CustomerProvider,
  StoreProvider,
} from '@unlikelystudio/react-ecommerce-hooks'

import BannerProvider from '~/providers/BannerProvider'
import BreadcrumbProvider from '~/providers/BreadcrumbProvider'
import ClickOutsideProvider from '~/providers/ClickOutsideProvider'
import CssVariablesProvider from '~/providers/CssVariablesProvider'
import GeolocationProvider from '~/providers/GeolocationProvider'
import GlobalDataProvider from '~/providers/GlobalDataProvider'
import MarketProvider from '~/providers/MarketProvider'
import NavigationProvider from '~/providers/NavigationProvider'
import PanelProvider from '~/providers/PanelProvider'
import PopInProvider from '~/providers/PopInProvider'
import PromotionComponentsProvider from '~/providers/PromotionComponentsProvider'
import { GoogleReCaptchaProvider } from '~/providers/RecaptchaProvider/google-recaptcha-provider'
import SettingsProvider from '~/providers/SettingsProvider'
import { ShopifyAnalyticsWrapper as ShopifyAnalytics } from '~/providers/ShopifyAnalytics'
import StyleProvider from '~/providers/StyleProvider'
import ThemeProvider from '~/providers/ThemeProvider'
import TrackerProvider from '~/providers/TrackerProvider'

import useCurrency from '~/hooks/useCurrency'
import useMarketCookie from '~/hooks/useMarketCookie'

import { getCountryCode, getLanguage } from '~/utils/locales'

import { DefaultPageData } from '~/data/page-data/serializer'

import gridPresets from '~/styles/grid-presets/styles.module.scss'
import ratioPresets from '~/styles/ratio-presets/styles.module.scss'
import textPresets from '~/styles/text-presets/styles.module.scss'
import stylingPresets from '~/styles/text-presets/styling.module.scss'
import backgroundColors from '~/styles/theme-classes/background-color.module.scss'
import borderColors from '~/styles/theme-classes/border-color.module.scss'
import colors from '~/styles/theme-classes/colors.module.scss'
import themePresets from '~/styles/theme-presets/styles.module.scss'

const motionAsyncFeatures = () =>
  import('~/utils/motion-features').then((res) => res.default)

export interface AppProviderProps {
  children: JSX.Element | JSX.Element[]
  pageProps: DefaultPageData
}

export const stylePresets = {
  textPreset: textPresets,
  textStyling: stylingPresets,
  color: colors,
  grid: gridPresets,
  ratio: ratioPresets,
  backgroundColor: backgroundColors,
  borderColor: borderColors,
} as const

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

interface ReCaptchaProps {
  nonce?: string
  defer?: boolean
  async?: boolean
  appendTo?: 'head' | 'body'
  id?: string
}

function AppProvider({ children, pageProps }: AppProviderProps) {
  const { locale } = useRouter()
  const { getMarketBannerUserPreferences, getMarketPanelDisplayed } =
    useMarketCookie()

  const memoizedGlobalProviderData = useMemo(
    () => ({
      global: pageProps?.global ?? null,
      metas: pageProps?.metas ?? null,
      document: pageProps?.document ?? null,
    }),
    [pageProps?.global, pageProps?.metas, pageProps?.document],
  )

  const cssVariables = useMemo(
    () => ({ 'scroll-bar': '0px', 'scroll-bar-grid': '0px', vh100: '100vh' }),
    [],
  )

  const navigationValues = useMemo(
    () => ({
      productHeaderVisible: false,
    }),
    [],
  )

  const pageSlices = useMemo(
    () => ({
      slices: pageProps?.slices ?? [],
    }),
    [pageProps],
  )

  const recaptachaProps: ReCaptchaProps = useMemo(
    () => ({
      async: true,
      defer: true,
      appendTo: 'body',
    }),
    [],
  )

  const storeData = getStorePublicCredentials()

  const userHasAlreadySeenMarketPanel = !getMarketPanelDisplayed()
  const marketUserPreferences = getMarketBannerUserPreferences()

  let countryCode = getCountryCode(locale) ?? DEFAULT_COUNTRY_CODE

  if (
    userHasAlreadySeenMarketPanel &&
    marketUserPreferences &&
    locale === marketUserPreferences?.locale
  ) {
    countryCode = marketUserPreferences?.countryCode
  }

  const currency = useCurrency()
  const lang = getLanguage(locale)?.toUpperCase() as ShopifySFLanguageCode

  return (
    <QueryClientProvider client={queryClient}>
      <GeolocationProvider>
        <LazyMotion strict features={motionAsyncFeatures}>
          <GoogleReCaptchaProvider
            reCaptchaKey={RECAPTCHA_PUBLIC_API_KEY}
            scriptProps={recaptachaProps}>
            <MarketProvider>
              <StoreProvider
                country={countryCode.toUpperCase() as ShopifySFCountryCode}
                language={lang.toUpperCase() as ShopifySFLanguageCode}
                currency={currency.toUpperCase() as ShopifyCurrencyCode}
                storefrontAccessToken={storeData?.storefrontAccessToken}
                storeUrl={storeData?.storeUrl}
                locale={locale}>
                <AnalyticsProvider
                  filtersWhiteList={[]}
                  debug={processEnvSystem('VERCEL_ENV') !== 'production'}>
                  <ShopifyAnalytics />
                  <CartProvider>
                    <SettingsProvider
                      settings={memoizedGlobalProviderData?.global?.settings}>
                      <CustomerProvider>
                        <PromotionComponentsProvider
                          slices={pageSlices?.slices ?? null}>
                          <TrackerProvider
                            pageTitle={
                              memoizedGlobalProviderData?.metas?.title ?? ''
                            }>
                            <I18n
                              locale={locale}
                              messages={pageProps?.global?.dictionary ?? {}}
                              allowMissing={true}>
                              <GlobalDataProvider
                                value={memoizedGlobalProviderData}>
                                <StyleProvider value={stylePresets}>
                                  <CssVariablesProvider value={cssVariables}>
                                    <ThemeProvider value={themePresets}>
                                      <AlertProvider>
                                        <PanelProvider
                                          data={
                                            pageProps?.global?.panels ?? null
                                          }>
                                          <PopInProvider>
                                            <BreadcrumbProvider
                                              links={
                                                pageProps?.breadcrumb ?? null
                                              }>
                                              <NavigationProvider
                                                value={navigationValues}>
                                                <BannerProvider>
                                                  <ClickOutsideProvider>
                                                    {children}
                                                  </ClickOutsideProvider>
                                                </BannerProvider>
                                              </NavigationProvider>
                                            </BreadcrumbProvider>
                                          </PopInProvider>
                                        </PanelProvider>
                                      </AlertProvider>
                                    </ThemeProvider>
                                  </CssVariablesProvider>
                                </StyleProvider>
                              </GlobalDataProvider>
                            </I18n>
                          </TrackerProvider>
                        </PromotionComponentsProvider>
                      </CustomerProvider>
                    </SettingsProvider>
                  </CartProvider>
                </AnalyticsProvider>
              </StoreProvider>
            </MarketProvider>
          </GoogleReCaptchaProvider>
        </LazyMotion>
      </GeolocationProvider>
    </QueryClientProvider>
  )
}

AppProvider.defaultProps = {}

export default AppProvider
