import classnames from 'classnames/bind'
import React from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalGridPreset } from '~/@types/grid-preset'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import Image, { ImageProps } from '~/components/UI/Image'

import { useSliceProvider } from '~/providers/SliceProvider'
import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZE = [{ ratio: 24 / 24 }]

export interface HeroSmallProps {
  className?: string
  image?: ImageProps
  name?: JSX.Element | string
  isAccount?: boolean
  children?: JSX.Element | JSX.Element[]
  isFirstSlice?: boolean
}

function HeroSmall({
  className,
  image,
  name,
  children,
  isAccount,
  isFirstSlice,
}: HeroSmallProps) {
  // Getting the slice position to set the correct priority
  const { position: slicePosition } = useSliceProvider()

  const gridStyle = useStyle({ grid: GlobalGridPreset.BASE_GRID })
  const nameStyle = useStyle({
    textPreset: GlobalTextPreset.Title18_35HafferSemiBold,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  return (
    <div className={cx(css.HeroSmall, className, gridStyle, { isAccount })}>
      {image && (
        <Image
          className={cx(css.image)}
          ratio={css.ratio}
          sizesFromBreakpoints={IMAGE_SIZE}
          layout="fill"
          objectFit="cover"
          asPlaceholder
          priority={
            isFirstSlice ? true : slicePosition ? slicePosition === 1 : false
          }
          {...image}
        />
      )}

      <div className={cx(css.content)}>
        {name && <p className={cx(css.name, nameStyle)}>{name}</p>}
        <div className={css.children}>{children}</div>
      </div>
    </div>
  )
}

HeroSmall.defaultProps = {}

export default HeroSmall
