import { decode } from 'qss'

import { stringIsNotEmpty } from '~/utils/check-empty-string'

import canUseDom from './can-use-dom'

export type QueryParams = Record<string, string>

export function getStringifiedQueryParam(param: string | string[]): string {
  return typeof param === 'object' ? param[0] : param
}

export function nonEmptyQueryParam(param: string | string[]): boolean {
  const stringified = getStringifiedQueryParam(param)
  return stringIsNotEmpty(stringified)
}

export function subStringSearch(search: string) {
  return search.substring(1)
}

/**
 * It takes a string, and returns an object with the query params
 * @param {string} [location] - The location to get the query params from. If not provided, it will use
 * the current window location.
 * @returns A partial of the type T.
 */
export function getQueryParams<T extends QueryParams>(
  location?: string,
): Partial<T> {
  if (!location && !canUseDom) return {}
  if (location) return decode(location)
  else return decode(subStringSearch(window.location.search))
}

export function getQueryParamsFromCurrentLocation(): QueryParams {
  const search = location.search.substring(1)

  return search ? getQueryParams(search) : {}
}
