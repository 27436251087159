import { useQuery } from '@tanstack/react-query'
import fetchAlgoliaFacets from '~/lib/algolia/fetch-facets'

import useLocale from '~/hooks/useLocale'

import getAlgoliaQueryString from '~/utils/get-algolia-query-string'

export default function useFetchAlgoliaFacets(defaultFilters) {
  const locale = useLocale()
  const queryString = defaultFilters
    ? getAlgoliaQueryString(defaultFilters)
    : ''

  const queryKeys = ['fetch_facets', queryString]

  return useQuery(
    queryKeys,
    async () => {
      return await fetchAlgoliaFacets({ locale, facets: defaultFilters })
    },
    { refetchOnMount: false, refetchOnWindowFocus: false, enabled: false },
  )
}
