import { TRACKING_EVENTS } from '~/lib/constants'

export function trackClickEventForMenuLink(tracker, text: any) {
  tracker.emit(TRACKING_EVENTS.TAGGED_EVENT, {
    eventName: 'menuHeaderLinks',
    eventAction: 'header',
    eventCategory: 'menu',
    eventLabel: text?.replaceAll("'", ' '),
  })
}

export function trackClickEventForImageItem(tracker, text: any) {
  tracker.emit(TRACKING_EVENTS.MENU_HEADER_LINKS, {
    eventLabel: text,
  })
}
