import { useRouter } from 'next/router'
import linkResolver from '~/lib/link-resolver'

import useLocale from '~/hooks/useLocale'

function useSingleRoutesRedirect(): (type: string) => void {
  const router = useRouter()
  const locale = useLocale()

  const redirect = async (type: string) => {
    const url = linkResolver(
      {
        type,
        uid: null,
      },
      locale,
    )

    router.push(url)
  }

  return redirect
}

export default useSingleRoutesRedirect
