import { DEFAULT_LOCALE } from '~/lib/constants'

import { getIntlLocale } from '~/utils/locales'

export default function serializePrice(
  locale: string = DEFAULT_LOCALE,
  currencyCode: string,
  amount: number | string,
): string {
  if (!locale || !currencyCode || !amount) return null

  const intl = getIntlLocale(locale)
  const processedAmount =
    typeof amount === 'string' ? parseFloat(amount) : amount

  return (
    new Intl.NumberFormat(intl, {
      style: 'currency',
      currency: currencyCode,
      useGrouping: true,
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    })
      .format(processedAmount)
      // Remove unbreakable space
      .replace(/\u00a0/g, '')
  )
}
