import { useIsomorphicLayoutEffect } from '@unlikelystudio/react-hooks'

import { useCssVariables } from '~/providers/CssVariablesProvider'

import use100vh from '~/hooks/use100vh'
import useFixScrollbarOverflow from '~/hooks/useFixScrollbarOverflow'

import isIOS from '~/utils/is-ios'

import css from './styles.module.scss'

export interface FixCssProps {
  className?: string
  children?: JSX.Element | JSX.Element[]
}

function FixCss({ children }: FixCssProps) {
  useFixScrollbarOverflow({ maxGridWidth: parseInt(css?.maxGridWidth) })
  use100vh()

  useIsomorphicLayoutEffect(() => {
    const body = document.querySelector('body')
    body.classList.toggle('ios', isIOS)
  }, [isIOS])

  const { variables: cssVariables } = useCssVariables()

  useIsomorphicLayoutEffect(() => {
    const body = document.querySelector('html')
    const reduced = Object.keys(cssVariables).reduce(
      (prev, key) => prev + `--${key} : ${cssVariables[key]}; `,
      '',
    )
    body.setAttribute('style', reduced)
  }, [{ ...cssVariables }])

  return null
}

export default FixCss
