import { useForm } from 'react-hook-form'
import { ALERT_CODES } from '~/@types/alert'
import {
  GOOGLE_RECAPTCHA_PARAMS,
  requestNextApiEndpoint,
} from '~/lib/handle-api'

import { useGoogleReCaptcha } from '~/providers/RecaptchaProvider/use-google-recaptcha'

import useAlerts from '~/hooks/useAlerts'

import { isVercelProduction } from '~/utils/vercel-env'

export default function useFormWithMutation(
  mutation?: any,
  formOptions?: any,
  action?: string,
  onInvalid?: (errors) => void,
) {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const triggerAlert = useAlerts()

  const form = useForm({
    ...formOptions,
  })

  const submitHandler = form.handleSubmit(async (data) => {
    let handleCaptcha

    if (action && isVercelProduction) {
      try {
        const token = await executeRecaptcha(action)
        handleCaptcha = await requestNextApiEndpoint(GOOGLE_RECAPTCHA_PARAMS, {
          payload:
            action === 'newsletter' && data?.email
              ? { token, action, email: data.email }
              : { token, action },
        })
        if (handleCaptcha?.success) mutation(data)
        else triggerAlert(ALERT_CODES.GOOGLE_RECAPTCHA_ERROR)
      } catch (err) {
        console.error('Recaptcha Error', err)
        triggerAlert(ALERT_CODES.GOOGLE_RECAPTCHA_ERROR)
      }
    } else {
      mutation?.(data)
    }
  }, onInvalid)

  return { form, onSubmit: submitHandler }
}
