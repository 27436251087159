import { useEffect, useState } from 'react'

declare global {
  interface Window {
    OneTrust: {
      OnConsentChanged: (e) => void
    }
    OnetrustActiveGroups: string[]
  }
}

type IConsentChangedEvent = {
  detail: string[]
}

export function useCookiesConsentDone() {
  const [cookiesConsentDone, setCookiesConsentDone] = useState(false)
  // https://my.onetrust.com/s/article/UUID-8102e851-d860-d465-d8d6-b1d636d68eb9?language=en_US
  const PERFORMANCE_COOKIE = 'C0002'

  useEffect(() => {
    //check if the cookie is already accepted
    if (window?.OnetrustActiveGroups?.includes(PERFORMANCE_COOKIE)) {
      setCookiesConsentDone(true)
    }

    //check if the cookie is changed
    window?.OneTrust?.OnConsentChanged((e: IConsentChangedEvent) => {
      if (e?.detail?.includes(PERFORMANCE_COOKIE)) {
        setCookiesConsentDone(true)
      } else {
        setCookiesConsentDone(false)
      }
    })
  }, [])

  return { cookiesConsentDone }
}