import { encode } from 'qss'
import { getStorePublicCredentials } from '~/lib/shopify/public-client'

import {
  UnlikelyRequestHeaders,
  type UnlikelyMetafield,
} from '@unlikelystudio/commerce-connector'

enum QueryRoutes {
  // Nextjs
  NEXT_GEOLOCATION = 'geolocation',
  NEXT_COUNTRIES_LIST = 'countries-input-list',
  // Shopify
  SHOPIFY_METAFIELDS_SET = 'customers/set-metafields',
  SHOPIFY_NEWSLETTER = 'customers/newsletter',
  SHOPIFY_CUSTOMER_LOCALE = 'customers/update-locale',
  SHOPIFY_CUSTOMER_TAGS = 'customers/update-tags',
  SHOPIFY_ORDER_ADMIN = 'orders/get-admin-order',
  SHOPIFY_MULTIPASS = 'multipass',
  SHOPIFY_GIFT_CARD_BALANCE = 'gift-card-balance',
  // Blog
  BLOG_ARTICLES_LIST = 'blog/get-blog-articles-list',
  // Stock Alert
  UNLIKELY_STOCK_ALERT_ADD = 'stock-alert/post',
  // Google
  GOOGLE_RECAPTCHA = 'verify-recaptcha',
}

export enum QueryMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export type QueryPayload = Record<
  string,
  string | object | boolean | UnlikelyMetafield
>

interface QueryParams {
  payload?: QueryPayload
  accessToken?: string
  queryParams?: Record<string, string>
  headers?: Record<string, string>
}

interface GenerateQueryParams extends QueryParams {
  action: QueryRoutes | string
  method: QueryMethods
}

interface EndpointSettings {
  action: QueryRoutes
  method: QueryMethods
}

async function generateQuery({
  action,
  payload,
  accessToken,
  queryParams = null,
  method,
  headers,
}: GenerateQueryParams) {
  const hasBody = method !== QueryMethods.GET
  const shop = getStorePublicCredentials()

  const req = await fetch(
    `/api/${action}/${queryParams ? `?${encode(queryParams)}` : ``}`,
    {
      headers: {
        'Content-Type': 'application/json',
        ...(headers ? headers : {}),
        ...(accessToken && {
          [UnlikelyRequestHeaders.AUTHORIZATION]: accessToken,
          [UnlikelyRequestHeaders.STORE_URL]: shop?.storeUrl,
          [UnlikelyRequestHeaders.STORE_TOKEN]: shop?.storefrontAccessToken,
        }),
      },
      method,
      ...(hasBody && { body: JSON.stringify(payload) }),
    },
  )

  const res = await req.json()

  return res
}

export const requestNextApiEndpoint = async (
  settings: EndpointSettings,
  params?: QueryParams,
) => {
  return await generateQuery({
    ...settings,
    ...(params ? params : {}),
  })
}

// Stock Alert
export const UNLIKELY_STOCK_ALERT_ADD_PARAMS = {
  action: QueryRoutes.UNLIKELY_STOCK_ALERT_ADD,
  method: QueryMethods.POST,
}

// Next
export const NEXT_GEOLOCATION_PARAMS = {
  action: QueryRoutes.NEXT_GEOLOCATION,
  method: QueryMethods.GET,
}

export const NEXT_COUNTRIES_LIST_PARAMS = {
  action: QueryRoutes.NEXT_COUNTRIES_LIST,
  method: QueryMethods.GET,
}

// Shopify
export const SHOPIFY_CUSTOMER_LOCALE_PARAMS = {
  action: QueryRoutes.SHOPIFY_CUSTOMER_LOCALE,
  method: QueryMethods.PUT,
}

export const SHOPIFY_CUSTOMER_TAGS_PARAMS = {
  action: QueryRoutes.SHOPIFY_CUSTOMER_TAGS,
  method: QueryMethods.PUT,
}

export const SHOPIFY_METAFIELDS_SET_PARAMS = {
  action: QueryRoutes.SHOPIFY_METAFIELDS_SET,
  method: QueryMethods.POST,
}

export const SHOPIFY_NEWSLETTER_PARAMS = {
  action: QueryRoutes.SHOPIFY_NEWSLETTER,
  method: QueryMethods.POST,
}

export const SHOPIFY_ADMIN_ORDER_PARAMS = {
  action: QueryRoutes.SHOPIFY_ORDER_ADMIN,
  method: QueryMethods.GET,
}

export const SHOPIFY_MULTIPASS_PARAMS = {
  action: QueryRoutes.SHOPIFY_MULTIPASS,
  method: QueryMethods.POST,
}

export const SHOPIFY_GIFT_CARD_BALANCE = {
  action: QueryRoutes.SHOPIFY_GIFT_CARD_BALANCE,
  method: QueryMethods.POST,
}

// Google
export const GOOGLE_RECAPTCHA_PARAMS = {
  action: QueryRoutes.GOOGLE_RECAPTCHA,
  method: QueryMethods.POST,
}

// Blog
export const BLOG_ARTICLES_LIST_PARAMS = {
  action: QueryRoutes.BLOG_ARTICLES_LIST,
  method: QueryMethods.POST,
}
