/**
 * The function calculates the discount percentage between the compareAtPrice and the price in a
 * Shopify store.
 * @param {number} compareAtPrice - The compareAtPrice parameter represents the original price of a
 * product on Shopify. It is a number that indicates the price at which the product was previously sold
 * or the suggested retail price.
 * @param {number} price - The `price` parameter represents the current price of a product in a Shopify
 * store.
 * @returns a string that represents the discount percentage between the compareAtPrice and the price.
 */
export default function getShopifyDiscountPercentage(
  compareAtPrice: number,
  price: number,
) {
  const computedDiscountPercentage = Math.round(
    ((compareAtPrice - price) * 100) / compareAtPrice,
  )

  return isNaN(computedDiscountPercentage)
    ? null
    : `-${Math.round(((compareAtPrice - price) * 100) / compareAtPrice)}%`
}
