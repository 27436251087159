import { HTMLSerializer } from 'prismic-richtext'
import { GlobalTextPreset } from '~/@types/text-preset'

import { Elements } from '~/components/Abstracts/Prismic/types'
import css from '~/components/Abstracts/RichText/styles.module.scss'
import Image from '~/components/UI/Image'
import InlineCta, {
  InlineCtaColors,
  InlineCtaProps,
  InlineCtaTextPreset,
} from '~/components/UI/InlineCta'

import serializeImage from '~/data/serialize-image'
import serializeLink from '~/data/serialize-link'

export interface HtmlSerializeProps {
  locale: string
  ctaPreset?: InlineCtaTextPreset
  theme?: InlineCtaColors
  withoutCtaTextStyle?: InlineCtaProps['withoutTextStyle']
  hasLine?: InlineCtaProps['hasLine']
  onClick
}

export default function htmlSerializer({
  ctaPreset,
  theme,
  withoutCtaTextStyle,
  locale,
  hasLine,
  onClick,
}: HtmlSerializeProps): HTMLSerializer<JSX.Element | null> {
  function serializer(type, element, content, serializedChildren) {
    switch (type) {
      case Elements.hyperlink: {
        const link = element?.data ? serializeLink(element?.data, locale) : null

        return link ? (
          <InlineCta
            className={css.link}
            textPreset={ctaPreset ?? GlobalTextPreset.Cta12_14HafferSemiBold}
            theme={theme}
            withoutTextStyle={withoutCtaTextStyle}
            key={`rich-text-link-${link?.href}`}
            hasLine={hasLine}
            onClick={onClick}
            {...link}>
            {serializedChildren}
          </InlineCta>
        ) : (
          serializedChildren
        )
      }
      case Elements.image: {
        const image = serializeImage(element)
        return <Image asPlaceholder {...image} />
      }
      default:
        // Returning null will render the default PrismicRichText interpretation for the current type
        return null
    }
  }

  return serializer
}
