import { BreadcrumbDataProps } from '@unlikelystudio/react-abstract-components'

import getAlternateUrl from '~/utils/alternate-url'

export default function serializeJSONLD(items): BreadcrumbDataProps {
  return {
    items:
      items?.map((item) => ({
        name: item?.children ?? null,
        url: getAlternateUrl(item?.href) ?? null,
      })) ?? [],
  }
}
