import { useMemo } from 'react'
import { useWindowSize } from 'react-use'

import { useIsMountedState } from '@unlikelystudio/react-hooks'

import { useTheme } from '~/providers/ThemeProvider'

export default function useBreakpoint(breakpoint: string): boolean {
  const { screens } = useTheme()
  const { width } = useWindowSize()
  const isMounted = useIsMountedState()

  const processedScreenSizes = useMemo(() => {
    return (
      Object?.entries(screens)
        .map(([key, value]: [string, string], index: number) => {
          return {
            value: parseInt(value.replace('px', '')),
            breakpoint: key,
          }
        })
        .sort((a, b) => a.value - b.value) ?? []
    )
  }, [screens])

  const askedBreakpoint = processedScreenSizes.findIndex((item) => {
    return item.breakpoint === breakpoint
  })

  const currentBreakpoint = processedScreenSizes.findIndex(({ value }) => {
    return value > width
  })

  const processedCurrentBreakpoint =
    currentBreakpoint > -1
      ? currentBreakpoint
      : processedScreenSizes?.[processedScreenSizes?.length - 1]

  return !isMounted ? false : processedCurrentBreakpoint <= askedBreakpoint
}
