import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { Alerts } from '@unlikelystudio/react-abstract-components'

import { useStyle } from '~/providers/StyleProvider'

import { ALERT } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export function CustomAlerts() {
  const titleStyle = useStyle({
    textPreset: GlobalTextPreset.Text12_14Haffer,
    textStyling: GlobalTextStyling.UpperCase,
  })
  const textStyle = useStyle({ textPreset: GlobalTextPreset.Text12_14Haffer })
  const t = useTranslate()

  return (
    <Alerts
      className={css.Alerts}
      alertClassName={css.Alert}
      closeButtonClassName={css.CloseIcon}
      titleClassName={titleStyle}
      textClassName={cx(textStyle, css.text)}
      closeLabel={t(ALERT.CLOSE)}
    />
  )
}
