import mitt, { Emitter } from 'mitt'
import React, {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useState,
} from 'react'
import { TRACKING_EVENTS } from '~/lib/constants'

import { EventPayload } from '~/hooks/tracking/useGTMTracking'

const emitter = mitt()

type TContext<T extends Record<TRACKING_EVENTS, EventPayload>> = {
  tracker: Emitter<T>
  pageTitle: string
}

export const Context = createContext({
  tracker: emitter,
  pageTitle: '',
})

export function useTracker<T extends Record<TRACKING_EVENTS, EventPayload>>() {
  return useContext<TContext<T>>(Context as React.Context<TContext<T>>)
}

export interface TrackerProviderProps {
  children?: JSX.Element | JSX.Element[]
  pageTitle?: string
}

function TrackerProvider({ children, pageTitle }: TrackerProviderProps) {
  const [tracker] = useState(emitter)

  const value = useMemo(
    () => ({
      tracker,
      pageTitle,
    }),
    [tracker, pageTitle],
  )

  return <Context.Provider value={value}>{children}</Context.Provider>
}

TrackerProvider.defaultProps = {}

export default TrackerProvider
