import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import React from 'react'

import { useIsHover } from '@unlikelystudio/react-hooks'

import Image from '~/components/UI/Image'
import Link from '~/components/UI/Link'

import { ImageItem } from '../types'
import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ breakpoint: 'md', ratio: 5 / 24 }, { ratio: 24 / 24 }]

export default function NavigationImageItem({
  className,
  image,
  imageHover,
  link,
}: ImageItem) {
  const [isHover, callbacks] = useIsHover()

  return (
    <Link className={cx(css.ImageItem, className)} {...link} {...callbacks}>
      <div className={css.images}>
        {image && (
          <Image
            className={css.image}
            layout="fill"
            objectFit="cover"
            asPlaceholder
            sizesFromBreakpoints={IMAGE_SIZES}
            ratio={css.ratio}
            {...image}
          />
        )}

        {imageHover && (
          <AnimatePresence>
            {isHover && (
              <m.div
                className={cx(css.imageHover)}
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.25 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.25 },
                }}
              >
                <Image
                  draggable="false"
                  layout="fill"
                  objectFit="cover"
                  ratio={css.ratio}
                  sizesFromBreakpoints={IMAGE_SIZES}
                  asPlaceholder
                  {...imageHover}
                />
              </m.div>
            )}
          </AnimatePresence>
        )}
      </div>
    </Link>
  )
}
